export interface SourceDocumentAttachment {
  id: number;
  isDeletable: boolean;
  isTaxReportProcessed?: boolean;
  sourceDocument: SourceDocument;
  uuid?: string;
  isTmp?: boolean;
}

export interface SourceDocument {
  id: number;
  type: SourceDocumentType;
  externalId: string | null;
  companyId: number;
  date: string | null;
  amount: number | null;
  createdAt: string;
  file: {
    id: number;
    name: string;
    url: string;
  };
  progress?: number;
  hasBeenUploadedNow?: boolean;
  tempUuid?: string;
}

// TODO COMMENT ON UPLOAD AVEC UN TYPE ?
export enum SourceDocumentType {
  VENDOR_INVOICE = 'vendorInvoice',
  SALES_INVOICE = 'salesInvoice',
  OTHER = 'other',
  AMAZON_TAX_REPORT = 'amazonTaxReport',
  MISCELLANEOUS_ACCOUNTING_LINES_CSV = 'miscellaneousAccountingLinesCsv',
  UNKNOWN = 'unknown',
}
