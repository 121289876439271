import { Pipe, PipeTransform } from '@angular/core';
import { fr } from 'date-fns/locale';
import { format, formatInTimeZone } from 'date-fns-tz';

@Pipe({
  name: 'date',
  standalone: true,
})
export class DougsDatePipe implements PipeTransform {
  transform(initialDate: Date | string | undefined | null, dateFormat = 'dd/MM/yyyy'): string {
    if (!initialDate) {
      return '';
    }
    const isDateString: boolean =
      typeof initialDate === 'string' &&
      !!initialDate.match(new RegExp(`\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}.\\d{3}Z`, 'g'));
    const dateFormatHasHoursOrMinutes: boolean = dateFormat.includes('H') || dateFormat.includes('m');

    if (isDateString && dateFormatHasHoursOrMinutes) {
      return formatInTimeZone(initialDate, 'Europe/Paris', dateFormat);
    }
    const dateTZ: Date = new Date(initialDate);
    const dateNoTZ: Date = new Date(dateTZ.valueOf() + dateTZ.getTimezoneOffset() * 60 * 1000);
    return format(dateNoTZ, dateFormat, { locale: fr });
  }
}
