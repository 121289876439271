<div
  (clickOutside)="onClickOutside()"
  [ngClass]="{
    'search-bar-component__small': size === 'small',
    'search-bar-component__tiny': size === 'tiny',
    'search-bar-component__in-modal': inModal,
    'search-bar-component__with-filter-block': hasFilterBlock,
    'search-bar-component__filter-block-visible': showFilterBlock
  }"
  class="search-bar-component"
  dougsClickOutside
>
  <i class="search-bar-component__search-icon far fa-search"></i>
  <input
    #inputSearch
    [(ngModel)]="value"
    class="search-bar-component__search-input"
    [placeholder]="placeholder"
    type="text"
  />
  <i
    (click)="clearSearch.emit()"
    *ngIf="searchInProgress && !hideCloseButton"
    [ngClass]="{ 'px-8 py-4': hasFilterBlock, 'p-16': !hasFilterBlock }"
    class="fal fa-times search-bar-component__clear-icon"
  ></i>
  <div
    *ngIf="isActive || searchInProgress || alwaysShowSuffixFilter"
    [@inOutAnimation]
    class="search-bar-component__suffix-filter"
  >
    <ng-content select="[suffixFilter]"></ng-content>
  </div>
  <div
    *ngIf="hasFilterBlock"
    [ngClass]="{ active: showFilterBlock, 'has-filters': hasSelectedFilters }"
    (click)="toggleFilterBlock()"
    class="search-bar-component__filter-toggle py-4 px-8"
  >
    <i class="fal fa-sliders-h mr-4"></i>
    Filtrer
  </div>
</div>

<div *ngIf="hasFilterBlock && showFilterBlock" [@inOutAnimation] class="filter-block py-16 px-16 mt-4">
  <ng-content select="[filterBlock]"></ng-content>
</div>
