import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

@Pipe({
  name: 'withLoading',
  standalone: true,
})
export class WithLoadingPipe implements PipeTransform {
  transform(value: Observable<unknown> | undefined): Observable<unknown> | undefined {
    return isObservable(value)
      ? value.pipe(
          map((value: unknown) => ({ loading: false, value })),
          startWith({ loading: true }),
          catchError(() => of({ loading: false })),
        )
      : value;
  }
}
