import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { GetDatePillMaxWidthPipe, IsSameDayPipe, TrackByPipe } from '../../../pipes';
import { DropdownComponent } from '../../dropdown';
import { DatePill, PillComponent } from '../../pill';
import { DatepickerOptions } from '../datepicker-common.service';
import { DatepickerComponentService } from './datepicker.component.service';

@Component({
  selector: 'dougs-datepicker',
  templateUrl: './datepicker.component.html',
  standalone: true,
  imports: [CommonModule, DropdownComponent, PillComponent, TrackByPipe, IsSameDayPipe, GetDatePillMaxWidthPipe],
  providers: [DatepickerComponentService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent {
  constructor(
    private readonly cdr: ChangeDetectorRef,
    public readonly datepickerComponentService: DatepickerComponentService,
  ) {}

  set date(date: Date) {
    this.datepickerComponentService.selectedDate = date;
  }

  datePills: DatePill[] = [];
  onDateChange: Subject<Date> = this.datepickerComponentService.onDateChange;

  @Input()
  set options(options: DatepickerOptions) {
    this.datepickerComponentService.options = options;
  }

  get options(): DatepickerOptions {
    return this.datepickerComponentService.options;
  }

  @ViewChild('dropdownDatepicker') dropdown!: DropdownComponent;
  private _reference!: ElementRef;

  set reference(reference: ElementRef) {
    this._reference = reference;
    this.cdr.markForCheck();
  }

  get reference(): ElementRef {
    return this._reference;
  }
}
