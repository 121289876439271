<dougs-dropdown
  *ngIf="showAddressAutocomplete"
  [reference]="elementRef.nativeElement"
  #addressDropdown
  [displayArrow]="false"
>
  <ng-container *ngIf="addressService.addressValueChanges$ | async as addresses">
    <dougs-dropdown-option
      (click)="onSelectAddress(address.properties)"
      *ngFor="let address of addresses.features; trackBy: trackByPropertiesId"
    >
      {{ address.properties.label }} {{ useAddressContext ? address.properties.context : '' }}
    </dougs-dropdown-option>
    <dougs-dropdown-option *ngIf="!addresses?.features.length">
      Aucune adresse n'a été trouvée. Vous pouvez renseigner manuellement les champs.
    </dougs-dropdown-option>
    @if (showAddressNotListedOption) {
      <dougs-dropdown-option (click)="onAddressNotListed()"> Mon adresse n'est pas listée</dougs-dropdown-option>
    }
  </ng-container>
  <ng-container *ngIf="!(addressService.addressValueChanges$ | async)">
    <dougs-dropdown-option [disabled]="true">
      Vous devez taper au moins 3 caractères pour déclencher la recherche.
    </dougs-dropdown-option>
  </ng-container>
</dougs-dropdown>
