import { animate, AnimationEvent, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

const ANIMATION = '300ms ease-in-out';

@Component({
  selector: 'dougs-accordion',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          height: 0,
          paddingTop: 0,
          paddingBottom: 0,
          opacity: 0,
          overflow: 'hidden',
        }),
        animate(ANIMATION, style({ height: '*', paddingTop: '*', paddingBottom: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ overflow: 'hidden' }),
        animate(ANIMATION, style({ height: 0, paddingTop: 0, paddingBottom: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class AccordionComponent {
  private _isOpen = false;

  get isOpen(): boolean {
    return this._isOpen;
  }

  @Input()
  set isOpen(isOpen: boolean) {
    this._isOpen = isOpen;
  }

  @Input() icon = '';
  @Input() image = '';
  @Input() showFooter = false;
  @Input() isLocked = false;
  @Input() disableActiveIcon = false;
  @Input() iconBackgroundColor: 'primary' | 'default' = 'default';
  @Input() accordionId = '';
  @Input() isAdmin = false;

  @Output() open: EventEmitter<boolean> = new EventEmitter<boolean>();

  animationLeaveDone = true;
  headerHovered = false;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  animationDone(e: AnimationEvent): void {
    if (e.toState === 'void') {
      this.animationLeaveDone = true;
      this.cdr.markForCheck();
    }
  }

  animationStarted(e: AnimationEvent): void {
    if (e.fromState === 'void') {
      this.animationLeaveDone = false;
      this.cdr.markForCheck();
    }
  }

  handleAccordionClick(): void {
    if (!this.isLocked) {
      this.isOpen = !this.isOpen;
      this.open.emit(this.isOpen);
    }
  }
}
