import { OverlayRef } from '@angular/cdk/overlay';
import { Type } from '@angular/core';
import { Subject } from 'rxjs';

import { filter } from 'rxjs/operators';
import { ModalConfig } from './modal-config.model';
import { ModalService } from './modal.service';

export interface OverlayCloseEvent<R> {
  type: 'backdropClick' | 'close' | 'keyboard';
  data: R;
}

// R = Response Data Type, T = Data passed to Modal Type
export class ModalRef<R = unknown, T = unknown> {
  afterClosed$ = new Subject<OverlayCloseEvent<R | null | undefined>>();
  beforeClosed$ = new Subject<OverlayCloseEvent<R | null | undefined>>();

  constructor(
    public overlay: OverlayRef,
    public content: Type<unknown>,
    private readonly modalService: ModalService,
    public config?: ModalConfig<T>, // pass data to modal i.e. FormData
  ) {
    if (!this.config?.disableCloseOnEscape) {
      overlay
        .keydownEvents()
        .pipe(filter((event: KeyboardEvent) => event.key === 'Escape' && !this.config?.disableClose))
        .subscribe((event: KeyboardEvent) => {
          event.preventDefault();
          this._close('keyboard', null);
        });
    }

    overlay.backdropClick().subscribe(() => {
      if (!this.config?.disableClose && !this.config?.disableBackdropClose) {
        this._close('backdropClick', null);
      }
    });
  }

  close(data?: R) {
    this._close('close', data);
  }

  private _close(type: 'backdropClick' | 'close' | 'keyboard', data: R | null | undefined) {
    document.body.classList.remove('modal-opened');
    this.beforeClosed$.next({
      type,
      data,
    });
    this.beforeClosed$.complete();
    this.modalService.onModalClose();
    this.overlay.dispose();
    this.afterClosed$.next({
      type,
      data,
    });

    this.afterClosed$.complete();
  }
}

export function _closeDialogVia<R>(ref: ModalRef<R>, result?: R) {
  return ref.close(result);
}
