import { Attachment } from '@dougs/core/files';

export interface Labels {
  pills: CompanyPill[];
  intercom: IntercomRating | null;
  postIts: PostIt[];
}

export interface CompanyPill {
  level?: LabelsLevel;
  label: string | number;
  tooltip?: string;
}

export interface IntercomRating {
  link: string;
  rating: number;
}

export enum LabelsLevel {
  ALERT = 'alert',
  WARN = 'warn',
  INFO = 'info',
  SUCCESS = 'success',
}

export interface PostIt {
  id: number;
  title: string;
  content: string;
  lastUpdater: {
    lastName: string;
    firstName: string;
    avatarUrl: string;
  };
  isArchived: boolean;
  department: string;
  lastUpdatedAt?: Date;
  archivedAt?: Date;
  createdAt: Date;
  attachments: Attachment[];
  isGlobal?: boolean;
}

export interface PostItCreation {
  title: string;
  content?: string;
  isGlobal?: boolean;
  files?: File[];
}

export enum PostItsDrawerTab {
  VISIBLE = 'visible',
  ARCHIVED = 'archived',
  ALL = 'all',
}
