import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[dougsShowPassword]',
  standalone: true,
})
export class ShowPasswordDirective {
  @Output() isShowing: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private readonly element: ElementRef) {}

  @HostListener('click', ['$event'])
  show(event: MouseEvent | TouchEvent): void {
    event.preventDefault();
    if (this.elementRef.type === 'text') {
      this.element.nativeElement.classList.remove('fa-eye-slash');
      this.element.nativeElement.classList.add('fa-eye');
      this.elementRef.type = 'password';
      this.isShowing.next(false);
    } else {
      this.element.nativeElement.classList.remove('fa-eye');
      this.element.nativeElement.classList.add('fa-eye-slash');
      this.elementRef.type = 'text';
      this.isShowing.next(true);
    }
  }

  @Input('dougsShowPassword')
  elementRef!: HTMLInputElement;
}
