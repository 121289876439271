import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Attachment } from '@dougs/core/files';
import { ModalService } from '../../modal/modal.service';

@Injectable()
export class VendorInvoicePillComponentService {
  constructor(private readonly modalService: ModalService) {}

  async confirmDeleteFile(attachment: Attachment): Promise<boolean> {
    const { ConfirmationModalComponent } = await import('../../../modals');

    const { data } = await lastValueFrom(
      this.modalService.open(ConfirmationModalComponent, {
        data: {
          title: 'Détacher la facture',
          isInvoice: true,
          body: `Êtes-vous sûr de vouloir détacher la facture <b>&laquo;&nbsp;${attachment.name}&nbsp;&raquo;</b> &nbsp; de cette opération ? Votre facture sera toujours accessible depuis votre module Factures d’achat.`,
          yesText: 'Oui, détacher la facture',
          noText: 'Annuler',
        },
      }).afterClosed$,
    );
    return !!data;
  }
}
