<img
  *ngIf="!animation"
  [ngStyle]="{ 'max-width': maxWidth ? maxWidth + 'px' : 'none' }"
  [src]="image"
  [ngClass]="{ 'auto-width': autoWidth }"
  alt="Blank state"
/>
<div *ngIf="animation" class="animation-container">
  <ng-lottie width="128px" height="128px" containerClass="lottie-animation" [options]="animation"></ng-lottie>
</div>
<ng-content></ng-content>
