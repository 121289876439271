import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppConfig } from '@dougs/core/config';
import { Attachment, SourceDocumentAttachment } from '@dougs/core/files';
import { TrustRessourceUrlPipe } from '../../pipes';
import { IconButtonComponent } from '../icon-button';
import { ClassIconPipe } from './pipes/class-icon.pipe';
import { MimeTypePipe } from './pipes/mime-type.pipe';
import { FilesPreviewComponentService } from './services/files-preview.component.service';

@Component({
  selector: 'dougs-files-preview',
  templateUrl: './files-preview.component.html',
  styleUrls: ['./files-preview.component.scss'],
  imports: [CommonModule, TrustRessourceUrlPipe, ClassIconPipe, MimeTypePipe, IconButtonComponent],
  providers: [FilesPreviewComponentService, MimeTypePipe],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesPreviewComponent {
  constructor(public readonly filesPreviewComponentService: FilesPreviewComponentService) {}

  @Input() noFilenameBorder = false;
  @Input() noFilenameHeader = false;

  @Input()
  set attachments(attachments: Attachment[]) {
    this.filesPreviewComponentService.setAttachments(attachments);
  }

  @Input()
  set sourceDocuments(sourceDocuments: SourceDocumentAttachment[]) {
    this.filesPreviewComponentService.setSourceDocuments(sourceDocuments);
  }

  protected readonly legacyApiServerUrl = AppConfig.settings.legacyApiServerUrl;
}
