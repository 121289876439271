import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export const slideOutAnimation: AnimationTriggerMetadata = trigger('slideOut', [
  transition(':leave', [
    style({ opacity: 1, transform: 'translateY(0%)' }),
    animate('500ms ease-in-out', style({ opacity: 0, transform: 'translateY(100%)' })),
  ]),
]);

export const scaleInAnimation: AnimationTriggerMetadata = trigger('scaleIn', [
  transition(':enter', [
    style({ opacity: 0, 'font-size': '*', 'max-height': '12px' }),
    animate(
      '300ms ease-in-out',
      style({
        opacity: 0.66,
        'font-size': '20px',
        'margin-top': '-4px',
        'max-height': '12px',
      }),
    ),
    animate(
      '200ms ease-in-out',
      style({
        opacity: 1,
        'font-size': '*',
        'margin-top': 0,
        'max-height': '12px',
      }),
    ),
  ]),
]);
export const miniScaleInAnimation: AnimationTriggerMetadata = trigger('scaleIn', [
  transition(':enter', [
    style({ opacity: 0, 'font-size': '*', 'max-height': '12px' }),
    animate(
      '300ms ease-in-out',
      style({
        opacity: 0.66,
        'font-size': '16px',
        'margin-top': '-4px',
        'max-height': '12px',
      }),
    ),
    animate(
      '200ms ease-in-out',
      style({
        opacity: 1,
        'font-size': '*',
        'margin-top': 0,
        'max-height': '12px',
      }),
    ),
  ]),
]);

export const slideInFromSideAnimation: AnimationTriggerMetadata = trigger('slideInFromSide', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(100%)' }),
    animate('500ms ease-in-out', style({ opacity: 1, transform: 'translateX(0)' })),
  ]),
]);
