import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  constructor(private readonly breakpointObserver: BreakpointObserver) {}

  mobileWidth = 768;
  xlarge = 1200;

  isMobile: Observable<boolean> = this.breakpointObserver
    .observe(`(max-width: ${this.mobileWidth - 1}px)`)
    .pipe(map((breakpointState) => breakpointState.matches));

  isSmallScreen: Observable<boolean> = this.breakpointObserver
    .observe('(max-width: 640px)')
    .pipe(map((breakpointState) => breakpointState.matches));

  isXLarge: Observable<boolean> = this.breakpointObserver
    .observe(`(min-width: ${this.xlarge}px)`)
    .pipe(map((breakpointState) => breakpointState.matches));
}
