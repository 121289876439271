import { Pipe, PipeTransform } from '@angular/core';
import { MimeTypesEnum } from '../dto/file-preview.dto';

@Pipe({
  name: 'mimeType',
  standalone: true,
})
export class MimeTypePipe implements PipeTransform {
  transform(fileName: string | undefined): string | null {
    if (!fileName) {
      return null;
    }
    const splits: string[] = fileName.split('.');
    const ext: string = splits[splits.length - 1].toUpperCase();
    return MimeTypesEnum[ext as keyof typeof MimeTypesEnum];
  }
}
