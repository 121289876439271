import { Pipe, PipeTransform } from '@angular/core';
import { Operation } from '@dougs/operations/dto';

@Pipe({
  name: 'showOperationDivider',
  standalone: true,
})
export class ShowOperationDividerPipe implements PipeTransform {
  transform(operations: Operation[], index: number, isFirst: boolean): boolean {
    if (isFirst) {
      return true;
    }

    const previousDate = new Date(operations[index - 1].date);
    const currentDate = new Date(operations[index].date);

    return (
      previousDate.getFullYear() !== currentDate.getFullYear() || previousDate.getMonth() !== currentDate.getMonth()
    );
  }
}
