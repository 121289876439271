<div class="currency-suffix" [dougsDropdownTrigger]="currencyDropdown" [dougsDropdownDisabled]="disabledDropdown">
  <i
    *ngIf="value | currencyClass as currencyClass"
    [ngClass]="{ 'default-cursor': disabledDropdown }"
    class="fal {{ currencyClass }}"
  ></i>
  <span [ngClass]="{ 'default-cursor': disabledDropdown }" *ngIf="!(value | currencyClass)">{{ value }}</span>
  <i
    *ngIf="!disabledDropdown"
    class="fal chevron"
    [ngClass]="{ 'fa-chevron-down': !isDropdownOpened, 'fa-chevron-up': isDropdownOpened }"
  ></i>
</div>
<dougs-dropdown
  (opened)="isDropdownOpened = true"
  (closed)="isDropdownOpened = false"
  #currencyDropdown
  [displayArrow]="false"
  [widthToRefElement]="false"
>
  <dougs-dropdown-option
    *ngFor="let currency of configBackService.currencies$ | async; trackBy: 'isoValue' | trackBy"
    [closeOnClick]="true"
    (click)="value = currency.isoCode"
    [ngClass]="{ selected: value === currency.isoCode }"
  >
    <div class="currency-line">
      <img src="images/flags/{{ currency.flag }}.svg" alt="drapeau" />
      <p class="small color-primary-700">{{ currency.isoCode }}</p>
      <p class="tiny color-gray">{{ currency.label }}</p>
    </div>
  </dougs-dropdown-option>
</dougs-dropdown>
