export { default as ParseError } from '../es6/ParseError.js';
// `parsePhoneNumber()` named export has been renamed to `parsePhoneNumberWithError()`.
export { default as parsePhoneNumberWithError, default as parsePhoneNumber } from '../es6/parsePhoneNumberWithError.js';

// `parsePhoneNumberFromString()` named export is now considered legacy:
// it has been promoted to a default export due to being too verbose.
export { default, default as parsePhoneNumberFromString } from '../es6/parsePhoneNumber.js';
export { default as isValidPhoneNumber } from '../es6/isValidPhoneNumber.js';
export { default as isPossiblePhoneNumber } from '../es6/isPossiblePhoneNumber.js';
export { default as validatePhoneNumberLength } from '../es6/validatePhoneNumberLength.js';

// Deprecated.
export { default as findNumbers } from '../es6/legacy/findNumbers.js';
export { default as searchNumbers } from '../es6/legacy/searchNumbers.js';
export { default as findPhoneNumbersInText } from '../es6/findPhoneNumbersInText.js';
export { default as searchPhoneNumbersInText } from '../es6/searchPhoneNumbersInText.js';
export { default as PhoneNumberMatcher } from '../es6/PhoneNumberMatcher.js';
export { default as AsYouType } from '../es6/AsYouType.js';
export { DIGIT_PLACEHOLDER } from '../es6/AsYouTypeFormatter.js';
export { default as getCountries } from '../es6/getCountries.js';
export { default as Metadata, isSupportedCountry, getCountryCallingCode, getExtPrefix } from '../es6/metadata.js';
export { default as getExampleNumber } from '../es6/getExampleNumber.js';
export { default as formatIncompletePhoneNumber } from '../es6/formatIncompletePhoneNumber.js';
export { default as parseIncompletePhoneNumber, parsePhoneNumberCharacter } from '../es6/parseIncompletePhoneNumber.js';
export { default as parseDigits } from '../es6/helpers/parseDigits.js';
export { parseRFC3966, formatRFC3966 } from '../es6/helpers/RFC3966.js';