import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Attachment, SourceDocument, SourceDocumentAttachment } from '@dougs/core/files';
import { FilePreview, MimeTypesEnum } from '../dto/file-preview.dto';
import { MimeTypePipe } from '../pipes/mime-type.pipe';

@Injectable()
export class FilesPreviewComponentService {
  constructor(private readonly mimeTypePipe: MimeTypePipe) {}

  private readonly _attachments: WritableSignal<Attachment[]> = signal<Attachment[]>([]);
  private readonly _sourceDocuments: WritableSignal<SourceDocumentAttachment[]> = signal<SourceDocumentAttachment[]>(
    [],
  );

  private readonly _currentFilePreview: WritableSignal<FilePreview | null> = signal<FilePreview | null>(null);
  currentFilePreview$: Signal<FilePreview | null> = this._currentFilePreview.asReadonly();

  private readonly _currentFilePreviewIndex: WritableSignal<number> = signal<number>(0);
  currentFilePreviewIndex$: Signal<number> = this._currentFilePreviewIndex.asReadonly();

  filesPreview$: Signal<FilePreview[]> = computed(() => [
    ...this._attachments().map((attachment: Attachment) => this.transformToFilePreview(attachment)),
    ...this._sourceDocuments().map((sourceDocumentAttachment: SourceDocumentAttachment) =>
      this.transformToFilePreview(sourceDocumentAttachment.sourceDocument),
    ),
  ]);

  selectDefaultFilePreview$: Observable<void> = toObservable(this.filesPreview$).pipe(
    distinctUntilChanged(),
    filter((filePreviews: FilePreview[]) => Boolean(filePreviews?.length)),
    map((filePreviews: FilePreview[]) => this.selectFilePreview(filePreviews[0])),
  );

  hasNextFile$: Signal<boolean> = computed(
    () => !!this.filesPreview$()?.length && this.currentFilePreviewIndex$() !== this.filesPreview$().length - 1,
  );

  hasPreviousFile$: Signal<boolean> = computed(
    () => !!this.filesPreview$()?.length && this.currentFilePreviewIndex$() > 0,
  );

  isCurrentFilePreviewImage$: Signal<boolean> = computed(
    () => !!this.mimeTypePipe.transform(this.currentFilePreview$()?.fileName)?.startsWith('image'),
  );

  isCurrentFilePreviewPdf$: Signal<boolean> = computed(
    () => this.mimeTypePipe.transform(this.currentFilePreview$()?.fileName) === MimeTypesEnum.PDF,
  );

  setAttachments(attachments: Attachment[]): void {
    this._attachments.set(attachments);
  }

  setSourceDocuments(sourceDocuments: SourceDocumentAttachment[]): void {
    this._sourceDocuments.set(sourceDocuments);
  }

  selectFilePreview(filePreview: FilePreview): void {
    this._currentFilePreview.set(filePreview);
  }

  selectNextFile(): void {
    if (this.hasNextFile$()) {
      this._currentFilePreviewIndex.update((index: number) => index + 1);
      this.selectFilePreview(this.filesPreview$()[this._currentFilePreviewIndex()]);
    }
  }

  selectPreviousFile(): void {
    if (this.hasPreviousFile$()) {
      this._currentFilePreviewIndex.update((index: number) => index - 1);
      this.selectFilePreview(this.filesPreview$()[this._currentFilePreviewIndex()]);
    }
  }

  private transformToFilePreview(model: Attachment | SourceDocument): FilePreview {
    return {
      fileName: ('file' in model && model?.file?.name) || '',
      fileUrl: ('file' in model && model?.file?.url) || '',
    };
  }
}
