import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioChange } from './radio-change.model';
import { RadioComponent } from './radio.component';

@Component({
  selector: 'dougs-radio-group',
  templateUrl: './radio-group.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RadioGroupComponent,
      multi: true,
    },
  ],
})
export class RadioGroupComponent implements ControlValueAccessor, AfterContentInit, AfterViewInit {
  static radioGroupCount = 0;

  @Output() radioGroupChange: EventEmitter<RadioChange> = new EventEmitter<RadioChange>();

  @ContentChildren(forwardRef(() => RadioComponent), { descendants: true })
  radios: QueryList<RadioComponent> = new QueryList<RadioComponent>();
  @Input() disabled = false;
  @Input() vertical = false;
  @Input() appearance: 'classic' | 'yesNo' | 'yesNoOutline' | 'no-vertical-row-gap' = 'classic';
  @Input() isAdmin = false;
  @Input() noWrap = false;
  onTouched!: () => void;
  propagateChange!: (value: unknown) => void;
  protected isInitialized = false;
  protected _disabled = false;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  protected _value: unknown = '';

  get value() {
    return this._value;
  }

  @Input()
  set value(newValue: unknown) {
    if (this._value !== newValue) {
      this._value = newValue;

      this.updateSelectedRadioFromValue();
      this.checkSelectedRadio();
    }
  }

  protected _selected: RadioComponent | null = null;

  get selected() {
    return this._selected;
  }

  @Input()
  set selected(selected: RadioComponent | null) {
    this._selected = selected;
    this.value = selected ? selected.value : '';
    this.checkSelectedRadio();
  }

  protected _name = `radio-group-${RadioGroupComponent.radioGroupCount++}`;

  get name() {
    return this._name;
  }

  @Input()
  set name(name: string) {
    this._name = name;
    this.updateRadios();
  }

  checkSelectedRadio() {
    if (this.selected && !this._selected?.checked) {
      this.selected.checked = true;
    }
  }

  updateSelectedRadioFromValue() {
    if (this.radios && !this.isAlreadySelected()) {
      this._selected = null;
      this.radios.forEach((radio) => {
        if (radio.checked || radio.value === this._value) {
          this._selected = radio;
        }
      });
    }
  }

  resetSelectedRadio() {
    this.radios.forEach((radio) => {
      radio.checked = false;
    });
  }

  isAlreadySelected(): boolean {
    return this._selected !== null && this._selected.value === this._value;
  }

  emitChangeEvent(event: RadioChange) {
    this.radioGroupChange.emit(event);
    this.propagateChange(event.value);
    this.onTouched();
  }

  updateRadios() {
    setTimeout(() => {
      this.radios.forEach((radio) => {
        radio.name = this.name;
        radio.setDisabledFromGroup(this.disabled);
      });
    });
  }

  markAllRadiosAsCheck(): void {
    this.radios.forEach((radio) => radio.markForCheck());
    setTimeout(() => {
      this.radios.forEach((radio) => radio.markForCheck());
      this.selected?.markForCheck();
    });
  }

  writeValue(value: string | boolean) {
    this.value = value;
    setTimeout(() => {
      this.resetSelectedRadio();
      this.updateSelectedRadioFromValue();
      this.checkSelectedRadio();
      this.markAllRadiosAsCheck();
    });
  }

  ngAfterContentInit() {
    this.radios.changes.subscribe(() => {
      this.updateRadios();
      this.updateRadioChangeHandler();
    });

    this.updateRadioChangeHandler();
  }

  ngAfterViewInit() {
    this.updateRadios();
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.updateRadios();
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: () => void) {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  protected updateRadioChangeHandler() {
    this.radios.forEach((radio) => {
      radio.registerRadioChangeHandler((event: RadioChange) => {
        this.resetSelectedRadio();
        radio.checked = true;
        // update selected and value from the event
        this.selected = event.source;

        // bubble the event
        this.emitChangeEvent(event);

        this.markAllRadiosAsCheck();
      });
    });
  }
}
