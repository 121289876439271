import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[dougsStepSubtitle]',
  standalone: true,
})
export class StepSubtitleDirective {
  @HostBinding('class')
  get elementClasses(): string {
    return `step-subtitle`;
  }
}
