import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'trustResourceHtml',
  standalone: true,
})
export class TrustResourceHtmlPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(value: string | undefined): SafeUrl | string | undefined {
    if (!value) {
      return value;
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
