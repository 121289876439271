import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AppConfig } from '@dougs/core/config';
import { SourceDocumentAttachment } from '@dougs/core/files';
import { LoaderComponent } from '../../loader';
import { miniScaleInAnimation, slideInFromSideAnimation, slideOutAnimation } from '../animations/animations.const';
import { SourceDocumentPillComponentService } from './source-document-pill-component.service';

@Component({
  selector: 'dougs-source-document-pill',
  templateUrl: './source-document-pill.component.html',
  styleUrls: ['../scss/file-pill.scss'],
  standalone: true,
  imports: [CommonModule, LoaderComponent, RouterLink],
  providers: [SourceDocumentPillComponentService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideOutAnimation, miniScaleInAnimation, slideInFromSideAnimation],
})
export class SourceDocumentPillComponent {
  @Input() set sourceDocumentAttachment(sourceDocumentAttachment: SourceDocumentAttachment) {
    this.sourceDocumentPillComponentService.setSourceDocumentAttachment(sourceDocumentAttachment);
  }

  @Input() set canDelete(canDelete: boolean) {
    this.sourceDocumentPillComponentService.setCanDelete(canDelete);
  }

  @Input() set disableRouting(disableRouting: boolean) {
    this.sourceDocumentPillComponentService.setDisableRouting(disableRouting);
  }

  @Input() set enableAnimation(enableAnimation: boolean) {
    this.sourceDocumentPillComponentService.setEnableAnimation(enableAnimation);
  }

  @Output() deleteSourceDocumentAttachment: EventEmitter<SourceDocumentAttachment> =
    this.sourceDocumentPillComponentService.deleteSourceDocumentAttachmentEmitter;

  constructor(public readonly sourceDocumentPillComponentService: SourceDocumentPillComponentService) {}

  protected readonly legacyApiServerUrl = AppConfig.settings.legacyApiServerUrl;
}
