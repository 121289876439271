import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { merge, Subscription } from 'rxjs';
import { ErrorFormFieldDirective, FORM_FIELD_ERROR } from './error.directive';
import { FORM_FIELD_PREFIX, PrefixFormFieldDirective } from './prefix.directive';
import { FORM_FIELD_SUFFIX, SuffixFormFieldDirective } from './suffix.directive';

@Component({
  selector: 'dougs-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent implements AfterContentInit, OnDestroy {
  private _size: 'small' | 'medium' | 'large' = 'medium';

  @Input()
  set size(value: 'small' | 'medium' | 'large') {
    this._size = value;
  }

  get size() {
    return this._size;
  }

  @HostBinding('class.no-margin')
  @Input()
  noMargin = false;

  @Input()
  noBorder = false;

  @HostBinding('class.auto-width')
  @Input()
  autoWidth = false;

  @HostBinding('class.compact')
  @Input()
  isCompact = false;

  hasError = false;

  @ContentChildren(FORM_FIELD_PREFIX, { descendants: true })
  _prefixChildren!: QueryList<PrefixFormFieldDirective>;
  @ContentChildren(FORM_FIELD_SUFFIX, { descendants: true })
  _suffixChildren!: QueryList<SuffixFormFieldDirective>;
  @ContentChildren(FORM_FIELD_ERROR, { descendants: true })
  _errorChildren!: QueryList<ErrorFormFieldDirective>;

  private errorSubscription!: Subscription;
  private prefixSuffixSubscription!: Subscription;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    this.errorSubscription = this._errorChildren.changes.subscribe(() => {
      this.hasError = !!this._errorChildren.length;
      this.changeDetectorRef.markForCheck();
    });

    if (this._errorChildren.length) {
      this.hasError = !!this._errorChildren.length;
      this.changeDetectorRef.markForCheck();
    }

    this.prefixSuffixSubscription = merge(this._prefixChildren.changes, this._suffixChildren.changes).subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.errorSubscription.unsubscribe();
    this.prefixSuffixSubscription.unsubscribe();
  }
}
