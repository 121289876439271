import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Attachment, SourceDocumentAttachment } from '@dougs/core/files';
import { LoaderComponent } from '../../loader';
import { miniScaleInAnimation, slideInFromSideAnimation, slideOutAnimation } from '../animations/animations.const';
import { SalesInvoicePillComponentService } from './sales-invoice-pill-component.service';

@Component({
  selector: 'dougs-sales-invoice-pill',
  templateUrl: './sales-invoice-pill.component.html',
  styleUrls: ['../scss/file-pill.scss'],
  standalone: true,
  imports: [CommonModule, LoaderComponent, RouterLink],
  providers: [SalesInvoicePillComponentService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideOutAnimation, miniScaleInAnimation, slideInFromSideAnimation],
})
export class SalesInvoicePillComponent {
  private _salesInvoiceSourceDocumentAttachment!: SourceDocumentAttachment;

  @Input()
  set salesInvoiceSourceDocumentAttachment(salesInvoiceSourceDocumentAttachment: SourceDocumentAttachment) {
    this._salesInvoiceSourceDocumentAttachment = salesInvoiceSourceDocumentAttachment;
    this.salesInvoiceAttachment = {
      name: salesInvoiceSourceDocumentAttachment.sourceDocument.file.name,
      url: salesInvoiceSourceDocumentAttachment.sourceDocument.file.url,
      progress: salesInvoiceSourceDocumentAttachment.sourceDocument?.progress,
      hasBeenUploadedNow: !!salesInvoiceSourceDocumentAttachment.sourceDocument?.hasBeenUploadedNow,
    } as Attachment;
  }

  get salesInvoiceSourceDocumentAttachment(): SourceDocumentAttachment {
    return this._salesInvoiceSourceDocumentAttachment;
  }

  @Input() canDelete = true;
  @Input() disableRouting = false;
  @Input() enableAnimation = false;

  @Output() deleteSalesInvoiceAttachment: EventEmitter<SourceDocumentAttachment> =
    new EventEmitter<SourceDocumentAttachment>();

  public salesInvoiceAttachment?: Attachment;

  constructor(private readonly salesInvoicePillComponentService: SalesInvoicePillComponentService) {}

  async confirmDeleteFile(e: Event): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    if (this.salesInvoiceAttachment) {
      const isDetached: boolean = await this.salesInvoicePillComponentService.confirmDeleteFile(
        this.salesInvoiceAttachment,
      );
      if (isDetached) {
        this.deleteSalesInvoiceAttachment.emit(this.salesInvoiceSourceDocumentAttachment);
      }
    }
  }
}
