import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { DropdownComponent, DropdownOptionComponent, DropdownTriggerDirective } from '../../dropdown';
import {
  ControlFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  PrefixFormFieldDirective,
  SuffixFormFieldDirective,
} from '../../form-field';
import { LoaderComponent } from '../../loader';
import { ModalService } from '../../modal';
import { TooltipDirective } from '../../tooltip';
import { AddressNotListedModalComponent } from '../address-not-listed-modal/address-not-listed-modal.component';
import { AddressService } from '../address.service';
import { AddressData } from '../dtos/address-data.dto';
import { AddressFieldsEnum } from '../dtos/address-fields.enum';
import { AddressGouvFeature, AddressGouvFeatureProperties } from '../dtos/address-gouv.dto';

@Component({
  selector: 'dougs-address-autocomplete, [dougsAddressAutocomplete]',
  standalone: true,
  imports: [
    CommonModule,
    FormFieldComponent,
    LabelFormFieldDirective,
    ControlFormFieldDirective,
    ReactiveFormsModule,
    DropdownComponent,
    DropdownOptionComponent,
    DropdownTriggerDirective,
    SuffixFormFieldDirective,
    TooltipDirective,
    PrefixFormFieldDirective,
    LoaderComponent,
  ],
  templateUrl: './address-autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AddressService],
})
export class AddressAutocompleteComponent {
  @Input() showAddressAutocomplete = false;
  @Input() useAddressContext = false;
  @Input() addressFieldsToDisplay: AddressFieldsEnum[] = [];
  @Input() showAddressNotListedOption = false;

  @Output() selectAddress: EventEmitter<AddressGouvFeatureProperties> =
    new EventEmitter<AddressGouvFeatureProperties>();
  @Output() manualAddressEntry: EventEmitter<AddressData> = new EventEmitter<AddressData>();

  @ViewChild('addressDropdown')
  addressDropdown!: DropdownComponent;

  @HostBinding('attr.autocomplete')
  autocomplete = 0;

  @HostListener('focus')
  onFocus(): void {
    if (this.addressDropdown) {
      this.addressDropdown.show();
      this.addressService.addressValueSubject.next((this.elementRef.nativeElement as HTMLInputElement).value);
    }
  }

  @HostListener('blur')
  onBlur(): void {
    if (this.addressDropdown) {
      setTimeout(() => {
        this.addressDropdown.hide();
      }, 200);
    }
  }

  @HostListener('input')
  onInput(): void {
    if (this.addressDropdown) {
      this.addressService.addressValueSubject.next((this.elementRef.nativeElement as HTMLInputElement).value);
    }
  }

  constructor(
    public readonly addressService: AddressService,
    public readonly elementRef: ElementRef,
    public readonly modalService: ModalService,
  ) {}

  onSelectAddress(address: AddressGouvFeatureProperties): void {
    this.selectAddress.emit(address);
    this.addressDropdown.hide();
  }
  async onAddressNotListed(): Promise<void> {
    const manualAddressEntry: AddressData = (
      await lastValueFrom(
        this.modalService.open(AddressNotListedModalComponent, {
          data: this.addressFieldsToDisplay,
        }).afterClosed$,
      )
    ).data as AddressData;
    if (manualAddressEntry) {
      this.manualAddressEntry.emit(manualAddressEntry);
    }
  }

  trackByPropertiesId(_: number, address: AddressGouvFeature): string {
    return address.properties.id;
  }
}
