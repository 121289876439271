import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { AppConfig } from '@dougs/core/config';
import { InvoiceFile } from '@dougs/core/files';
import { TrustRessourceUrlPipe } from '../../pipes';

@Injectable()
export class InvoicePreviewComponentService {
  constructor(private readonly trustRessourceUrlPipe: TrustRessourceUrlPipe) {}

  private readonly invoiceFile: WritableSignal<InvoiceFile | null> = signal(null);
  invoiceFile$: Signal<InvoiceFile | null> = this.invoiceFile.asReadonly();

  isInvoiceFileSupported$: Signal<boolean> = computed(() => this.isFileSupported(this.invoiceFile()));
  isInvoiceFileImage$: Signal<boolean> = computed(() => this.isImage(this.invoiceFile()));
  url$: Signal<SafeUrl | null> = computed(() =>
    this.invoiceFile()?.filePath
      ? this.trustRessourceUrlPipe.transform(
          AppConfig.settings.legacyApiServerUrl + (this.invoiceFile() as InvoiceFile).filePath,
        )
      : null,
  );

  setInvoiceFile(invoiceFile: InvoiceFile) {
    this.invoiceFile.set(invoiceFile);
  }

  private isFileSupported(invoiceFile: InvoiceFile | null): boolean {
    return (
      !!invoiceFile?.fileType && (invoiceFile.fileType?.includes('image') || invoiceFile.fileType?.includes('pdf'))
    );
  }

  private isImage(invoiceFile: InvoiceFile | null): boolean {
    return !!invoiceFile?.fileType && invoiceFile.fileType?.includes('image');
  }
}
