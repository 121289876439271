import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { generateUuidV4 } from '@dougs/core/utils';
import { LabelFormFieldDirective } from '../form-field';

@Component({
  selector: 'dougs-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, LabelFormFieldDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxComponent,
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  constructor(private readonly cdr: ChangeDetectorRef) {}

  @Input() disabled = false;
  @Input() appearance: 'classic' | 'toggle' = 'classic';
  @Input() showYesNo = false;
  @Input() yesText = 'Oui';
  @Input() noText = 'Non';
  @Input() id = generateUuidV4();
  @Input() label = '';
  @Input() dougsFormFieldLabel = false;
  @Input() isAdmin = false;
  @Input() labelAfter = false;
  @Input() darkerBorder = false;
  @Input() tinyLabel = false;

  private _value = false;

  set value(value: boolean) {
    this._value = value;
    if (this.propagateChange) {
      this.propagateChange(value);
      this.onTouched();
    }
    this.cdr.markForCheck();
  }

  get value(): boolean {
    return this._value;
  }

  propagateChange: (value: boolean) => void = () => true;
  onTouched: () => void = () => true;

  registerOnChange(fn: (value: boolean) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: boolean): void {
    this._value = obj;
    this.cdr.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}
