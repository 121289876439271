import { Directive, InjectionToken } from '@angular/core';

export const FORM_FIELD_PREFIX = new InjectionToken<PrefixFormFieldDirective>('PrefixFormFieldDirective');

@Directive({
  selector: '[dougsFormFieldPrefix]',
  standalone: true,
  providers: [{ provide: FORM_FIELD_PREFIX, useExisting: PrefixFormFieldDirective }],
})
export class PrefixFormFieldDirective {}
