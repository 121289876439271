import { SourceDocument, SourceDocumentAttachment, SourceDocumentType } from '../dto';

const getDefaults = (): SourceDocumentAttachment => ({
  id: 1,
  isDeletable: true,
  sourceDocument: getSourceDocumentMock(),
});

export const getSourceDocumentAttachmentMock = (p?: Partial<SourceDocumentAttachment>): SourceDocumentAttachment => ({
  ...getDefaults(),
  ...p,
});

const getSourceDocumentDefaults = (): SourceDocument => ({
  id: 1,
  amount: 1,
  companyId: 1,
  createdAt: new Date('2023-01-01').toString(),
  date: new Date('2023-01-01').toString(),
  externalId: '1',
  file: {
    id: 1,
    name: 'fileName',
    url: 'fileUrl',
  },
  type: SourceDocumentType.OTHER,
});

export const getSourceDocumentMock = (p?: Partial<SourceDocument>): SourceDocument => ({
  ...getSourceDocumentDefaults(),
  ...p,
});
