import { Directive, ElementRef, HostBinding, HostListener, Input, Optional } from '@angular/core';
import { ModalRef } from './modal-ref';
import { ModalService } from './modal.service';

@Directive({
  selector: '[dougsModalClose]',
  standalone: true,
})
export class ModalCloseDirective {
  @Input('dougsModalClose') modalResult: unknown;

  @HostBinding('class') classes = 'modal-close';

  constructor(
    @Optional() public dialogRef: ModalRef,
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _dialog: ModalService,
  ) {}

  @HostListener('click', ['$event'])
  onButtonClick() {
    this.dialogRef.close(this.modalResult);
  }
}
