import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ConfigBack, Department, Team } from '../dto';
import { Currency } from '../dto/currency.dto';
import { ConfigBackHttpService } from '../http/config-back.http';

@Injectable({
  providedIn: 'root',
})
export class ConfigBackService {
  constructor(private readonly configBackHttpService: ConfigBackHttpService) {}

  private readonly config$: Observable<ConfigBack | null> = this.configBackHttpService.getConfig().pipe(shareReplay(1));
  countries$ = this.config$.pipe(map((config) => config?.countries.countries));
  vatTypes$ = this.config$.pipe(map((config) => config?.vatSettings.labels.vatTypes));
  vatActivities$ = this.config$.pipe(map((config) => config?.vatSettings.labels.vatActivities));
  vatActivitiesExemption$ = this.vatActivities$.pipe(map((vatActivities) => vatActivities?.exemption));
  certifiedOrganisms$ = this.config$.pipe(map((config) => config?.certifiedOrganism.organisms));
  certifiedOrganismsCga$ = this.certifiedOrganisms$.pipe(
    map((certifiedOrganisms) => certifiedOrganisms?.filter((organism) => organism.type.includes('cga'))),
  );
  certifiedOrganismsAga$ = this.certifiedOrganisms$.pipe(
    map((certifiedOrganisms) => certifiedOrganisms?.filter((organism) => organism.type.includes('aga'))),
  );
  courtAdministrationServices$ = this.config$.pipe(map((config) => config?.courtsAdministrationServices));
  companyTaxesServices$ = this.config$.pipe(map((config) => config?.companyTaxesServices));
  currencies$: Observable<Currency[] | undefined> = this.config$.pipe(map((config) => config?.currencies));
  entrySurveyQuestions$ = this.config$.pipe(map((config) => config?.entrySurvey.questions));
  labftSurveyQuestions$ = this.config$.pipe(map((config) => config?.labftSurveyQuestions));
  missionAcceptationSurveyQuestions$ = this.config$.pipe(map((config) => config?.missionAcceptationSurveyQuestions));
  legalForms$ = this.config$.pipe(map((config) => config?.legalForms));
  taxRegimes$ = this.config$.pipe(map((config) => config?.taxRegimes));
  resiliationReasons$ = this.config$.pipe(map((config) => config?.resiliationReasons || []));
  legalCreationTasks$ = this.config$.pipe(map((config) => config?.legalCreationTasks));

  departments$: Observable<Department[]> = this.configBackHttpService.getCollaboratorDepartments().pipe(shareReplay(1));
  teams$: Observable<Team[]> = this.departments$.pipe(
    map((departments) => departments.flatMap((department) => department.teams)),
  );
}
