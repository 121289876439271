import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Injector,
  Input,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Company } from '@dougs/company/dto';
import { FormService } from '@dougs/core/form';
import { CopyToClipboardDirective } from '../../../directives/copy-to-clipboard/copy-to-clipboard.directive';
import { ErrorFormFieldDirective } from '../../form-field';
import { ControlFormFieldDirective } from '../../form-field/control.directive';
import { FormFieldComponent } from '../../form-field/form-field.component';
import { LabelFormFieldDirective } from '../../form-field/label.directive';
import { PrefixFormFieldDirective } from '../../form-field/prefix.directive';
import { PanelInfoComponent } from '../../panel-info';
import { AddressAutocompleteComponent } from '../address-autocomplete/address-autocomplete.component';
import { AddressData } from '../dtos/address-data.dto';
import { AddressFieldsEnum } from '../dtos/address-fields.enum';
import { AddressGouvFeatureProperties } from '../dtos/address-gouv.dto';

@Component({
  selector: 'dougs-address',
  standalone: true,
  imports: [
    CommonModule,
    AddressAutocompleteComponent,
    ControlFormFieldDirective,
    FormFieldComponent,
    FormsModule,
    LabelFormFieldDirective,
    PanelInfoComponent,
    PrefixFormFieldDirective,
    CopyToClipboardDirective,
    ErrorFormFieldDirective,
  ],
  templateUrl: './address.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressComponent),
      multi: true,
    },
  ],
})
export class AddressComponent implements ControlValueAccessor, AfterViewInit {
  fullAddress!: string;
  isManualEntry = false;
  displayManualEntryInfo = false;
  isDisabled = false;
  control!: NgControl;
  isValid = true;
  hasValidAddress = true;

  @Input()
  isAccountantOrAdmin!: boolean;
  @Input()
  addressFieldsToDisplay!: AddressFieldsEnum[];

  @Input()
  set activeCompany(activeCompany: Company) {
    this.displayManualEntryInfo = false;
  }

  constructor(
    private readonly injector: Injector,
    private readonly cdr: ChangeDetectorRef,
    protected readonly formService: FormService,
  ) {}

  ngAfterViewInit(): void {
    this.control = this.injector.get(NgControl);
  }
  onChange: (value: AddressData) => void = () => true;

  onTouched: () => void = () => true;

  writeValue(value: AddressData): void {
    if (value) {
      this.displayManualEntryInfo = this.isManualEntry;
      this.buildFullAddress(value);
      this.hasValidAddress = true;
      this.cdr.markForCheck();
    }
  }

  onSelectAddress(event: AddressGouvFeatureProperties): void {
    this.onChange(this.buildAddressObjectFromAddressGouv(event));
    this.buildFullAddress({ street: event.name, zipcode: event.postcode, city: event.city });
    this.onTouched();
    this.hasValidAddress = true;
  }

  onManualAddressEntry(event: AddressData): void {
    this.isManualEntry = true;
    this.buildFullAddress(event);
    this.onChange(event);
    this.onTouched();
    this.hasValidAddress = true;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cdr.markForCheck();
  }
  invalidateAddress(): void {
    this.hasValidAddress = false;
    if (this.control.touched && this.hasValidAddress) {
      this.isValid = false;
    }
  }

  private buildFullAddress(addressData: AddressData): void {
    this.fullAddress = `${addressData.street ?? ''} ${addressData.zipcode ?? ''} ${addressData.city ?? ''}`;
  }

  private buildAddressObjectFromAddressGouv(address: AddressGouvFeatureProperties): AddressData {
    return {
      street: address.name,
      zipcode: address.postcode,
      city: address.city,
    };
  }
}
