import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dougs-logo',
  templateUrl: './logo.component.html',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @Input() compact = false;
  @Input() color: 'dark' | 'light' | 'white' = 'dark';
  @Input() width = 72;

  @Input() logoName = 'dougs-logo';

  isCompact(): string {
    return this.compact ? '-compact' : '';
  }

  getColor(): string {
    return this.color !== 'dark' ? `-${this.color}` : '';
  }
}
