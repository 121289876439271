import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonColor } from '../../dto';

@Component({
  selector: 'dougs-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  imports: [CommonModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
  @Input() color: ButtonColor = 'primary';
  @Input() iconClass!: string;
  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() type: 'regular' | 'solid' | 'light' = 'light';
  @Input() disabled = false;
}
