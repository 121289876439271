import { Directive, HostBinding, InjectionToken } from '@angular/core';

export const FORM_FIELD_ERROR = new InjectionToken<ErrorFormFieldDirective>('ErrorFormFieldDirective');

@Directive({
  selector: '[dougsFormFieldError]',
  standalone: true,
  providers: [{ provide: FORM_FIELD_ERROR, useExisting: ErrorFormFieldDirective }],
})
export class ErrorFormFieldDirective {
  @HostBinding('class') classes = 'form-field-error';
}
