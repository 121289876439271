<dougs-dropdown
  [isDatepicker]="true"
  [widthToRefElement]="false"
  [displayArrow]="false"
  *ngIf="reference"
  #dropdownDatepicker
  [reference]="reference?.nativeElement"
>
  <div
    *ngIf="{
      mode: dateRangePickerComponentService.mode$ | async,
      view: dateRangePickerComponentService.view$ | async,
      days: dateRangePickerComponentService.days$ | async,
      months: dateRangePickerComponentService.months$ | async,
      years: dateRangePickerComponentService.years$ | async,
      isValid: dateRangePickerComponentService.isValid$ | async
    } as pickerData"
    class="datepicker"
  >
    <div class="datepicker__range-mode pt-16 px-24 pb-24">
      <dougs-button
        [color]="pickerData.mode === RangeMode.FROM ? 'primary' : 'secondary'"
        (click)="dateRangePickerComponentService.setMode(RangeMode.FROM)"
        size="small"
      >
        À partir du
      </dougs-button>
      <dougs-button
        [color]="pickerData.mode === RangeMode.RANGE ? 'primary' : 'secondary'"
        (click)="dateRangePickerComponentService.setMode(RangeMode.RANGE)"
        size="small"
        class="mx-8"
      >
        Entre
      </dougs-button>
      <dougs-button
        [color]="pickerData.mode === RangeMode.UNTIL ? 'primary' : 'secondary'"
        (click)="dateRangePickerComponentService.setMode(RangeMode.UNTIL)"
        size="small"
      >
        Jusqu'au
      </dougs-button>
    </div>
    <div class="px-24 pb-16">
      <div
        class="datepicker__navigation mb-16"
        [ngClass]="{ 'datepicker__navigation--centered': pickerData.view === 'months' }"
      >
        <i
          *ngIf="pickerData.view !== 'months'"
          (click)="dateRangePickerComponentService.previous(pickerData.view)"
          class="datepicker__navigation__arrow fal fa-chevron-left"
        ></i>
        <h5 class="datepicker__navigation__title" (click)="dateRangePickerComponentService.toggleView('years')">
          {{ dateRangePickerComponentService.title$ | async | titlecase }}
          <i class="fal fa-chevron-down"></i>
        </h5>
        <i
          *ngIf="pickerData.view !== 'months'"
          (click)="dateRangePickerComponentService.next(pickerData.view)"
          class="datepicker__navigation__arrow fal fa-chevron-right"
        ></i>
      </div>
      <div class="datepicker__day-names" *ngIf="pickerData.view === 'days'">
        <span class="datepicker__day-names__label" *ngFor="let day of dateRangePickerComponentService.dayNames">{{
          day | titlecase
        }}</span>
      </div>
      <div class="datepicker__days" *ngIf="pickerData.view === 'days'">
        <span
          (click)="day.isSelectable && dateRangePickerComponentService.choseDate(day.date)"
          (mouseenter)="dateRangePickerComponentService.startHoveringDay(day)"
          (mouseleave)="dateRangePickerComponentService.stopHoveringDay()"
          class="datepicker__days__label"
          *ngFor="let day of pickerData.days; let i = index; trackBy: dateRangePickerComponentService.trackById"
          [ngClass]="{
            'datepicker__days__label--is-not-in-month': !day.inThisMonth,
            'datepicker__days__label--is-today': day.isToday,
            'datepicker__days__label--is-within-range': day.isWithinRange,
            'datepicker__days__label--is-start-date': day.isStartDate,
            'datepicker__days__label--is-end-date': day.isEndDate,
            'datepicker__days__label--is-preselected': day.isPreselected,
            'is-disabled': !day.isSelectable
          }"
        >
          {{ day.day }}
        </span>
      </div>
      <div class="datepicker__years" *ngIf="pickerData.view === 'years'">
        <span
          (click)="dateRangePickerComponentService.setYear(year.year)"
          class="datepicker__years__label"
          *ngFor="let year of pickerData.years; let i = index"
          [ngClass]="{
            'datepicker__years__label--is-selected': year.isSelected,
            'datepicker__years__label--is-this-year': year.isThisYear
          }"
        >
          {{ year.year }}
        </span>
      </div>
      <div class="datepicker__months" *ngIf="pickerData.view === 'months'">
        <span
          (click)="dateRangePickerComponentService.setMonth(month.month)"
          class="datepicker__months__label"
          *ngFor="let month of pickerData.months; let i = index; trackBy: 'month' | trackBy"
          [ngClass]="{
            'datepicker__months__label--is-selected': month.isSelected,
            'datepicker__months__label--is-this-year': month.isThisMonth
          }"
        >
          {{ month.label | titlecase }}
        </span>
      </div>
    </div>
    <div class="datepicker__range-actions px-24 py-16">
      <dougs-button (click)="dateRangePickerComponentService.reset()" color="secondary" size="small"
        >Réinitialiser</dougs-button
      >
      <dougs-button
        (click)="dateRangePickerComponentService.submit()"
        [disabled]="!pickerData.isValid"
        color="primary"
        size="small"
        >Rechercher</dougs-button
      >
    </div>
  </div>
</dougs-dropdown>
