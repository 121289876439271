import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Pill } from './pill.dto';

@Component({
  selector: 'dougs-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillComponent {
  @Input() type: Pill = 'secondary';
  @Input() isSelected = false;
  @Input() ellipsis = false;
}
