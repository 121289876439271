<span *ngIf="!optionSelected" class="placeholder">{{ placeholder }}</span>
<span *ngIf="optionSelected" [innerHTML]="optionSelected.label" class="select-value"></span>
<i [ngClass]="isOpen ? 'fa-angle-up' : 'fa-angle-down'" class="fal fa-lg"></i>
<dougs-dropdown
  [displayArrow]="false"
  [isSelect]="true"
  [maxHeight]="maxHeight"
  [reference]="element.nativeElement"
  [widthToRefElement]="widthToRefElement"
>
  <label *ngIf="searchable" class="dropdown-search">
    <i class="dropdown-search__icon-search fal fa-search"></i>
    <input
      #searchOption
      (input)="filterOptions($event)"
      (keydown)="interceptKeyDown($event)"
      [placeholder]="searchPlaceholder"
      type="text"
    />
  </label>
  <div class="scrollable">
    <ng-container *ngIf="isGroupSelect">
      <ng-container
        *ngFor="
          let groupOption of filteredGroupOptions | keyvalue: compareGroupOption;
          trackBy: trackByGroupValue;
          let i = index
        "
      >
        <dougs-dropdown-option
          (click)="selectGroup(i)"
          *ngIf="groupOption.key"
          [innerHTML]="formatGroupHeader(groupOption.key, i)"
          class="dropdown-option group-title"
        ></dougs-dropdown-option>
        <ng-container *ngIf="selectedGroup === i || isSearching">
          <dougs-dropdown-option
            (click)="selectOption(option)"
            *ngFor="let option of groupOption.value; trackBy: trackByValue"
            [ngClass]="{
              selected: option.selected,
              active: option.active,
              disabled: option.disabled,
              'group-title': option.isGroupTitle,
              small: size === 'small'
            }"
            class="dropdown-option"
          >
            <span [class.mr-4]="option.selected" [innerHTML]="option.content"></span>
            @if (option.selected) {
              <i class="fal fa-check ml-auto"></i>
            }
          </dougs-dropdown-option>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!isGroupSelect">
      <dougs-loader *ngIf="isLoading"></dougs-loader>
      <dougs-dropdown-option
        (click)="selectOption(option)"
        *ngFor="let option of filteredOptions; trackBy: trackByValue"
        [dougsTooltip]="option.tooltip"
        [ngClass]="{
          selected: option.selected,
          active: option.active,
          disabled: option.disabled,
          'group-title': option.isGroupTitle,
          small: size === 'small'
        }"
        class="dropdown-option"
      >
        <span [class.mr-4]="option.selected" [innerHTML]="option.content"></span>
        @if (option.selected) {
          <i class="fal fa-check ml-auto"></i>
        }
      </dougs-dropdown-option>
    </ng-container>
    <div
      *ngIf="
        (filteredOptions.length === 0 && !isGroupSelect) ||
        ((filteredGroupOptions | keyvalue)?.length === 0 && isGroupSelect && !isLoading)
      "
      class="dropdown-option empty"
    >
      ¯\_(ツ)_/¯ Aucun résultat
    </div>
  </div>
  @if (showCustomFooter$()) {
    <dougs-dropdown-option class="no-padding">
      <ng-content select="[dougsCustomSelectOptionFooter]"></ng-content>
    </dougs-dropdown-option>
  }
</dougs-dropdown>
