import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'avatarUrl',
  standalone: true,
})
export class AvatarUrlPipe implements PipeTransform {
  transform(
    value: { name?: string; fullName?: string; avatarUrl?: string },
    defaultUrl = 'https://images.prismic.io/test-dougs/059d75a6-aa18-472d-891d-20a83105a3f7_sarah-small.jpeg',
  ): string {
    const name = value?.name || value?.fullName;
    return value?.avatarUrl && name ? value.avatarUrl : defaultUrl;
  }
}
