<div class="step" [ngClass]="status | stepperStatusClass" [class.no-status]="!showStepStatus">
  <div *ngIf="showStepStatus" class="step-status-wrapper">
    <ng-content select="[dougsStepStatus]"></ng-content>
  </div>
  <dougs-stepper-icon [stepStatus]="status"></dougs-stepper-icon>
  <ng-content select="[dougsStepTitle]"></ng-content>
  <div *ngIf="showStepStatus"></div>
  <div class="step-separator-wrapper">
    <div class="step-separator" [class.last]="isLast"></div>
  </div>
  <ng-content select="[dougsStepSubtitle]"></ng-content>
</div>
