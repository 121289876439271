import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { SelectOptionComponent } from './select-option.component';

@Component({
  selector: 'dougs-select-option-group',
  template: '<ng-content></ng-content>',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOptionGroupComponent {
  @ContentChildren(SelectOptionComponent, {
    descendants: true,
  })
  optionsComponent: QueryList<SelectOptionComponent> = new QueryList<SelectOptionComponent>();

  @Input() value!: any;
}
