<div [dougsDropdownTrigger]="fileInput">
  <div *ngIf="!square" class="file-input" dougsDragNDrop (fileDropped)="onUploadFiles($event)">
    <i class="color-primary fal fa-arrow-up" [ngClass]="{ 'mr-8': !noMargin }"></i>
    {{ fileInputText }}
  </div>

  <div *ngIf="square" class="square-add-file p-8" dougsDragNDrop (fileDropped)="onUploadFiles($event)">
    <i class="color-primary fal fa-arrow-up" [ngClass]="{ 'mr-8': !noMargin }"></i>
    {{ fileInputText }}
  </div>

  <dougs-dropdown #fileInput [displayArrow]="true" [widthToRefElement]="false">
    <input
      [hidden]="true"
      id="input-file"
      type="file"
      [accept]="acceptFileType"
      [multiple]="multiple"
      (change)="onUploadFiles($event.target.files, $event)"
    />
    <div class="dropdown-file">
      <div class="dropdown-file__drag-file mb-8" dougsDragNDrop (fileDropped)="onUploadFiles($event)">
        <i class="mb-8 dropdown-file__drag-file__icon fal fa-cloud-upload fa-2x"></i>
        <p class="tiny">Glisser & déposer vos fichiers</p>
      </div>
      <div class="dropdown-file__computer">
        <label for="input-file" class="color-primary-700 p-8">
          <i class="mr-8 fal fa-desktop"></i>
          Depuis mon ordinateur
        </label>
      </div>
      <ng-container *ngIf="shouldAddVendorInvoice || shouldAddSalesInvoice">
        <p class="tiny dropdown-file__or">
          <span class="dropdown-file__or__wording px-8">ou</span>
        </p>
      </ng-container>
      <div
        *ngIf="shouldAddVendorInvoice"
        class="dropdown-file__computer"
        (click)="dropdownFileInput.hide(); attachFromVendorInvoice.emit()"
      >
        <label class="color-primary-700 p-8">
          <i class="mr-8 fal fa-receipt"></i>
          Depuis
          <b>Factures d'achat</b>
        </label>
      </div>
      <div
        *ngIf="shouldAddSalesInvoice"
        class="dropdown-file__computer"
        (click)="dropdownFileInput.hide(); attachFromSalesInvoice.emit()"
      >
        <label class="color-primary-700 p-8">
          <i class="mr-8 fal fa-file-invoice"></i>
          Depuis
          <b>Factures de vente</b>
        </label>
      </div>
    </div>
  </dougs-dropdown>
</div>
