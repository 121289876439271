import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[dougsBlurOnEnter]',
  standalone: true,
})
export class BlurOnEnterDirective {
  constructor(private readonly host: ElementRef) {}

  @HostListener('keydown.enter')
  blur(): void {
    this.host.nativeElement.blur();
  }
}
