import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Directive({
  selector: '[dougsKeydown]',
  standalone: true,
})
export class KeydownDirective {
  private readonly keydownSubject: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  public readonly onKeydown$: Observable<KeyboardEvent> = this.keydownSubject.asObservable();

  @Output() keydownEvent: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
  protected hasFocus = false;

  @HostListener('body:focusin', ['$event'])
  onFocusIn(): void {
    this.hasFocus = true;
  }

  @HostListener('body:focusout', ['$event'])
  onFocusOut(): void {
    this.hasFocus = false;
  }

  @HostListener('window:keydown', ['$event'])
  onWindowKeydown(e: KeyboardEvent): void {
    if (document.activeElement === document.body && !this.hasFocus) {
      this.keydownSubject.next(e);
      this.keydownEvent.emit(e);
    }
  }
}
