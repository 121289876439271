import { Pipe, PipeTransform } from '@angular/core';
import { parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns-tz';

@Pipe({
  name: 'appointmentNotificationDate',
  standalone: true,
})
export class AppointmentNotificationDatePipe implements PipeTransform {
  transform(initialDate: Date | string | undefined | null): string {
    if (!initialDate) {
      return '';
    }

    return format(parseISO(initialDate.toString()), 'EEEE d MMMM à k:mm', { locale: fr });
  }
}
