<ng-container *ngIf="changeImageOnBreakpoint$ | async"></ng-container>
<div class="mobile-blank-slate">
  <div class="mobile-blank-slate__header p-24">
    <dougs-logo [ngClass]="{ 'mb-32': shouldShowBigHeader }" [width]="78"></dougs-logo>
    <div class="mobile-blank-slate__header__text" *ngIf="shouldShowBigHeader">
      <ng-container *ngTemplateOutlet="text"></ng-container>
    </div>
  </div>
  <div
    class="mobile-blank-slate__body"
    [ngClass]="{ 'width-100': shouldShowBigHeader, 'p-24': !shouldShowBigHeader, 'py-24': shouldShowBigHeader }"
  >
    <ng-container *ngIf="!shouldShowBigHeader">
      <ng-container *ngTemplateOutlet="text"></ng-container>
    </ng-container>
    <img class="mt-8" [src]="imageBlankSlate" *ngIf="shouldShowBigHeader" alt="Blank slate" />
  </div>
</div>
<ng-template #text>
  <img *ngIf="!shouldShowBigHeader" class="mb-32" [src]="imageBlankSlate" alt="Blank slate" />
  <p *ngIf="pageName" class="small color-primary mb-8">{{ pageName }}</p>
  <h4 class="mb-8" [innerHTML]="title"></h4>
  <p class="small mb-16">Connectez-vous depuis un ordinateur ou téléchargez l’application Dougs.</p>
  <dougs-button *ngIf="urlStore">
    <a [href]="urlStore">
      <i class="fal fa-download mr-4"></i>
      Télécharger Dougs
    </a>
  </dougs-button>
</ng-template>
