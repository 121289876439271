import { AbstractControl, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { isEqual } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { concatMap, distinctUntilChanged } from 'rxjs/operators';
import { Fields } from '@dougs/fields/dto';

export class FormSettingsService {
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  formGroupChanges$?: Observable<unknown>;
  formGroupHasBeenTouched = new Subject<void>();
  formGroupHasBeenTouched$ = this.formGroupHasBeenTouched.asObservable();

  protected modelSchema?: (string | Record<string, any>)[];

  constructor(
    private readonly schema: (string | Record<string, unknown>)[] = [],
    private readonly blur = false,
  ) {
    this.modelSchema = schema;
    this.buildFieldsForm(blur);
  }

  protected listenOnFormGroupChanges(callbackFn: (value: any) => Observable<any>): void {
    if (!this.formGroupChanges$) {
      this.formGroupChanges$ = this.formGroup.valueChanges.pipe(
        distinctUntilChanged((previous, current) => isEqual(previous, current)),
        concatMap(callbackFn),
      );
    }
  }

  protected validateForm(): boolean {
    this.formGroup.markAllAsTouched();
    this.formGroupHasBeenTouched.next();
    return this.formGroup.valid;
  }

  private buildFieldsForm(blur = false, formGroup?: FormGroup, modelSchema?: (string | Record<string, any>)[]): void {
    const currentFormGroup: FormGroup = formGroup ?? this.formGroup;
    const currentModelSchema: (string | Record<string, any>)[] | undefined = modelSchema ?? this.modelSchema;
    if (currentModelSchema?.length) {
      for (const modelSchemaValue of currentModelSchema) {
        if (typeof modelSchemaValue === 'object' && modelSchemaValue !== null) {
          for (const [nestedModelSchemaKey, nestedModelSchemaValue] of Object.entries(modelSchemaValue)) {
            const nestedFormGroup: FormGroup = new FormGroup({});
            currentFormGroup.addControl(nestedModelSchemaKey, nestedFormGroup, { emitEvent: false });
            this.buildFieldsForm(blur, nestedFormGroup, nestedModelSchemaValue);
          }
        } else if (modelSchemaValue) {
          // value here
          currentFormGroup.addControl(
            modelSchemaValue.toString(),
            new FormControl(null, { updateOn: blur ? 'blur' : 'change', validators: [] }),
            {
              emitEvent: false,
            },
          );
        }
      }
    }
  }

  protected formatFieldsForm(fields: Fields, formGroup?: FormGroup): void {
    const currentFormGroup: FormGroup = formGroup ?? this.formGroup;
    if (fields && Object.values(fields)?.length && currentFormGroup) {
      for (const [unsplittedFieldKey, field] of Object.entries(fields)) {
        const fieldKeys: string[] = unsplittedFieldKey.split('.');
        let currentAbstractControl: AbstractControl | null = currentFormGroup;
        for (const fieldKey of fieldKeys) {
          currentAbstractControl = currentAbstractControl?.get(fieldKey) ?? null;
        }
        if (currentAbstractControl && field) {
          if (!field.isEditable && !currentAbstractControl.dirty) {
            currentAbstractControl.disable({ emitEvent: false });
          } else {
            currentAbstractControl.enable({ emitEvent: false });
          }
          if (field?.input?.type === 'email') {
            currentAbstractControl.addValidators([Validators.email]);
          }
          if (field?.input?.isRequired) {
            currentAbstractControl.addValidators([Validators.required]);
          }
        }
      }
    }
  }

  protected populateFieldsForm(
    model: Record<string, any>,
    formGroup?: FormGroup,
    modelSchema?: (string | Record<string, any>)[],
  ): void {
    const currentFormGroup: FormGroup = formGroup ?? this.formGroup;
    const currentModelSchema: (string | Record<string, any>)[] | undefined = modelSchema ?? this.modelSchema;
    if (model && currentModelSchema?.length && currentFormGroup) {
      for (const modelSchemaValue of currentModelSchema) {
        if (typeof modelSchemaValue === 'object' && modelSchemaValue !== null) {
          // nested here
          for (const [nestedModelSchemaKey, nestedModelSchemaValue] of Object.entries(modelSchemaValue)) {
            const modelNested: any = model[nestedModelSchemaKey];
            const nestedFormGroup: FormGroup = currentFormGroup.get(nestedModelSchemaKey) as FormGroup;
            if (modelNested && nestedFormGroup && nestedModelSchemaValue?.length) {
              this.populateFieldsForm(modelNested, nestedFormGroup, nestedModelSchemaValue);
            }
          }
        } else if (modelSchemaValue) {
          // value here
          const modelValue: unknown = model[modelSchemaValue.toString()];
          const currentFormControl: AbstractControl | null = currentFormGroup.get(modelSchemaValue.toString());
          if (currentFormControl && !currentFormControl.dirty) {
            currentFormGroup.get(modelSchemaValue.toString())?.setValue(modelValue, { emitEvent: false });
          }
        }
      }
    }
  }
}
