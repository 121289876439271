import { BlockScrollStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector, Type } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DrawerAnimationService } from './drawer-animation.service';
import { DrawerConfig } from './drawer-config.model';
import { DrawerRef } from './drawer-ref';
import { DrawerComponent } from './drawer.component';

export const DRAWER_DATA = new InjectionToken<unknown>('DrawerData');

@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  constructor(
    private readonly overlay: Overlay,
    private readonly injector: Injector,
  ) {}

  private readonly drawerOpenedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public drawerOpened$: Observable<boolean> = this.drawerOpenedSubject.asObservable();

  public onDrawerClose(): void {
    this.drawerOpenedSubject.next(false);
  }

  open<Output = unknown, Input = unknown>(content: Type<unknown>, config?: DrawerConfig<Input>): DrawerRef<Output> {
    const scrollStrategy: BlockScrollStrategy = this.overlay.scrollStrategies.block();
    const configs: OverlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'drawer-background',
      panelClass: this.getPanelClasses(config?.size),
      scrollStrategy,
    });

    const drawerAnimationService = new DrawerAnimationService();

    const overlayRef: OverlayRef = this.overlay.create(configs);

    overlayRef.hostElement.classList.add('global-overlay-wrapper');

    const drawerRef: DrawerRef<Output, Input> = new DrawerRef<Output, Input>(
      overlayRef,
      content,
      drawerAnimationService,
      this,
      config,
    );

    const injector = Injector.create({
      providers: [
        { provide: DrawerRef, useValue: drawerRef },
        { provide: DrawerAnimationService, useValue: drawerAnimationService },
        { provide: DRAWER_DATA, useValue: config?.data },
      ],
      parent: this.injector,
    });

    overlayRef.attach(new ComponentPortal(DrawerComponent, null, injector));

    document.body.classList.add('drawer-opened');
    this.drawerOpenedSubject.next(true);

    return drawerRef;
  }

  private getPanelClasses(size: 'small' | 'medium' | 'large' | undefined): string[] {
    return ['drawer', ...[size || 'large']];
  }
}
