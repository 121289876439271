import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'dougs-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input()
  size: 'small' | 'semi-small' | 'medium' | 'large' = 'medium';

  @Input()
  type:
    | 'error'
    | 'success'
    | 'gray'
    | 'purple-200'
    | 'primary'
    | 'admin'
    | 'pink'
    | 'green-300'
    | 'turquoise'
    | 'primary-700' = 'error';

  @HostBinding('class') get classes(): string {
    return `${this.size} ${this.type}`;
  }
}
