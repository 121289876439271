<ng-container *ngIf="sourceDocumentPillComponentService.useRouterLink$(); else openFile">
  <a [routerLink]="[]" [queryParams]="sourceDocumentPillComponentService.queryParams$()">
    <ng-container
      [ngTemplateOutlet]="filePillContent"
      [ngTemplateOutletContext]="{ icon: sourceDocumentPillComponentService.pillIcon$() }"
    ></ng-container>
  </a>
</ng-container>
<ng-template #openFile>
  <a
    [ngClass]="{ 'disable-link': !sourceDocumentPillComponentService.url$() }"
    [href]="legacyApiServerUrl + sourceDocumentPillComponentService.url$()"
    target="_blank"
  >
    <ng-container
      [ngTemplateOutlet]="filePillContent"
      [ngTemplateOutletContext]="{ icon: sourceDocumentPillComponentService.pillIcon$() }"
    ></ng-container>
  </a>
</ng-template>

<ng-template #filePillContent let-icon="icon">
  <div class="file-pill">
    <div
      class="file-pill__content"
      [ngClass]="{
        'file-pill__content--no-delete': !sourceDocumentPillComponentService.canDelete$()
      }"
    >
      <i
        @scaleIn
        [@.disabled]="sourceDocumentPillComponentService.animationDisabled$()"
        *ngIf="!sourceDocumentPillComponentService.isLoading$()"
        class="self-center fal {{ icon }}"
      ></i>
      <dougs-loader
        *ngIf="sourceDocumentPillComponentService.isLoading$()"
        class="self-center"
        appearance="spin"
      ></dougs-loader>
      <span class="filename">{{ sourceDocumentPillComponentService.sourceDocument$()?.file?.name }}</span>
      <i
        @slideInFromSide
        [@.disabled]="sourceDocumentPillComponentService.animationDisabled$()"
        *ngIf="sourceDocumentPillComponentService.showDeleteIcon$()"
        class="fal fa-times delete-file"
        (click)="sourceDocumentPillComponentService.confirmDelete($event)"
      ></i>
    </div>
  </div>
</ng-template>
