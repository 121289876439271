import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: '[fillUntilScrollable]',
  standalone: true,
})
export class FillUntilScrollableDirective {
  private _existingItemsLength!: number;

  get existingItemsLength(): number {
    return this._existingItemsLength;
  }

  @Input()
  set existingItemsLength(length: number) {
    this._existingItemsLength = length;
    setTimeout(() => this.loadMoreUntilContainerFilled());
  }

  private loadMoreUntilContainerFilled(): void {
    const htmlElement: HTMLElement | undefined = this.element?.nativeElement as HTMLElement;
    const clientHeight: number | undefined = this.useWindowHeight ? window.innerHeight : htmlElement.clientHeight;
    if (
      this._existingItemsLength >= this.minItems &&
      !!htmlElement &&
      clientHeight > 0 &&
      htmlElement.scrollHeight > 0 &&
      this._existingItemsLength !== this.previousItemsLength &&
      clientHeight >= htmlElement.scrollHeight
    ) {
      this.previousItemsLength = this._existingItemsLength;
      this.loadMoreItems.emit();
    }
  }

  @Input() useWindowHeight = false;
  @Input() minItems = 0;
  @Output() loadMoreItems: EventEmitter<void> = new EventEmitter();

  previousItemsLength!: number;

  constructor(private readonly element: ElementRef) {}
}
