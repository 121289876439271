import { Pipe } from '@angular/core';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { DatePill } from '../pill';

@Pipe({
  name: 'accountingYearDatePills',
  standalone: true,
})
export class AccountingYearDatePillsPipe {
  transform(accountingYear?: AccountingYear): DatePill[] {
    if (!accountingYear) {
      return [];
    }
    return this.buildAccountingYearDatePills(accountingYear);
  }

  private buildAccountingYearDatePills(accountingYear: AccountingYear): DatePill[] {
    return [
      { label: 'Ouverture', date: new Date(accountingYear.openingDate) },
      { label: "Aujourd'hui", date: new Date() },
      { label: 'Clôture', date: new Date(accountingYear.closingDate) },
    ];
  }
}
