import { A11yModule, ActiveDescendantKeyManager, FocusKeyManager } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
  Signal,
  signal,
  ViewChild,
  ViewChildren,
  WritableSignal,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { DropdownComponent, DropdownOptionComponent, DropdownTriggerDirective } from '../dropdown';
import { LoaderComponent } from '../loader';
import { TooltipDirective } from '../tooltip';
import { CUSTOM_OPTION_FOOTER, CustomSelectOptionFooterDirective } from './footer.directive';
import { Option } from './option.model';
import { SelectOptionGroupComponent } from './select-option-group.component';
import { SelectOptionComponent } from './select-option.component';
import type { ValueType } from './value.type';

@Component({
  selector: 'dougs-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    OverlayModule,
    FormsModule,
    A11yModule,
    DropdownComponent,
    DropdownTriggerDirective,
    DropdownOptionComponent,
    TooltipDirective,
    LoaderComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectComponent,
      multi: true,
    },
  ],
})
export class SelectComponent implements AfterViewInit, ControlValueAccessor, OnDestroy, AfterContentInit {
  @HostBinding('class.disabled')
  @Input()
  disabled = false;

  @HostBinding('class.select')
  isSelect = true;
  @HostBinding('class.select') classes = true;
  @Input() @HostBinding('class.admin') isAdmin = false;
  @Input() type: 'single' | 'multi' = 'single';
  @Input() placeholder = '';
  @Input() bindValue!: string;
  @Input() searchable = false;
  @Input() maxHeight = 0;
  @Input() widthToRefElement = true;
  @Input() searchPlaceholder = 'Rechercher...';
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() isLoading = false;
  @Output() select: EventEmitter<Option> = new EventEmitter();
  @ViewChild(DropdownComponent)
  public dropdown!: DropdownComponent;
  @ViewChild('searchOption')
  searchOptionInput!: ElementRef;
  @ContentChildren(SelectOptionComponent, {
    descendants: true,
  })
  optionsComponent: QueryList<SelectOptionComponent> = new QueryList<SelectOptionComponent>();
  @ContentChildren(SelectOptionGroupComponent, {
    descendants: true,
  })
  optionGroupsComponent: QueryList<SelectOptionGroupComponent> = new QueryList<SelectOptionGroupComponent>();
  @ViewChildren(DropdownOptionComponent)
  dropdownOptionsComponent: QueryList<DropdownOptionComponent> = new QueryList<DropdownOptionComponent>();

  @ContentChildren(CUSTOM_OPTION_FOOTER, { descendants: true })
  _customFooterOptions!: QueryList<CustomSelectOptionFooterDirective>;

  private readonly showCustomFooter: WritableSignal<boolean> = signal<boolean>(false);
  showCustomFooter$: Signal<boolean> = this.showCustomFooter.asReadonly();

  isOpen = false;
  isGroupSelect = false;
  options: Option[] = [];
  groupOptions: { [group: string]: Option[] } = {};
  filteredOptions: Option[] = [];
  filteredGroupOptions: { [group: string]: Option[] } = {};
  selectedGroup: number | null = null;
  isSearching = false;
  onChange!: (value: ValueType) => void;
  onTouch!: () => void;
  private keyManager!: ActiveDescendantKeyManager<Option>;
  private focusKeyManager!: FocusKeyManager<DropdownOptionComponent>;
  private dropdownSubscription!: Subscription;
  private keyManagerSubscription!: Subscription;
  private optionsComponentSubscription!: Subscription;
  private optionGroupsComponentSubscription!: Subscription;
  private dropdownComponentSubscription!: Subscription;
  private customFooterSubscription!: Subscription;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    public element: ElementRef,
  ) {}

  @HostBinding('attr.tabindex')
  get tabindex(): number | undefined {
    return this.disabled ? undefined : 0;
  }

  private _value: ValueType = null;

  get value() {
    return this._value;
  }

  set value(value: ValueType) {
    if (this._value !== value) {
      this._value = value;
    }
  }

  get optionSelected(): Option | undefined {
    if (this.isGroupSelect) {
      return this.concatGroupOptions.find((option: Option) => option.selected);
    }
    return this.options.find((option: Option) => option.selected);
  }

  get concatGroupOptions(): Option[] {
    return Object.keys(this.groupOptions).reduce(
      (concatGroupOptions: Option[], groupKey: string) => concatGroupOptions.concat(...this.groupOptions[groupKey]),
      [],
    );
  }

  @HostListener('click')
  onClick() {
    this.showDropdown();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.interceptKeyDown(event);
  }

  showDropdown() {
    if (!this.disabled) {
      this.isOpen = true;
      this.dropdown.show();

      if (this.options.length && this.keyManager && !this.searchable) {
        const optionSelected: Option | undefined = this.optionSelected;
        if (optionSelected) {
          this.keyManager.setActiveItem(optionSelected);
        } else if (!this.keyManager.activeItem) {
          this.keyManager.setFirstItemActive();
        }
      }

      if (this.searchable && this.searchOptionInput) {
        (this.searchOptionInput.nativeElement as HTMLInputElement).focus();
      }
    }
  }

  filterOptions(event: Event): void {
    const searchElement: string = (event.target as HTMLInputElement).value.toLowerCase();
    this.isSearching = searchElement.trim() !== '';
    if (this.isGroupSelect) {
      this.filteredGroupOptions = this.filterGroupOptionsBySearchElement(searchElement);
    } else {
      this.filteredOptions = this.options.filter((option: Option) =>
        option.label.toLowerCase().includes(searchElement),
      );
    }
  }

  hideDropdown() {
    this.isOpen = false;
    this.dropdown.hide();
  }

  ngAfterViewInit(): void {
    this.dropdownSubscription = this.dropdown.afterClosed$.subscribe(() => {
      this.isOpen = false;
      this.onTouch();
      this.cdr.markForCheck();
    });

    this.optionGroupsComponentSubscription = this.optionGroupsComponent.changes
      .pipe(startWith(this.optionGroupsComponent))
      .subscribe(() => {
        this.populateGroupOptionsFromQueryChild();
      });

    this.optionsComponentSubscription = this.optionsComponent.changes
      .pipe(startWith(this.optionsComponent))
      .subscribe(() => {
        this.populateOptionsFromQueryChild();
      });

    this.dropdownComponentSubscription = this.dropdownOptionsComponent.changes
      .pipe(startWith(this.dropdownOptionsComponent))
      .subscribe(() => {
        setTimeout(() => {
          this.focusKeyManager = new FocusKeyManager<DropdownOptionComponent>(this.dropdownOptionsComponent)
            .withWrap()
            .skipPredicate((item) => item.disabled);
        });
      });
  }

  ngAfterContentInit(): void {
    this.customFooterSubscription = this._customFooterOptions.changes.subscribe(() => {
      this.showCustomFooter.set(!!this._customFooterOptions?.length);
    });

    if (this._customFooterOptions.length) {
      this.showCustomFooter.set(!!this._customFooterOptions?.length);
    }
  }

  populateGroupOptionsFromQueryChild(): void {
    this.isGroupSelect = !!this.optionGroupsComponent?.length;
    if (this.isGroupSelect) {
      this.groupOptions = this.filteredGroupOptions = this.populateGroupOptions();
      this.selectOptionByValue(this._value);
      this.cdr.detectChanges();
    }
  }

  populateOptionsFromQueryChild(): void {
    this.options = this.filteredOptions = this.optionsComponent.map(
      (option: SelectOptionComponent) =>
        new Option(
          option.getContent(),
          option.value,
          option?.label,
          option.disabled,
          option.isGroupTitle,
          option.tooltip,
        ),
    );

    this.selectOptionByValue(this._value);

    if (!this.isGroupSelect) {
      setTimeout(() => {
        this.keyManager = new ActiveDescendantKeyManager(this.filteredOptions)
          .withTypeAhead()
          .withHorizontalOrientation('ltr')
          .withVerticalOrientation()
          .withHomeAndEnd()
          .skipPredicate((option) => option.disabled)
          .withWrap();

        this.keyManagerSubscription = this.keyManager.change.subscribe((index: number) => {
          this.focusKeyManager.setActiveItem(index);
        });
      });
    }

    this.cdr.detectChanges();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  writeValue(value: ValueType) {
    this.value = value;
    const selectedOption: Option | undefined = this.optionSelectedByValue(value);
    if (selectedOption) {
      this.selectOptionWithoutChange(selectedOption);
    } else {
      this.resetOptions();
    }
    this.cdr.markForCheck();
  }

  optionSelectedByValue(value: ValueType): Option | undefined {
    if (this.isGroupSelect) {
      return this.concatGroupOptions.find((option: Option) =>
        this.bindValue && this.isObject(option.value)
          ? this.isObject(value)
            ? option.value[this.bindValue] === value?.[this.bindValue]
            : option.value[this.bindValue] === value
          : option.value === value,
      );
    }
    return this.options.find((option: Option) =>
      this.bindValue && this.isObject(option.value)
        ? this.isObject(value)
          ? option.value[this.bindValue] === value?.[this.bindValue]
          : option.value[this.bindValue] === value
        : option.value === value,
    );
  }

  selectOptionByValue(value: ValueType): void {
    const selectedOption = this.optionSelectedByValue(value);
    if (selectedOption) {
      this.selectOptionWithoutChange(selectedOption);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  interceptKeyDown(event: KeyboardEvent): void {
    this.focusKeyManager?.onKeydown(event);
    if (this.isArrowUpDownKey(event.key) || this.isOpenKey(event.key)) {
      if (!this.dropdown.showing) {
        this.showDropdown();
        return;
      }

      if (!this.options.length) {
        event.preventDefault();
        return;
      }
    }

    if (this.isOpenKey(event.key) && this.keyManager?.activeItem) {
      this.selectOption(this.keyManager.activeItem);
    } else if (this.isEscapeKey(event.key)) {
      this.dropdown.showing && this.hideDropdown();
      event.stopPropagation();
    } else if (this.isSkipKey(event.key)) {
      this.dropdown.showing && event.preventDefault();
    } else {
      this.keyManager?.onKeydown(event);
    }
  }

  selectOption(option: Option | null): void {
    if (option && !option.disabled) {
      this.resetOptions();

      option.selected = true;

      this.onChange(option.value);
      this._value = option.value;
      this.select.emit(option);
      this.hideDropdown();
    }
  }

  selectOptionWithoutChange(option: Option | null): void {
    if (option && !option.disabled) {
      this.resetOptions();

      option.selected = true;
    }
  }

  resetOptions(): void {
    if (this.isGroupSelect) {
      this.concatGroupOptions.forEach((option: Option) => {
        option.selected = false;
      });
      return;
    }
    this.options = this.options.map((option: Option) => {
      option.selected = false;
      return option;
    });
  }

  isArrowKey(key: string): boolean {
    return ['ArrowUp', 'Up', 'ArrowDown', 'Down', 'ArrowRight', 'Right', 'ArrowLeft', 'Left'].includes(key);
  }

  isArrowUpDownKey(key: string): boolean {
    return ['ArrowUp', 'Up', 'ArrowDown', 'Down'].includes(key);
  }

  isOpenKey(key: string): boolean {
    return ['Enter'].includes(key);
  }

  isEscapeKey(key: string): boolean {
    return ['Esc', 'Escape'].includes(key);
  }

  isSkipKey(key: string): boolean {
    return ['PageUp', 'PageDown', 'Tab'].includes(key);
  }

  isObject(value: Record<string, unknown>): boolean {
    return typeof value === 'object' && value !== undefined && value !== null;
  }

  trackByValue(index: number, item: Option) {
    return item.value;
  }

  trackByGroupValue(index: number, item: { key: string; value: Option[] }) {
    return item.key;
  }

  compareGroupOption(a: { key: string; value: Option[] }, b: { key: string; value: Option[] }): number {
    return !a.key ? 1 : !b.key ? -1 : a.key < b.key ? -1 : 1;
  }

  formatGroupHeader(value: string, index: number): string {
    const cssClass: string = index === this.selectedGroup || this.isSearching ? 'fa-chevron-down' : 'fa-chevron-right';
    return `<span class="group-left">${value}</span><i class="fal ${cssClass}"></i>`;
  }

  selectGroup(index: number): void {
    if (this.selectedGroup === index) {
      this.selectedGroup = null;
    } else {
      this.selectedGroup = index;
    }
  }

  ngOnDestroy() {
    this.dropdownSubscription?.unsubscribe();
    this.dropdownComponentSubscription?.unsubscribe();
    this.optionsComponentSubscription?.unsubscribe();
    this.keyManagerSubscription?.unsubscribe();
    this.optionGroupsComponentSubscription?.unsubscribe();
    this.customFooterSubscription?.unsubscribe();
  }

  private populateGroupOptions(): { [group: string]: Option[] } {
    return this.optionGroupsComponent.reduce((groupOptions: { [group: string]: Option[] }, groupOption) => {
      const groupOptionsList: Option[] = groupOption?.optionsComponent.map((option: SelectOptionComponent) => {
        return new Option(
          option.getContent(),
          option.value,
          option?.label,
          option.disabled,
          option.isGroupTitle,
          option.tooltip,
        );
      });
      groupOptions[groupOption.value] = groupOptionsList;
      this.options.push(...groupOptionsList);
      return groupOptions;
    }, {});
  }

  private filterGroupOptionsBySearchElement(searchElement: string): { [group: string]: Option[] } {
    return Object.keys(this.groupOptions).reduce(
      (filteredGroupOptions: { [group: string]: Option[] }, groupOptionKey) => {
        const groupOptions: Option[] = this.groupOptions[groupOptionKey];
        const filteredOptions: Option[] = groupOptions.filter((option) =>
          option.label.toLowerCase().includes(searchElement),
        );
        if (filteredOptions?.length) {
          filteredGroupOptions[groupOptionKey] = filteredOptions;
        }
        return filteredGroupOptions;
      },
      {},
    );
  }
}
