import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[dougsFormFieldLabel]',
  standalone: true,
})
export class LabelFormFieldDirective {
  @HostBinding('class') classes = 'label';

  @Input()
  @HostBinding('class.admin')
  isAdmin = false;
}
