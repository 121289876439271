import { Attachment, MimeTypesEnum } from '../dto';

export class AttachmentUtils {
  static getMimeType(attachment: Attachment | null): MimeTypesEnum | null {
    if (!attachment?.name) {
      return null;
    }
    const splits: string[] = attachment.name.split('.');
    const ext: string = splits[splits.length - 1].toUpperCase();
    return MimeTypesEnum[ext as keyof typeof MimeTypesEnum];
  }

  static isImage(attachment: Attachment | null): boolean {
    return !!attachment && !!this.getMimeType(attachment)?.startsWith('image');
  }

  static isPdf(attachment: Attachment | null): boolean {
    return !!attachment && this.getMimeType(attachment) === MimeTypesEnum.PDF;
  }
}
