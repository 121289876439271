import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DragEvent } from 'react';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import { LoaderComponent } from '../../loader';
import { scaleInAnimation, slideInFromSideAnimation, slideOutAnimation } from '../animations/animations.const';
import { FilePillComponentService } from './file-pill.component.service';

@Component({
  selector: 'dougs-file-pill',
  templateUrl: './file-pill.component.html',
  styleUrls: ['../scss/file-pill.scss'],
  standalone: true,
  imports: [CommonModule, LoaderComponent],
  providers: [FilePillComponentService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideOutAnimation, scaleInAnimation, slideInFromSideAnimation],
})
export class FilePillComponent {
  @Input() attachment!: Attachment;
  @Input() canDelete = true;
  @Input() square = false;
  @Input() enableAnimation = false;
  @Output() deleteFile: EventEmitter<Attachment> = new EventEmitter<Attachment>();
  @Output() download: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly filePillComponentService: FilePillComponentService) {}

  openFile(): void {
    if (this.attachment.url || this.attachment.file.url) {
      this.download.emit();
    }
  }

  async confirmDeleteFile(e: Event): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    const isDeleted: boolean = await this.filePillComponentService.confirmDeleteFile(this.attachment);
    if (isDeleted) {
      this.deleteFile.emit(this.attachment);
    }
  }

  onDragStart(e: DragEvent): void {
    e.dataTransfer?.clearData();
    e.dataTransfer.setData(
      'attachment',
      JSON.stringify({
        uuid: this.attachment.file.uuid,
        name: this.attachment.name,
        type: this.attachment.file?.extension === '.pdf' ? 'application/pdf' : undefined,
      }),
    );
  }

  protected readonly legacyApiServerUrl = AppConfig.settings.legacyApiServerUrl;
}
