<div dougsModalTitle>
  <h6>{{ data.title }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent class="modal-select-association-container">
  <dougs-form-field *ngIf="data.items.length !== 0 && !data.hideSearch">
    <i class="fal fa-search" dougsFormFieldPrefix></i>
    <input type="text" [formControl]="searchControl" dougsFormFieldControl placeholder="Rechercher" />
  </dougs-form-field>
  <ul class="select-candidate">
    <li *ngFor="let item of itemSearch" class="p-8" (click)="selectItem(item)">
      <img *ngIf="item.iconUrl" class="mr-16" [src]="item.iconUrl" [alt]="item.label" />
      <div>
        {{ item.label }}
        <p class="small" [innerHTML]="item.description"></p>
      </div>
    </li>
  </ul>
</div>
