<dougs-form-field [noMargin]="true" [size]="size">
  <div dougsFormFieldPrefix class="phone-prefix" [dougsDropdownTrigger]="phoneCodeDropdown">
    <img class="phone-code-flag" [src]="'images/flags/' + phoneCode.toLowerCase() + '.svg'" alt="Flag" />
    <i class="fal fa-chevron-down ml-4"></i>
  </div>
  <input type="tel" dougsFormFieldControl [(ngModel)]="value" (blur)="onBlur()" />
  <span
    dougsFormFieldError
    *ngIf="
      formService.isControlInvalid(control) && control?.hasError('invalidPhoneNumber') && !control?.hasError('required')
    "
  >
    Le numéro de téléphone est invalide
  </span>
  <span dougsFormFieldError *ngIf="formService.isControlInvalid(control) && control?.hasError('required')">
    Le numéro de téléphone est requis
  </span>
</dougs-form-field>
<dougs-dropdown #phoneCodeDropdown [maxHeight]="250" [widthToRefElement]="false">
  <dougs-dropdown-option
    *ngFor="let country of countries$ | async; trackBy: 'value' | trackBy"
    (click)="selectDialCode(country.value)"
    [closeOnClick]="true"
    class="phone-code-option"
  >
    <div class="phone-code-option__left">
      <img class="phone-code-flag" [src]="'images/flags/' + country.value + '.svg'" alt="Flag" />
      {{ country.label }}
    </div>
    <span>+{{ country.value | countryDialCode }}</span>
  </dougs-dropdown-option>
</dougs-dropdown>
