import { Attachment } from '../dto';

const getDefaults = (): Attachment => ({
  id: 1,
  modelId: 1,
  progress: undefined,
  file: {
    url: 'url',
  },
  name: 'name',
  companyId: 1,
});

export const getAttachmentMock = (p?: Partial<Attachment>): Attachment => ({
  ...getDefaults(),
  ...p,
});
