import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[dougsCardFooter]',
  standalone: true,
})
export class CardFooterDirective {
  @HostBinding('class.card__footer')
  cardFooter = true;
}
