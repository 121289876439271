import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance, isSameDay, startOfDay } from 'date-fns';
import { fr } from 'date-fns/locale';

@Pipe({
  name: 'formatDistanceToNowRoundedToDay',
  standalone: true,
})
export class FormatDistanceToNowRoundedToDayPipe implements PipeTransform {
  transform(rawDate: Date | string, addSuffix = false): string {
    const date: Date = startOfDay(typeof rawDate === 'string' ? new Date(rawDate) : rawDate);
    const today: Date = new Date();

    if (isSameDay(today, date)) {
      return "aujourd'hui";
    }

    return formatDistance(date, startOfDay(new Date()), {
      locale: fr,
      addSuffix,
    });
  }
}
