import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ConfigBackService } from '@dougs/core/config-back';

@Pipe({
  name: 'countryLabel',
  standalone: true,
})
export class CountryLabelPipe implements PipeTransform {
  constructor(private readonly configBackService: ConfigBackService) {}

  transform(countryCode: string): Observable<string> {
    return this.configBackService.countries$.pipe(
      map((countries) => countries?.find((country) => country.value === countryCode)?.label || countryCode),
    );
  }
}
