import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '../../components';

@Component({
  selector: 'dougs-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalContentDirective,
    ModalCloseDirective,
    ModalFooterDirective,
    ButtonComponent,
  ],
})
export class ConfirmationModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      title: string;
      body: string;
      yesText: string;
      noText: string;
      secondaryColor?: boolean;
    },
  ) {}
}
