<label [ngClass]="{ disabled: disabled }">
  <ng-container *ngIf="label && !labelAfter">
    <span
      [ngClass]="{ 'tiny-label': tinyLabel }"
      class="checkbox-label mr-4"
      *ngIf="!dougsFormFieldLabel"
      [innerHTML]="label"
    ></span>
    <span
      dougsFormFieldLabel
      [ngClass]="{ 'tiny-label': tinyLabel }"
      class="checkbox-label mr-8"
      *ngIf="dougsFormFieldLabel"
      [innerHTML]="label"
    ></span>
  </ng-container>
  <input [id]="id" type="checkbox" [(ngModel)]="value" [disabled]="disabled" [checked]="value === true" />
  <span class="checkmark no-margin" [ngClass]="{ 'darker-border': darkerBorder }" *ngIf="appearance === 'classic'">
    <i *ngIf="value === true" class="far fa-check"></i>
  </span>
  <ng-container *ngIf="label && labelAfter">
    <span
      [ngClass]="{ 'tiny-label': tinyLabel }"
      class="checkbox-label ml-8"
      *ngIf="!dougsFormFieldLabel"
      [innerHTML]="label"
    ></span>
    <span
      dougsFormFieldLabel
      [ngClass]="{ 'tiny-label': tinyLabel }"
      class="checkbox-label mr-8"
      *ngIf="dougsFormFieldLabel"
      [innerHTML]="label"
    ></span>
  </ng-container>
  <span class="slider" [ngClass]="{ checked: value === true, admin: isAdmin }" *ngIf="appearance === 'toggle'"></span>
  <ng-container *ngIf="showYesNo">
    <span class="ml-4 show-yes-no" [ngClass]="{ admin: isAdmin }">
      {{ value === true ? yesText : noText }}
    </span>
  </ng-container>
  <ng-content *ngIf="!showYesNo"></ng-content>
</label>
