import { AbstractControl, ValidatorFn } from '@angular/forms';

export const mustMatch = (controlName: string, matchingControlName: string): ValidatorFn => {
  return (formGroup: AbstractControl) => {
    const control: AbstractControl | null = formGroup.get(controlName);
    const matchingControl: AbstractControl | null = formGroup.get(matchingControlName);

    if (hasAnotherError(control) || hasAnotherError(matchingControl)) {
      return null;
    }

    return control?.value !== matchingControl?.value ? { mustMatch: true } : null;
  };
};

const hasAnotherError = (control: AbstractControl | null): boolean | null | undefined => {
  return control?.errors && !control?.errors.mustMatch;
};
