import { generateUuidV4 } from '@dougs/core/utils';
import { Attachment, SourceDocumentAttachment, SourceDocumentType } from '../dto';

export class StateAttachmentUtils {
  static getModelWithTempAttachment(
    files: FileList,
    attachmentsMap: Map<string, File>,
    companyId: number,
    modelId: number,
    disableProgress = false,
  ): Attachment[] {
    return Array.from(files).map((file) => {
      const uuid: string = generateUuidV4();
      attachmentsMap.set(uuid, file);
      return {
        id: uuid,
        file: {
          url: URL.createObjectURL(file),
        },
        url: URL.createObjectURL(file),
        companyId,
        modelId,
        name: file.name,
        fileName: file.name,
        progress: disableProgress ? undefined : 1,
      };
    });
  }

  static getModelWithTempSourceDocument(
    files: File[],
    attachmentsMap: Map<string, File>,
    companyId: number,
    modelId: number,
    type?: SourceDocumentType,
  ): SourceDocumentAttachment[] {
    return files.map((file) => {
      const uuid: string = generateUuidV4();
      attachmentsMap.set(uuid, file);
      return {
        id: -1,
        isDeletable: false,
        uuid,
        isTmp: true,
        sourceDocument: {
          id: -1,
          type: type ?? SourceDocumentType.UNKNOWN,
          externalId: null,
          companyId,
          createdAt: '',
          date: '',
          amount: null,
          file: {
            id: -1,
            name: file.name,
            url: URL.createObjectURL(file),
          },
          progress: 1,
          hasBeenUploadedNow: true,
          tempUuid: uuid,
        },
      };
    });
  }

  static addHasBeenUploadedNowToAttachment(attachment: Attachment): Attachment {
    return { ...attachment, hasBeenUploadedNow: true };
  }

  static removeAttachmentByUUIDFromModel<T>(model: T, attachmentUUID: string): T {
    if (!(model as any)?.['attachments']) {
      return model;
    }
    const filteredAttachments: Attachment[] =
      (model as any)?.attachments.filter(
        (attachmentIterated: Attachment) => attachmentIterated.id !== attachmentUUID,
      ) ?? [];
    return {
      ...model,
      attachments: filteredAttachments,
    };
  }

  static removeVendorInvoiceByUUIDFromModel<T>(model: T, attachmentUUID: string): T {
    if (!(model as any)?.['vendorInvoices']) {
      return model;
    }
    const filteredAttachments: Attachment[] =
      (model as any)?.vendorInvoices.filter(
        (attachmentIterated: Attachment) => attachmentIterated.id !== attachmentUUID,
      ) ?? [];
    return {
      ...model,
      vendorInvoices: filteredAttachments,
    };
  }
}
