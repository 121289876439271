import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { Attachment } from '@dougs/core/files';

@Component({
  selector: 'dougs-file-details',
  templateUrl: './file-details.component.html',
  styleUrls: ['./file-details.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDetailsComponent {
  private _attachment!: Attachment;
  private _classIcon!: string;

  @Input()
  set attachment(attachment: Attachment) {
    this._attachment = attachment;
    this._classIcon = this.getClassIcon();
  }

  get attachment() {
    return this._attachment;
  }

  get classIcon() {
    return this._classIcon;
  }

  @HostListener('click')
  openFile(): void {
    if (this.attachment.file.url) {
      this.window.open(this.attachment.file.url, '_blank');
    }
  }

  constructor(private readonly window: Window) {}

  private getClassIcon(): string {
    const split = this.attachment.file.name?.split('.') || [''];
    const ext = split[split.length - 1].toLowerCase();
    switch (ext) {
      case 'doc':
      case 'docx':
        return 'far fa-file-word';
      case 'pdf':
        return 'far fa-file-pdf';
      case 'png':
      case 'jpg':
        return 'far fa-file-image';
      case 'xls':
      case 'xlsx':
        return 'far fa-file-excel';
      case 'zip':
        return 'far fa-file-archive';
      default:
        return 'far fa-file';
    }
  }
}
