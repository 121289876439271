import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import {
  animationFrameScheduler,
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  endWith,
  interval,
  map,
  Subscription,
  switchMap,
  takeWhile,
} from 'rxjs';

@Directive({
  selector: '[dougsCountUp]',
  standalone: true,
})
export class CountUpDirective implements OnInit, OnDestroy {
  initialCount = 0;
  private readonly count$ = new BehaviorSubject(0);
  private readonly duration$ = new BehaviorSubject(2000);
  private readonly easeOutQuadPositive = (x: number): number => x * (2 - x);
  private readonly easeOutQuadNegative = (x: number): number => -x * (x - 2);

  private readonly currentCount$ = combineLatest([this.count$, this.duration$]).pipe(
    switchMap(([count, duration]) => {
      // get the time when animation is triggered
      const startTime = animationFrameScheduler.now();

      return interval(0, animationFrameScheduler).pipe(
        // calculate elapsed time
        map(() => animationFrameScheduler.now() - startTime),
        // calculate progress
        map((elapsedTime) => elapsedTime / duration),
        // complete when progress is greater than 1
        takeWhile((progress) => progress <= 1),
        // apply quadratic ease-out
        // for faster start and slower end of counting
        map((progress) =>
          count > this.initialCount ? this.easeOutQuadPositive(progress) : this.easeOutQuadNegative(progress),
        ),
        // calculate current count
        map((progress) => {
          if (Math.round(progress * 1000) === 1000) {
            this.initialCount = count;
          }

          return Math.round(progress * (count - this.initialCount) + this.initialCount);
        }),
        // make sure that last emitted value is count
        endWith(count),
        distinctUntilChanged(),
      );
    }),
  );

  private currentCountSubscription!: Subscription;

  @Input('dougsCountUp')
  set count(count: number) {
    this.count$.next(count);
  }

  @Input()
  set duration(duration: number) {
    this.duration$.next(duration);
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.displayCurrentCount();
  }

  private displayCurrentCount(): void {
    this.currentCountSubscription = this.currentCount$.subscribe((currentCount) => {
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', currentCount);
    });
  }

  ngOnDestroy(): void {
    this.currentCountSubscription?.unsubscribe();
  }
}
