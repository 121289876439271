import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioChange } from './radio-change.model';

@Component({
  selector: 'dougs-radio',
  templateUrl: './radio.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RadioComponent,
      multi: true,
    },
  ],
})
export class RadioComponent {
  static radioCount = 0;

  @Input() checked = false;

  @Input() name = '';

  @Input() disabled = false;

  @Input() required = false;

  @Input() value: unknown = '';

  @Input() id = `radio-${RadioComponent.radioCount++}`;

  @Output() radioChange: EventEmitter<RadioChange> = new EventEmitter<RadioChange>();

  @Input() noDesign = false;

  @Input() light = false;

  disabledFromGroup = false;
  radioChangeHandler!: (event: RadioChange) => void;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  onChange(event: Event) {
    event.stopPropagation();
  }

  onClick(event: Event) {
    this.checked = (event.target as HTMLInputElement).checked;
    const radioEvent = new RadioChange(this, this.value);
    this.radioChange.emit(radioEvent);
    this.radioChangeHandler(radioEvent);
  }

  registerRadioChangeHandler(fn: (event: RadioChange) => void) {
    this.radioChangeHandler = fn;
  }

  setDisabledFromGroup(disabled: boolean) {
    this.disabledFromGroup = disabled;
    this.cdr.markForCheck();
  }

  markForCheck(): void {
    this.cdr.markForCheck();
  }
}
