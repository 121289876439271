import { UserInfo } from '../dto';

const getDefaults = (): UserInfo => ({
  id: 1,
  avatarUrl: '',
  abbreviatedName: '',
  firstName: '',
  flags: [],
  fullName: '',
  lastName: '',
});

export const getUserInfoMock = (p?: Partial<UserInfo>): UserInfo => ({
  ...getDefaults(),
  ...p,
});
