import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

@Directive({
  selector: '[wistiaVideo]',
  standalone: true,
})
export class WistiaVideoDirective implements AfterViewInit, OnDestroy {
  constructor(private readonly el: ElementRef) {}

  _video!: any;
  _wistiaVideo!: string;
  get wistiaVideo(): string {
    return this._wistiaVideo;
  }

  @Input() set wistiaVideo(value: string) {
    const oldClass = `wistia_async_${this._wistiaVideo}`;
    const elementClassList = (this.el.nativeElement as HTMLElement).classList;
    if (elementClassList.contains(oldClass)) {
      elementClassList.remove(oldClass);
    }
    this._wistiaVideo = value;
    elementClassList.add(`wistia_async_${this.wistiaVideo}`);

    (<any>window)._wq = (<any>window)._wq || [];
    (<any>window)._wq.push({
      id: value,
      onReady: (video: any) => {
        this._video = video;

        const _videoPlayed: EventEmitter<void> = this.videoPlayed;
        const _videoEnded: EventEmitter<void> = this.videoEnded;

        video.bind('play', () => _videoPlayed.emit());
        video.bind('end', () => _videoEnded.emit());
      },
    });
  }

  @Input()
  @HostBinding('class.popover=true')
  popover = false;

  @Input()
  @HostBinding('class.videoFoam=true')
  videoFoam = false;

  @Input()
  @HostBinding('class.autoPlay=true')
  autoplay = false;

  @Output() videoPlayed: EventEmitter<void> = new EventEmitter<void>();
  @Output() videoEnded: EventEmitter<void> = new EventEmitter<void>();

  public play(): void {
    if (this._video) {
      this._video.play();
    }
  }

  public ngAfterViewInit(): void {
    const wistiaScriptLoaded = document.getElementById('wistiaE-v1-script');
    if (!wistiaScriptLoaded) {
      const scriptElement = document.createElement('script');
      scriptElement.setAttribute('id', 'wistiaE-v1-script');
      scriptElement.setAttribute('src', 'https://fast.wistia.com/assets/external/E-v1.js');
      scriptElement.async = true;
      document.body.appendChild(scriptElement);
    }
  }

  public ngOnDestroy(): void {
    const wistiaScriptElement = document.getElementById('wistiaE-v1-script');
    wistiaScriptElement?.remove();
  }

  @HostBinding('class') classes = 'wistia_embed';
}
