import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, concatMap, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AddressGouv } from './dtos/address-gouv.dto';

@Injectable()
export class AddressService {
  constructor(private readonly http: HttpClient) {}

  addressValueSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  addressValueChanges$: Observable<AddressGouv | null> = this.addressValueSubject.pipe(
    map((value) => value.trim()),
    debounceTime(300),
    distinctUntilChanged(),
    concatMap((value) => {
      if (value !== '' && value.length > 2) {
        return this.http.get<AddressGouv>(`https://api-adresse.data.gouv.fr/search/?q=${value}`).pipe(
          catchError((e) => {
            return of(null);
          }),
        );
      }

      return of(null);
    }),
  );
}
