import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[dougsCardBody]',
  standalone: true,
})
export class CardBodyDirective {
  @HostBinding('class')
  classes = 'card__body';
}
