import { isValid, parse } from 'date-fns';

const regexFR = /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/;
const regexUS = /^\d{4}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$/;
const regexFullDate = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;

export const isValidBackDate = (value: string | Date): boolean => {
  if (typeof value === 'string') {
    if (isValidUSDate(value)) {
      return true;
    }

    if (regexFullDate.test(value) && isValid(new Date(value))) {
      return true;
    }
  }

  return isValid(value);
};

export const isValidUSDate = (value: string): boolean => {
  return regexUS.test(value) && isValid(new Date(value));
};

export const isValidFrDate = (value: string): boolean => {
  return regexFR.test(value) && isValid(parse(value, 'dd/MM/yyyy', new Date()));
};
