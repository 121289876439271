import { Directive, HostBinding, HostListener, Input, Optional } from '@angular/core';
import { DrawerRef } from './drawer-ref';

@Directive({
  selector: '[dougsDrawerClose]',
  standalone: true,
})
export class DrawerCloseDirective {
  @Input('dougsDrawerClose') drawerResult: unknown;

  @HostBinding('class') classes = 'drawer-close';

  constructor(@Optional() public drawerRef: DrawerRef) {}

  @HostListener('click', ['$event'])
  onButtonClick() {
    this.drawerRef?.close(this.drawerResult);
  }
}
