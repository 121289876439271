import { Directive, InjectionToken } from '@angular/core';

export const FORM_FIELD_SUFFIX = new InjectionToken<SuffixFormFieldDirective>('SuffixFormFieldDirective');

@Directive({
  selector: '[dougsFormFieldSuffix]',
  standalone: true,
  providers: [{ provide: FORM_FIELD_SUFFIX, useExisting: SuffixFormFieldDirective }],
})
export class SuffixFormFieldDirective {}
