import { Clipboard } from '@angular/cdk/clipboard';
import { Directive, HostListener, Input } from '@angular/core';
import { FlashMessagesService } from '../../components';

@Directive({
  selector: '[dougsCopyToClipboard]',
  standalone: true,
})
export class CopyToClipboardDirective {
  constructor(
    private readonly clipboard: Clipboard,
    private readonly flashMessagesService: FlashMessagesService,
  ) {}

  @Input() dougsCopyToClipboard!: string;
  @Input() dougsCopyToClipboardDisabled?: boolean;

  @HostListener('click')
  copyToClipboard(): void {
    if (!this.dougsCopyToClipboardDisabled) {
      this.clipboard.copy(this.dougsCopyToClipboard);
      this.flashMessagesService.show('Copié dans le presse-papier !', {
        type: 'success',
      });
    }
  }
}
