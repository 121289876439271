import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis',
  standalone: true,
})
export class EllipsisPipe implements PipeTransform {
  transform(value: string, length: number): string {
    if (!value || value.length === 0) {
      return value;
    }

    if (value.length < length) {
      return value;
    }

    return `${value.slice(0, length)}...`;
  }
}
