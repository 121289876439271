import { addMonths, format, getMonth, getYear, setDay, setMonth, setYear, subMonths, subYears } from 'date-fns';
import { fr } from 'date-fns/locale/fr';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Year {
  year: number;
  isThisYear: boolean;
  isSelected: boolean;
}

export interface Month {
  month: number;
  label: string;
  isThisMonth: boolean;
  isSelected: boolean;
}

export interface DatepickerOptions {
  minDate?: Date | null;
  maxDate?: Date | null;
  formatTitle?: string;
}

export abstract class DatepickerCommonService {
  RANGE_YEARS = 14;

  protected readonly _optionsSubject: BehaviorSubject<DatepickerOptions> = new BehaviorSubject<DatepickerOptions>({
    minDate: null,
    maxDate: null,
    formatTitle: 'LLLL yyyy',
  });
  protected readonly _currentDateSubject: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  protected readonly _yearRangeSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  protected readonly _viewSubject: BehaviorSubject<'days' | 'months' | 'years'> = new BehaviorSubject<
    'days' | 'months' | 'years'
  >('days');

  title$: Observable<string> = combineLatest([this._currentDateSubject, this._optionsSubject]).pipe(
    map(([currentDate, options]) => this.getTitle(currentDate, options)),
  );

  months$: Observable<Month[]> = this._viewSubject.pipe(
    map((view) => {
      return view === 'months' ? this.initMonths() : [];
    }),
  );

  years$: Observable<Year[]> = combineLatest([this._yearRangeSubject, this._viewSubject]).pipe(
    map(([yearRange, view]) => {
      return view === 'years' ? this.initYears(yearRange) : [];
    }),
  );

  view$: Observable<'days' | 'months' | 'years'> = this._viewSubject.asObservable();

  dayNames: string[] = this.initDayNames();

  abstract isMonthSelected(monthIndex: number): boolean;

  abstract isYearSelected(year: number): boolean;

  get options(): DatepickerOptions {
    return this._optionsSubject.value;
  }

  set options(options: DatepickerOptions) {
    this._optionsSubject.next(options);
  }

  protected getTitle(currentDate: Date, options: DatepickerOptions): string {
    return format(currentDate, options.formatTitle ? (options.formatTitle as string) : 'LLLL yyyy', {
      locale: fr,
    });
  }

  protected initDayNames(): string[] {
    const dayNames: string[] = [];
    for (let i = 1; i <= 7; i++) {
      dayNames.push(format(setDay(new Date(), i), 'EEE', { locale: fr }).slice(0, -2));
    }
    return dayNames;
  }

  next(view: 'days' | 'months' | 'years'): void {
    if (view === 'days') {
      this._currentDateSubject.next(addMonths(this._currentDateSubject.value, 1));
    } else if (view === 'years') {
      this._yearRangeSubject.next(this._yearRangeSubject.value - 1);
    }
  }

  previous(view: 'days' | 'months' | 'years'): void {
    if (view === 'days') {
      this._currentDateSubject.next(subMonths(this._currentDateSubject.value, 1));
    } else if (view === 'years') {
      this._yearRangeSubject.next(this._yearRangeSubject.value + 1);
    }
  }

  setMonth(month: number): void {
    this._currentDateSubject.next(setMonth(this._currentDateSubject.value, month));
    this.toggleView('days');
  }

  setYear(year: number): void {
    this._currentDateSubject.next(setYear(this._currentDateSubject.value, year));
    this.toggleView('months');
  }

  toggleView(view: 'days' | 'months' | 'years'): void {
    this._viewSubject.next(view);
  }

  initMonths(): Month[] {
    const months: Month[] = [];
    for (let i = 0; i <= 11; i++) {
      months.push({
        month: i,
        isSelected: this.isMonthSelected(i),
        isThisMonth: i === getMonth(new Date()),
        label: format(setMonth(new Date(), i), 'MMM', {
          locale: fr,
        }).slice(0, -1),
      });
    }
    return months;
  }

  initYears(yearRange: number): Year[] {
    const date: Date = subYears(this._currentDateSubject.value, this.RANGE_YEARS * yearRange * 2);
    const range: number = getYear(date) + this.RANGE_YEARS - (getYear(date) - this.RANGE_YEARS);
    return Array.from(new Array(range), (_, i) => i + getYear(date) - this.RANGE_YEARS).map((year) => ({
      year,
      isThisYear: year === getYear(new Date()),
      isSelected: this.isYearSelected(year),
    }));
  }
}
