import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'dougs-ring-progress',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ring-progress.component.html',
  styleUrls: ['./ring-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('ringProgress', [
      transition(':enter', [
        style({
          strokeDashoffset: '{{ circumferenceProgress }}',
        }),
        animate('2s ease-in-out'),
      ]),
    ]),
  ],
})
export class RingProgressComponent {
  constructor(private readonly cdr: ChangeDetectorRef) {}

  circumferenceProgress = 9 * 2 * Math.PI;
  strokeProgress = 0;

  @Input() strokeColor: 'primary' | 'success' | 'warning' | 'green' | 'error' = 'primary';
  @Input() showPercentText = true;
  @Input() textLeft = true;
  @Input() isAnimated = false;

  private _strokeWidth = 3;
  @Input()
  set strokeWidth(strokeWidth: number) {
    this._strokeWidth = strokeWidth;
    this.calculateStrokeAndCircumference();
  }

  get strokeWidth() {
    return this._strokeWidth;
  }

  private _size = 24;
  @Input()
  set size(size: number) {
    this._size = size;
    this.calculateStrokeAndCircumference();
  }

  get size() {
    return this._size;
  }

  private _percent!: number;
  @Input()
  set percent(percent: number) {
    this._percent = percent;
    this.calculateStrokeAndCircumference();
  }

  get percent() {
    return this._percent;
  }

  calculateStrokeAndCircumference(): void {
    this.circumferenceProgress = (this.size / 2 - this.strokeWidth) * 2 * Math.PI;
    this.strokeProgress =
      this.circumferenceProgress - (Number.isNaN(this.percent) ? 0 : this.percent / 100) * this.circumferenceProgress;
    this.cdr.markForCheck();
  }
}
