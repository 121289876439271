import { AbstractControl, ValidatorFn } from '@angular/forms';

export const countCharacterLimit = (maxCharacters: number): ValidatorFn => {
  return (control: AbstractControl) => {
    if (!control.value || control.value.length <= maxCharacters) {
      return null;
    }
    return { countCharacterLimit: true };
  };
};
