import { Injectable } from '@angular/core';
import { Field } from '@dougs/fields/dto';

@Injectable()
export class CompletionService {
  constructor(
    private readonly window: Window,
    private readonly document: Document,
  ) {}

  private readonly settingsMenuHeight = 54;
  private readonly partnerMenuHeight = 46;
  private readonly marginFromTop = 8;

  scrollToMissingFields(e: Event, missingFieldKey: string, missingField: Field, container?: HTMLElement): void {
    e.preventDefault();

    const missingFieldToScroll: string = (
      missingField.modelName ? `${missingFieldKey}-${missingField.modelName}` : missingFieldKey
    ).replace(/\./g, '');

    if (missingFieldKey.includes('metadata.parents')) {
      return this.openAccordionAndScrollTo(missingFieldToScroll, 'parents-container', container);
    }

    if (missingFieldKey.includes('previousAccountant')) {
      return this.openAccordionAndScrollTo(missingFieldToScroll, 'previous-accountant');
    }

    if (missingFieldKey === 'ownerMissingFields') {
      return this.openOwnerPartner(missingFieldKey);
    }

    if (missingFieldKey === 'affiliatePartnersMissingFields') {
      return this.scrollToElement('partnersMissingFields');
    }

    if (missingFieldKey === 'retirementContracts' || missingFieldKey === 'metadata.social.retirementOrganism') {
      return this.openAccordionAndScrollTo(missingFieldToScroll, 'retirement');
    }

    if (
      missingFieldKey === 'metadata.social.cooperative' ||
      missingFieldKey === 'healthContracts' ||
      missingFieldKey === 'metadata.social.cooperativeRate'
    ) {
      return this.openAccordionAndScrollTo(missingFieldToScroll, 'cooperative');
    }

    if (
      missingFieldKey === 'metadata.social.providentOrganismExecutiveRate' ||
      missingFieldKey === 'metadata.social.providentOrganismRate' ||
      missingFieldKey === 'metadata.social.providentOrganism' ||
      missingFieldKey === 'providentSocietyContracts'
    ) {
      return this.openAccordionAndScrollTo(missingFieldToScroll, 'provident');
    }

    this.scrollToElement(missingFieldToScroll, container);
  }

  private openAccordionAndScrollTo(missingFieldKey: string, accordionId: string, container?: HTMLElement): void {
    const accordionElement: HTMLElement | null = this.document.getElementById(accordionId);

    if (
      accordionElement &&
      !accordionElement.className.includes('active') &&
      !accordionElement.className.includes('accordion__header--opened')
    ) {
      accordionElement.click();
    }

    setTimeout(() => {
      this.scrollToElement(missingFieldKey, container);
    }, 0);
  }

  private openOwnerPartner(missingFieldKey: string): void {
    this.document.getElementById(missingFieldKey)?.click();
  }

  private scrollToElement(missingFieldKey: string, container?: HTMLElement): void {
    const element: HTMLElement | null = container
      ? container.querySelector(`#${missingFieldKey}`)
      : this.document.getElementById(missingFieldKey);

    if (element) {
      const distanceFromTop: number = !container
        ? element.getBoundingClientRect().top -
          this.settingsMenuHeight -
          this.marginFromTop -
          this.document.body.getBoundingClientRect().top
        : element.getBoundingClientRect().top -
          this.marginFromTop -
          this.partnerMenuHeight -
          container.getBoundingClientRect().top;

      (container || this.window).scrollTo({
        top: distanceFromTop,
        behavior: 'smooth',
      });
    }
  }
}
