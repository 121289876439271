import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ClickOutsideDirective } from '../../directives';
import { ButtonComponent } from '../button';

@Component({
  selector: 'dougs-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ClickOutsideDirective, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, paddingTop: 0, paddingBottom: 0 }),
        animate('200ms ease-in-out', style({ height: '*', paddingTop: '*', paddingBottom: '*' })),
      ]),
      transition(':leave', [animate('200ms ease-in-out', style({ height: 0, paddingTop: 0, paddingBottom: 0 }))]),
    ]),
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SearchBarComponent,
      multi: true,
    },
  ],
})
export class SearchBarComponent implements ControlValueAccessor {
  @Input() searchInProgress = false;
  @Output() clearSearch: EventEmitter<void> = new EventEmitter();
  @Output() toggleFilterBlockEmitter: EventEmitter<boolean> = new EventEmitter();
  isActive = false;
  @Input() smallSize = false;
  @Input() size: 'tiny' | 'small' | 'medium' = 'medium';
  @Input() inModal = false;
  @Input() placeholder = 'Rechercher...';
  @Input() hasFilterBlock = false;
  @Input() hasSelectedFilters = false;
  @Input() hideCloseButton = false;
  @Input() alwaysShowSuffixFilter = false;
  showFilterBlock = false;
  @ViewChild('inputSearch')
  private readonly inputSearch?: ElementRef;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  private _value!: string;

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
    this.onChange(value);
  }

  @HostListener('window:keydown.escape')
  async onEscape(): Promise<void> {
    if (this.inputSearch) {
      this.inputSearch.nativeElement.blur();
      this.searchInProgress = false;
      this.onClickOutside();
      this.clearSearch.emit();
    }
  }

  @HostListener('window:keydown', ['$event'])
  async onFocusSearch(event: KeyboardEvent): Promise<void> {
    if ((event.metaKey || event.ctrlKey) && event.key === '/' && this.inputSearch) {
      event.preventDefault();
      event.stopPropagation();
      this.onClick();
      this.inputSearch.nativeElement.focus();
    }
  }

  @HostListener('click')
  onClick(): void {
    if (!this.searchInProgress) {
      this.isActive = true;
      this.cdr.markForCheck();
    }
  }

  onClickOutside(): void {
    if (!this.searchInProgress) {
      this.isActive = false;
      this.cdr.markForCheck();
    }
  }

  toggleFilterBlock(): void {
    this.showFilterBlock = !this.showFilterBlock;
    this.toggleFilterBlockEmitter.emit(this.showFilterBlock);
  }

  public focusSearch(): void {
    this.inputSearch?.nativeElement?.focus();
  }

  onChange: (value: string) => void = () => true;
  onTouched: () => void = () => true;

  writeValue(value: string): void {
    this._value = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
