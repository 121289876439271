import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[dougsClickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  @Output() clickOutside: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(target: Node): void {
    const clickedInside: boolean = (this.elementRef.nativeElement as HTMLElement).contains(target);

    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }
}
