<div
  class="settings-completion"
  [ngClass]="{
    'p-16': smallSize,
    'px-32 py-24': !smallSize,
    'no-bottom-border': !bottomBorder,
    'mb-16': bottomMargin,
    'no-border': !showBorder
  }"
  *ngIf="hasMissingFields"
>
  <div
    *ngIf="showHeader"
    class="settings-completion__header"
    [ngClass]="{ 'align-center': !missingFields, 'mb-16': missingFields }"
  >
    <div
      *ngIf="!completionOnAccountingSurvey"
      class="settings-completion__header__title"
      [ngClass]="{ 'mb-8': missingFields }"
    >
      <h5>
        <ng-content></ng-content>
      </h5>
      <dougs-ring-progress
        strokeColor="warning"
        [size]="24"
        [percent]="completionPercent"
        *ngIf="!isWaitingForCompletionPercentageUpdate"
      >
        <div class="settings-completion__header__percent" [ngClass]="{ 'small-size': smallSize }">
          <div class="settings-completion__header__percent__value mr-8">
            <span [dougsCountUp]="completionPercent"></span>
            <span class="settings-completion__header__percent__unit" [ngClass]="{ 'small-size': smallSize }">%</span>
          </div>
        </div>
      </dougs-ring-progress>
    </div>
    <ng-container *ngIf="!isWaitingForCompletionPercentageUpdate">
      <p *ngIf="missingFields && !smallSize" class="small">
        {{ baseline }}
      </p>
      <dougs-button class="mt-8" *ngIf="completionOnAccountingSurvey">
        <a [routerLink]="settingsLink">Compléter mes paramètres&nbsp;&rarr;</a>
      </dougs-button>
    </ng-container>
  </div>
  <div class="settings-completion__body pl-16" *ngIf="missingFields || completionOnSettings">
    <ul [ngClass]="{ 'one-item': oneItem }" *ngIf="missingFields && !isWaitingForCompletionPercentageUpdate">
      <li class="mb-8" *ngFor="let missingField of missingFields | keyvalue | slice: 0 : 5">
        <a
          *ngIf="missingFieldsLink"
          href="#"
          (click)="completionService.scrollToMissingFields($event, missingField.key, missingField.value, container)"
        >
          <p class="small">{{ missingField.value.completionMessage }}</p>
        </a>
        <p class="small" *ngIf="!missingFieldsLink">{{ missingField.value.completionMessage }}</p>
      </li>
      <li *ngIf="missingFieldsLength > 5">
        <p class="small">+ {{ missingFieldsLength - 5 }} autres</p>
      </li>
    </ul>
    <dougs-loader *ngIf="!missingFields || isWaitingForCompletionPercentageUpdate"></dougs-loader>
  </div>
</div>
