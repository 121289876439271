import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dougsCommandClick]',
  standalone: true,
})
export class CommandClickDirective implements OnInit, OnDestroy {
  private unlisten!: () => void;

  constructor(
    private readonly renderer: Renderer2,
    private readonly element: ElementRef,
  ) {}

  @Output('dougsCommandClick') ctrlClickEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output('dougsShiftClick') shiftClickEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output('dougsClick') dougsClickEvent: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {
    this.unlisten = this.renderer.listen(this.element.nativeElement, 'click', (event: MouseEvent) => {
      if (event.shiftKey) {
        event.preventDefault();
        event.stopPropagation();
        this.shiftClickEvent.emit();
      } else if (event.metaKey || event.ctrlKey) {
        const target: HTMLElement | null = event.target as HTMLElement;
        const targetParent: HTMLElement | null | undefined = target?.parentElement;
        if (!this.isLink(target) && !this.isLink(targetParent)) {
          event.preventDefault();
          event.stopPropagation();
        }
        this.ctrlClickEvent.emit();
      } else {
        this.dougsClickEvent.emit();
      }
    });
  }

  private isLink(element: HTMLElement | null | undefined): boolean {
    return !!element && element instanceof HTMLAnchorElement && !!element?.href;
  }

  ngOnDestroy(): void {
    if (this.unlisten) {
      this.unlisten();
    }
  }
}
