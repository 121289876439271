import { Pipe, PipeTransform } from '@angular/core';
import { StepStatus } from './step-status.enum';

@Pipe({
  name: 'stepperStatusClass',
  standalone: true,
})
export class StepperStatusClassPipe implements PipeTransform {
  transform(status: StepStatus): string {
    return status?.toLocaleLowerCase().replace('_', '-') || '';
  }
}
