import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultAvatarUrl',
  standalone: true,
})
export class DefaultAvatarUrlPipe implements PipeTransform {
  transform(value: string | undefined | null): string {
    return value ?? 'https://www.gravatar.com/avatar/null?s=256&d=mm';
  }
}
