import { formatDistance } from "./tr/_lib/formatDistance.mjs";
import { formatLong } from "./tr/_lib/formatLong.mjs";
import { formatRelative } from "./tr/_lib/formatRelative.mjs";
import { localize } from "./tr/_lib/localize.mjs";
import { match } from "./tr/_lib/match.mjs";

/**
 * @category Locales
 * @summary Turkish locale.
 * @language Turkish
 * @iso-639-2 tur
 * @author Alpcan Aydın [@alpcanaydin](https://github.com/alpcanaydin)
 * @author Berkay Sargın [@berkaey](https://github.com/berkaey)
 * @author Fatih Bulut [@bulutfatih](https://github.com/bulutfatih)
 * @author Ismail Demirbilek [@dbtek](https://github.com/dbtek)
 * @author İsmail Kayar [@ikayar](https://github.com/ikayar)
 *
 *
 */
export const tr = {
  code: "tr",
  formatDistance: formatDistance,
  formatLong: formatLong,
  formatRelative: formatRelative,
  localize: localize,
  match: match,
  options: {
    weekStartsOn: 1 /* Monday */,
    firstWeekContainsDate: 1
  }
};

// Fallback for modularized imports:
export default tr;