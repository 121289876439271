<div class="invoice__preview p-24">
  <div class="invoice__preview__responsive-wrapper">
    <div
      class="invoice__preview__not-supported"
      *ngIf="!invoicePreviewComponentService.isInvoiceFileSupported$() || !invoicePreviewComponentService.url$()"
    >
      <img src="images/illustrations/not-supported-vendor-invoice.svg" alt="Not supported" class="mb-16" />
      <p class="small invoice__preview__not-supported__text">
        Nous ne pouvons pas lire ce format de fichier
        <img src="images/emoji/confused-face.png" alt="Sorry" />
      </p>
      <p class="tiny">Fichiers acceptés : pdf, jpeg, png, gif, svg...</p>
    </div>
    <img
      class="invoice__preview__image p-16"
      *ngIf="invoicePreviewComponentService.isInvoiceFileImage$() && invoicePreviewComponentService.url$()"
      [src]="invoicePreviewComponentService.url$()"
      [alt]="invoicePreviewComponentService.invoiceFile$()?.fileName"
    />
    <iframe
      *ngIf="
        invoicePreviewComponentService.isInvoiceFileSupported$() &&
        invoicePreviewComponentService.url$() &&
        !invoicePreviewComponentService.isInvoiceFileImage$()
      "
      [src]="invoicePreviewComponentService.url$()"
    ></iframe>
  </div>
</div>
