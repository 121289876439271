import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateSmallestUnitOfTimeDifference',
  standalone: true,
})
export class DateSmallestUnitOfTimeDifferencePipe implements PipeTransform {
  transform(date: string | Date): string {
    const dateTimestampDifference: number = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);

    switch (true) {
      case dateTimestampDifference < 60:
        return `il y a ${dateTimestampDifference}s`;
      case dateTimestampDifference < 3600:
        return `il y a ${Math.floor(dateTimestampDifference / 60)}m`;
      default:
        return this.transformFromDays(dateTimestampDifference);
    }
  }

  private transformFromDays(dateTimestampDifference: number): string {
    const secondsInADay = 3600 * 24;

    switch (true) {
      case dateTimestampDifference < 2 * secondsInADay:
        return 'hier';
      case dateTimestampDifference < 3 * secondsInADay:
        return 'avant-hier';
      default:
        return `il y a ${Math.floor(dateTimestampDifference / secondsInADay)}j`;
    }
  }
}
