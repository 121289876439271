import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Type } from '@angular/core';
import { take } from 'rxjs';
import { DrawerAnimationService } from './drawer-animation.service';
import { DrawerRef } from './drawer-ref';

export const OPEN_ANIMATION_DURATION = 400;
export const CLOSE_ANIMATION_DURATION = 350;

export const _defaultParams = {
  params: {
    enterAnimationDuration: `${OPEN_ANIMATION_DURATION}ms`,
    exitAnimationDuration: `${CLOSE_ANIMATION_DURATION}ms`,
  },
};

@Component({
  selector: 'dougs-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[@dialogContainer]': `getAnimationState()`,
  },
  animations: [
    trigger('dialogContainer', [
      state('void, exit', style({ transform: 'translateX(100%)' })),
      state('enter', style({ transform: 'translateX(0)' })),
      transition(
        '* => enter',
        group([
          animate('{{enterAnimationDuration}} cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateX(0)' })),
        ]),
        _defaultParams,
      ),
      transition(
        '* => void, * => exit',
        group([
          animate(
            '{{exitAnimationDuration}} cubic-bezier(0.25, 0.8, 0.25, 1)',
            style({ transform: 'translateX(100%)' }),
          ),
        ]),
        _defaultParams,
      ),
    ]),
  ],
})
export class DrawerComponent implements OnInit {
  component!: Type<unknown>;
  _state: 'void' | 'enter' | 'exit' = 'enter';

  constructor(
    public readonly ref: DrawerRef,
    private readonly drawerAnimationService: DrawerAnimationService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  close(): void {
    this.ref.close();
  }

  ngOnInit(): void {
    this.component = this.ref.content as Type<unknown>;
    this.drawerAnimationService.closeDrawer$.pipe(take(1)).subscribe(() => this.startExitAnimation());
  }

  startExitAnimation(): void {
    this._state = 'exit';
    this.cdr.markForCheck();
    setTimeout(() => {
      this.drawerAnimationService.animationEnd.next();
    }, CLOSE_ANIMATION_DURATION);
  }

  getAnimationState(): {
    value: 'void' | 'enter' | 'exit';
  } {
    return {
      value: this._state,
    };
  }
}
