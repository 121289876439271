import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigBackService, Country } from '@dougs/core/config-back';
import { ButtonComponent } from '../../button';
import {
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
} from '../../form-field';
import {
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '../../modal';
import { SelectComponent, SelectOptionComponent } from '../../select';
import { AddressFieldsEnum } from '../dtos/address-fields.enum';

@Component({
  selector: 'dougs-address-not-listed-modal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ModalTitleDirective,
    ControlFormFieldDirective,
    FormFieldComponent,
    LabelFormFieldDirective,
    ReactiveFormsModule,
    FormsModule,
    ErrorFormFieldDirective,
    SelectComponent,
    SelectOptionComponent,
  ],
  templateUrl: './address-not-listed-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressNotListedModalComponent implements OnInit {
  addressFormGroup = new FormGroup({
    street: new FormControl(''),
    city: new FormControl('', [Validators.required]),
    zipcode: new FormControl('', [Validators.required]),
    additionalAddress: new FormControl(''),
    country: new FormControl({} as Country),
  });
  protected readonly AddressFieldsEnum = AddressFieldsEnum;

  setValidatorsOnCountryChanged$: Observable<void | null> = this.addressFormGroup.controls['country'].valueChanges.pipe(
    map((country) => {
      this.triggerZipcodeValidatorIfFrance(country);
    }),
  );

  constructor(
    private readonly modalRef: ModalRef,
    @Inject(MODAL_DATA) public addressFieldsToDisplay: AddressFieldsEnum[],
    public readonly configBackService: ConfigBackService,
  ) {}

  ngOnInit(): void {
    this.setRelevantValidators();
  }

  onSubmit(): void {
    this.addressFormGroup.markAllAsTouched();
    if (this.addressFormGroup.valid) {
      this.modalRef.close(this.addressFormGroup.value);
    }
  }
  trackByValue(index: number, item: Country): string {
    return item.value;
  }

  private setRelevantValidators(): void {
    if (this.addressFieldsToDisplay.includes(AddressFieldsEnum.STREET)) {
      this.addressFormGroup.controls['street'].setValidators([Validators.required]);
    }
    if (this.addressFieldsToDisplay.includes(AddressFieldsEnum.COUNTRY)) {
      this.addressFormGroup.controls['country'].setValidators([Validators.required]);
    }
  }
  private triggerZipcodeValidatorIfFrance(country: Country | null): void {
    if (country?.value === 'fr') {
      this.addressFormGroup.controls['zipcode'].setValidators([Validators.required]);
    } else {
      this.addressFormGroup.controls['zipcode'].clearValidators();
    }
    this.addressFormGroup.controls['zipcode'].updateValueAndValidity();
  }
}
