export enum CONNECTION_STATUS_CODE {
  OK = 'ok',
  SYNCHRONIZATION_FAILED = 'synchronizationFailed',
  NOT_SUPPORTED = 'notSupported',
  RATE_LIMITING = 'rateLimiting',
  BANK_ACTION_NEEDED = 'bankActionNeeded',
  BANK_PASSWORD_ROTATION = 'bankPasswordRotation',
  WRONGPASS = 'wrongpass',
  AUTHENTICATION_REQUIRED = 'authenticationRequired',
  AUTHENTICATION_ACTION_NEEDED = 'authenticationActionNeeded',
  PENDING = 'pending',
  UNKNOWN = 'unknown',
  REQUEST_PROVIDER_FAILED = 'requestProviderFailed',
}
