import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InvoiceFile } from '@dougs/core/files';
import { TrustRessourceUrlPipe } from '../../pipes';
import { InvoicePreviewComponentService } from './invoice-preview.component.service';

@Component({
  selector: 'dougs-invoice-preview',
  templateUrl: './invoice-preview.component.html',
  styleUrls: ['./invoice-preview.component.scss'],
  standalone: true,
  imports: [CommonModule],
  providers: [InvoicePreviewComponentService, TrustRessourceUrlPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicePreview {
  constructor(public readonly invoicePreviewComponentService: InvoicePreviewComponentService) {}

  @Input()
  set invoiceFile(invoiceFile: InvoiceFile | null) {
    if (invoiceFile) {
      this.invoicePreviewComponentService.setInvoiceFile(invoiceFile);
    }
  }
}
