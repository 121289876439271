import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyISOCode } from '@dougs/core/config-back';

@Pipe({
  name: 'currencyClass',
  standalone: true,
})
export class CurrencyClassPipe implements PipeTransform {
  transform(value: CurrencyISOCode): string | null {
    return this.getCurrencyClass(value);
  }

  private getCurrencyClass(currencyCode: CurrencyISOCode): string | null {
    switch (true) {
      case currencyCode === CurrencyISOCode.EUR:
      case currencyCode === null:
        return 'fa-euro-sign';
      case currencyCode === CurrencyISOCode.USD:
        return 'fa-dollar-sign';
      case currencyCode === CurrencyISOCode.GBP:
        return 'fa-pound-sign';
      case currencyCode === CurrencyISOCode.CNY:
        return 'fa-yen-sign';
      default:
        return null;
    }
  }
}
