import { OverlayRef } from '@angular/cdk/overlay';
import { Type } from '@angular/core';
import { Subject, take } from 'rxjs';

import { filter } from 'rxjs/operators';
import { DrawerAnimationService } from './drawer-animation.service';
import { DrawerConfig } from './drawer-config.model';
import { DrawerService } from './drawer.service';

export interface OverlayDrawerCloseEvent<R> {
  type: 'backdropClick' | 'close' | 'keyboard';
  data: R;
}

// R = Response Data Type, T = Data passed to Drawer Type
export class DrawerRef<R = unknown, T = unknown> {
  afterClosed$ = new Subject<OverlayDrawerCloseEvent<R | null | undefined>>();
  beforeClosed$ = new Subject<OverlayDrawerCloseEvent<R | null | undefined>>();

  type: 'backdropClick' | 'close' | 'keyboard' = 'close';
  data: R | null | undefined = null;

  constructor(
    public overlay: OverlayRef,
    public content: Type<unknown>,
    public drawerAnimationService: DrawerAnimationService,
    private readonly drawerService: DrawerService,
    public config?: DrawerConfig<T>, // pass data to drawer i.e. FormData
  ) {
    if (!this.config?.disableCloseOnEscape) {
      overlay
        .keydownEvents()
        .pipe(filter((event: KeyboardEvent) => event.key === 'Escape' && !this.config?.disableClose))
        .subscribe((event: KeyboardEvent) => {
          event.preventDefault();
          this._close('keyboard', null);
        });
    }

    overlay.backdropClick().subscribe(() => {
      if (!this.config?.disableClose && !this.config?.disableBackdropClose) {
        this._close('backdropClick', null);
      }
    });

    this.drawerAnimationService.animationEnd$.pipe(take(1)).subscribe(() => {
      this.overlay.dispose();

      this.afterClosed$.next({
        type: this.type,
        data: this.data,
      });

      this.afterClosed$.complete();
    });
  }

  close(data?: R): void {
    this._close('close', data);
  }

  switchLargeToSmall(): void {
    this.overlay.removePanelClass('large');
    this.overlay.addPanelClass('small');
  }

  switchSmallToLarge(): void {
    this.overlay.removePanelClass('small');
    this.overlay.addPanelClass('large');
  }

  private _close(type: 'backdropClick' | 'close' | 'keyboard', data: R | null | undefined): void {
    document.body.classList.remove('drawer-opened');
    this.type = type;
    this.data = data;
    this.beforeClosed$.next({
      type: this.type,
      data: this.data,
    });
    this.beforeClosed$.complete();
    this.drawerService.onDrawerClose();
    this.drawerAnimationService.closeDrawer.next();
  }
}
