import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FlashMessage, FlashMessageOptions } from './flash-message';

@Injectable({
  providedIn: 'root',
})
export class FlashMessagesService {
  private messages: FlashMessage[] = [];
  private readonly messagesSubject: BehaviorSubject<FlashMessage[]> = new BehaviorSubject<FlashMessage[]>(
    this.messages,
  );
  public messages$: Observable<FlashMessage[]> = this.messagesSubject.asObservable();

  show(content: string | HTMLElement, options?: FlashMessageOptions): FlashMessage {
    const message: FlashMessage = new FlashMessage(content, options);
    this.messages = [message, ...this.messages];
    this.startTimer(message);
    this.messagesSubject.next(this.messages);
    return message;
  }

  close(message: FlashMessage): void {
    this.stopTimer(message);
    this.remove(message);
  }

  startTimer(message: FlashMessage): void {
    if (message.options.timeout) {
      message.timeoutId = window.setTimeout(() => {
        this.remove(message);
      }, message.options.timeout);
    }
  }

  stopTimer(message: FlashMessage): void {
    clearTimeout(message.timeoutId);
  }

  private remove(message: FlashMessage): void {
    const index: number = this.messages.indexOf(message);
    if (index !== -1) {
      this.messages = this.messages.filter((_, i) => i !== index);
    }
    this.messagesSubject.next(this.messages);
  }
}
