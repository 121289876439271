import { ConnectionStatusMessage } from './connection.dto';

export class ConnectionStatusError extends Error {
  public userMessage?: string;
  public userMessageType?: string;

  constructor(connectionStatusMessage: ConnectionStatusMessage) {
    super(connectionStatusMessage.message);
    this.userMessage = connectionStatusMessage.userMessage;
    this.userMessageType = connectionStatusMessage.userMessageType;
  }
}
