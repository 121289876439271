import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dougs-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit {
  @Input() loadingTexts: string[] = [];
  @HostBinding('class')
  @Input()
  appearance: 'spin' | 'dots' = 'dots';
  @Input() align: 'left' | 'center' = 'center';

  private activeTextIndex = 0;
  activeLoadingText = '';

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.loadingTexts.length > 0) {
      setTimeout(() => {
        this.activeLoadingText = this.loadingTexts[0];
        this.cdr.detectChanges();

        setInterval(() => {
          this.activeLoadingText = this.loadingTexts[this.activeTextIndex % this.loadingTexts.length];
          this.activeTextIndex++;
          this.cdr.detectChanges();
        }, 3000);
      }, 2000);
    }
  }
}
