export interface ConversationSummary {
  id: string;
  date: string;
  content: string;
  type: ConversationType;
  isClosed?: boolean | null;
  messageSummaries?: MessageSummary[];
  collaborator?: Author | null;
  duration?: number;
}

export interface MessageSummary {
  id: string;
  content: string;
  author: Author | null;
}

export interface Author {
  id: number;
  name: string;
  avatarUrl: string;
  isCollaborator: boolean;
  department: string | null;
}

export enum ConversationType {
  RINGOVER = 'ringover',
  INTERCOM = 'intercom',
}
