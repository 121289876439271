import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[dougsAutofocus]',
  standalone: true,
})
export class AutofocusDirective implements AfterViewInit {
  constructor(private readonly host: ElementRef) {}

  private _dougsAutofocus = true;

  get dougsAutofocus(): boolean {
    return this._dougsAutofocus;
  }

  @Input('dougsAutofocus') set dougsAutofocus(value: boolean) {
    // When using the directive without specifying an input value, the default value is an empty string and will be considered as false
    if (value === false) {
      this._dougsAutofocus = value;
    }
  }

  ngAfterViewInit(): void {
    if (!this.dougsAutofocus) {
      return;
    }
    setTimeout(() => this.host.nativeElement.focus());
  }
}
