import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[dougsStepTitle]',
  standalone: true,
})
export class StepTitleDirective {
  @HostBinding('class')
  get elementClasses(): string {
    return `step-title`;
  }
}
