import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { TrackByPipe } from '../../pipes';
import { MenuItem } from './menu.dto';

const ADDED_WIDTH = 10;
const SUBTRACT_LEFT = 5;

@Component({
  selector: 'dougs-menu',
  standalone: true,
  imports: [CommonModule, TrackByPipe],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements AfterViewInit, OnDestroy {
  @Input() menuItems!: MenuItem[];
  @Input() headerSize = 70;
  @Input() container?: HTMLElement;
  @Output() itemSelected = new EventEmitter<MenuItem>();

  public activeItemId!: string;

  @ViewChildren('menuItemLink') menuItemLinkList!: QueryList<ElementRef>;

  floatingBorderWidth = ADDED_WIDTH;
  floatingBorderLeft = -SUBTRACT_LEFT;
  floatingBorderHoverWidth = 0;
  floatingBorderHoverLeft = 0;

  constructor(
    private readonly document: Document,
    private readonly cdr: ChangeDetectorRef,
    private readonly window: Window,
  ) {}

  ngAfterViewInit(): void {
    (this.container || this.window).addEventListener('scroll', this.detectSection.bind(this));
    this.initActiveMenuItem();
  }

  initActiveMenuItem(): void {
    const defaultActiveItem: MenuItem | undefined = this.menuItems?.find((menuItem) => menuItem.isActiveByDefault);
    const linkElement: ElementRef | undefined = this.menuItemLinkList.find(
      (link) => link.nativeElement.id === defaultActiveItem?.id,
    );
    if (linkElement) {
      this.changingFloatingBorder(linkElement.nativeElement);
      this.changingFloatingHoverBorder(linkElement.nativeElement);
      this.cdr.markForCheck();
    }
  }

  activateMenu(e: Event, menuItem: MenuItem): void {
    e.preventDefault();
    this.itemSelected.emit(menuItem);
    this.scrollIntoSection(menuItem);
  }

  activateMenuHover(elementRef: HTMLAnchorElement): void {
    this.changingFloatingHoverBorder(elementRef);
  }

  desactivateMenuHover(): void {
    this.floatingBorderHoverWidth = this.floatingBorderWidth;
    this.floatingBorderHoverLeft = this.floatingBorderLeft;
  }

  changingFloatingBorder(element: HTMLLinkElement): void {
    this.floatingBorderWidth = element.offsetWidth + ADDED_WIDTH;
    this.floatingBorderLeft = element.offsetLeft - SUBTRACT_LEFT;
  }

  changingFloatingHoverBorder(element: HTMLLinkElement | HTMLAnchorElement): void {
    this.floatingBorderHoverWidth = element.offsetWidth + ADDED_WIDTH;
    this.floatingBorderHoverLeft = element.offsetLeft - SUBTRACT_LEFT;
  }

  scrollIntoSection(menuItem: MenuItem): void {
    const anchorElement: HTMLElement | null = this.document.getElementById(menuItem.anchorId);
    anchorElement?.scrollIntoView({ behavior: 'smooth' });
  }

  detectSection(): void {
    this.menuItems.some((menuItem) => {
      const anchorElement: HTMLElement | null = this.document.getElementById(menuItem.anchorId);
      const isInSection: boolean = !!anchorElement && this.isInSection(anchorElement);
      const linkElement: ElementRef | undefined = this.menuItemLinkList?.find(
        (linkElement) => linkElement.nativeElement.id === menuItem.id,
      );
      if (isInSection && !!linkElement) {
        this.activeItemId = menuItem.id;
        this.changingFloatingBorder(linkElement.nativeElement);
        this.changingFloatingHoverBorder(linkElement.nativeElement);
        this.cdr.markForCheck();
        return true;
      }
      return false;
    });
  }

  isInSection(element: HTMLElement): boolean {
    return (
      element &&
      element.getBoundingClientRect().bottom - (this.headerSize - 10) > 0 &&
      element.getBoundingClientRect().top - (this.headerSize + 1) < 0
    );
  }

  ngOnDestroy(): void {
    (this.container || this.window).removeEventListener('scroll', this.detectSection.bind(this));
  }
}
