import * as _ from 'lodash';

export function mergeObjects<T, U>(obj: T, ...src: U[]): T & object {
  return _.mergeWith({}, obj, ...src, mergeArrayFn);
}

function mergeArrayFn<T, U>(obj: T, src: U): T | U | undefined {
  if (Array.isArray(obj) && Array.isArray(src)) {
    return src;
  }
  return undefined;
}
