<ng-container *ngIf="filesPreviewComponentService.selectDefaultFilePreview$ | async"></ng-container>
<div class="files-preview">
  <div class="files-preview__header">
    <div
      class="files-preview__header__filename p-16"
      [ngClass]="{ 'no-border': noFilenameBorder }"
      *ngIf="!noFilenameHeader"
    >
      <i
        [ngClass]="filesPreviewComponentService.currentFilePreview$()?.fileName | mimeType | classIcon"
        class="mr-8"
      ></i>
      {{ filesPreviewComponentService.currentFilePreview$()?.fileName }}
    </div>
    <div class="files-preview__header__navigation p-16" *ngIf="filesPreviewComponentService.filesPreview$().length > 1">
      <p class="small mr-12">
        {{ filesPreviewComponentService.currentFilePreviewIndex$() + 1 }}/{{
          filesPreviewComponentService.filesPreview$().length
        }}
        fichiers
      </p>
      <dougs-icon-button
        (click)="filesPreviewComponentService.selectPreviousFile()"
        [disabled]="!filesPreviewComponentService.hasPreviousFile$()"
        color="secondary"
        iconClass="arrow-left"
      />
      <dougs-icon-button
        (click)="filesPreviewComponentService.selectNextFile()"
        [disabled]="!filesPreviewComponentService.hasNextFile$()"
        color="secondary"
        iconClass="arrow-right"
      />
    </div>
  </div>
  <div
    class="files-preview__content"
    [ngClass]="{
      'overflow-auto': filesPreviewComponentService.isCurrentFilePreviewImage$(),
      'no-header': noFilenameHeader
    }"
  >
    <img
      *ngIf="filesPreviewComponentService.isCurrentFilePreviewImage$()"
      [src]="legacyApiServerUrl + filesPreviewComponentService.currentFilePreview$()?.fileUrl"
      [alt]="filesPreviewComponentService.currentFilePreview$()?.fileName"
    />
    <iframe
      *ngIf="filesPreviewComponentService.isCurrentFilePreviewPdf$()"
      [src]="legacyApiServerUrl + filesPreviewComponentService.currentFilePreview$()?.fileUrl | trustRessourceUrl"
    ></iframe>
  </div>
</div>
