import { animate, AnimationTriggerMetadata, keyframes, style, transition, trigger } from '@angular/animations';

const ANIMATION_SPEED = '300ms ease';

export const stepperAnimations: AnimationTriggerMetadata[] = [
  trigger('slideVertical', [
    transition(':enter', [
      style({ height: 0, opacity: 0, overflow: 'hidden' }),
      animate(ANIMATION_SPEED, style({ height: '*', opacity: 1 })),
    ]),
    transition(':leave', [
      style({ height: '*', opacity: 1, overflow: 'hidden' }),
      animate(ANIMATION_SPEED, style({ height: 0, opacity: 0 })),
    ]),
  ]),
  trigger('slideHorizontal', [
    transition(':enter', [
      animate(
        ANIMATION_SPEED,
        keyframes([
          style({ transform: 'translateX(-100%)', opacity: 0, offset: 0 }),
          style({ transform: 'translateX(-50%)', opacity: 0, offset: 0.5 }),
          style({ transform: 'translateX(0)', opacity: 1, offset: 1.0 }),
        ]),
      ),
    ]),
    transition(':leave', [
      animate(
        ANIMATION_SPEED,
        keyframes([
          style({ transform: 'translateX(0)', opacity: 1, offset: 0 }),
          style({ transform: 'translateX(-50%)', opacity: 0, offset: 0.5 }),
          style({ transform: 'translateX(-100%)', offset: 1.0 }),
        ]),
      ),
    ]),
  ]),
  trigger('fade', [
    transition(':enter', [style({ opacity: 0 }), animate(ANIMATION_SPEED, style({ opacity: 1 }))]),
    transition(':leave', [style({ opacity: 1 }), animate(ANIMATION_SPEED, style({ opacity: 0 }))]),
  ]),
];
