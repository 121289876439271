import { Highlightable } from '@angular/cdk/a11y';
import { ValueType } from './value.type';

export class Option implements Highlightable {
  constructor(
    content: string,
    value: ValueType,
    label: string | undefined,
    disabled: boolean,
    isGroupTitle: boolean,
    tooltip: string | undefined,
  ) {
    this.content = content;
    this.label = label ?? content;
    this.value = value;
    this.disabled = disabled;
    this.isGroupTitle = isGroupTitle;
    this.tooltip = tooltip ?? '';
  }

  content: string;
  label: string;
  value: ValueType;
  active = false;
  selected = false;
  disabled = false;
  isGroupTitle = false;
  tooltip: string;

  setActiveStyles() {
    this.active = true;
  }

  setInactiveStyles() {
    this.active = false;
  }

  getLabel(): string {
    return this.label;
  }
}
