import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Attachment } from '@dougs/core/files';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { LoaderComponent } from '../../loader';
import { miniScaleInAnimation, slideInFromSideAnimation, slideOutAnimation } from '../animations/animations.const';
import { VendorInvoicePillComponentService } from './vendor-invoice-pill.component.service';

@Component({
  selector: 'dougs-vendor-invoice-pill',
  templateUrl: './vendor-invoice-pill.component.html',
  styleUrls: ['../scss/file-pill.scss'],
  standalone: true,
  imports: [CommonModule, LoaderComponent, RouterLink],
  providers: [VendorInvoicePillComponentService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideOutAnimation, miniScaleInAnimation, slideInFromSideAnimation],
})
export class VendorInvoicePillComponent {
  private _vendorInvoice!: VendorInvoice;

  @Input()
  set vendorInvoice(vendorInvoice: VendorInvoice) {
    this._vendorInvoice = vendorInvoice;
    this.vendorInvoiceAttachment = {
      name: vendorInvoice.fileName ?? vendorInvoice?.file?.name,
      url: vendorInvoice.filePath ?? vendorInvoice?.file?.url,
      progress: vendorInvoice?.progress,
      hasBeenUploadedNow: !!vendorInvoice?.hasBeenUploadedNow,
    } as Attachment;
  }

  get vendorInvoice(): VendorInvoice {
    return this._vendorInvoice;
  }

  @Input() canDelete = true;
  @Input() disableRouting = false;
  @Input() enableAnimation = false;

  @Output() deleteVendorInvoice: EventEmitter<VendorInvoice> = new EventEmitter<VendorInvoice>();

  public vendorInvoiceAttachment?: Attachment;

  constructor(private readonly vendorInvoicePillComponentService: VendorInvoicePillComponentService) {}

  async confirmDeleteFile(e: Event): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    if (this.vendorInvoiceAttachment) {
      const isDetached: boolean = await this.vendorInvoicePillComponentService.confirmDeleteFile(
        this.vendorInvoiceAttachment,
      );
      if (isDetached) {
        this.deleteVendorInvoice.emit(this.vendorInvoice);
      }
    }
  }
}
