import { monthsInYear } from "./constants.mjs";

/**
 * @name monthsToYears
 * @category Conversion Helpers
 * @summary Convert number of months to years.
 *
 * @description
 * Convert a number of months to a full number of years.
 *
 * @param months - The number of months to be converted
 *
 * @returns The number of months converted in years
 *
 * @example
 * // Convert 36 months to years:
 * const result = monthsToYears(36)
 * //=> 3
 *
 * // It uses floor rounding:
 * const result = monthsToYears(40)
 * //=> 3
 */
export function monthsToYears(months) {
  const years = months / monthsInYear;
  return Math.trunc(years);
}

// Fallback for modularized imports:
export default monthsToYears;