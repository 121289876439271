import { Highlightable } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'dougs-select-option',
  template: '<ng-content></ng-content>',
  styleUrls: ['select-option.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOptionComponent implements Highlightable {
  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly element: ElementRef,
  ) {}

  @Input() disabled = false;

  @HostBinding('class.selected')
  isSelected = false;

  @HostBinding('class.active')
  active = false;

  @HostBinding('class.group-title')
  @Input()
  isGroupTitle = false;

  @Input() value!: any;
  @Input() label?: string;
  @Input() tooltip?: string;

  markForCheck(): void {
    this.cdr.markForCheck();
  }

  getContent(): string {
    return (this.element.nativeElement.innerHTML || '').trim();
  }

  setActiveStyles(): void {
    this.active = true;
    this.markForCheck();
  }

  setInactiveStyles(): void {
    this.active = false;
    this.markForCheck();
  }
}
