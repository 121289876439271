import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dougsDisableUserSelect]',
  standalone: true,
})
export class DisableUserSelectDirective {
  constructor(
    private readonly host: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  @HostListener('window:keydown.shift')
  onDisableUserSelect(): void {
    this.renderer.addClass(this.host.nativeElement, 'disable-user-select');
  }

  @HostListener('window:keyup.shift')
  onEnableUserSelect(): void {
    this.renderer.removeClass(this.host.nativeElement, 'disable-user-select');
  }
}
