import { Platform } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { APPLE_STORE_URL, GOOGLE_STORE_URL, ResponsiveService } from '@dougs/core/responsive';
import { ButtonComponent } from '../button';
import { LogoComponent } from '../logo';
import { PageType } from './page.type';

@Component({
  selector: 'dougs-mobile-blank-slate',
  standalone: true,
  imports: [CommonModule, LogoComponent, ButtonComponent],
  templateUrl: './mobile-blank-slate.component.html',
  styleUrls: ['./mobile-blank-slate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileBlankSlateComponent implements OnInit {
  private _page: PageType = 'investment';
  imageBlankSlate = '';
  pageName = '';
  title = '';
  urlStore = '';
  shouldShowBigHeader = true;

  constructor(
    private readonly platform: Platform,
    private readonly responsiveService: ResponsiveService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  changeImageOnBreakpoint$: Observable<void> = combineLatest([
    this.responsiveService.isMobile,
    this.responsiveService.isSmallScreen,
  ]).pipe(
    map(([isMobile, isSmallScreen]) => {
      if (isSmallScreen) {
        this.imageBlankSlate = this.getImageFromPage(this.page, 's');
      } else if (isMobile) {
        this.imageBlankSlate = this.getImageFromPage(this.page, 'm');
      }
      this.cdr.markForCheck();
    }),
  );

  @Input()
  set page(page: PageType) {
    if (page) {
      this._page = page;
      this.imageBlankSlate = this.getImageFromPage(page);
      this.pageName = this.getPageName(page);
      this.title = this.getTitle(page);
      this.shouldShowBigHeader = page === 'performance' || page === 'accounting';
    }
  }

  get page(): PageType {
    return this._page;
  }

  @Input() firstName!: string;

  ngOnInit(): void {
    this.urlStore = this.getUrlStore();
  }

  getPageName(page: PageType): string {
    switch (page) {
      case 'vendor-invoice':
        return "Factures d'achat";
      case 'investment':
        return 'Immobilisations';
      case 'declaration':
        return 'Déclaration';
      case 'accounting-survey':
        return 'Questions bilan';
      case 'invoicing':
        return 'Facturation';
      case 'settings':
        return 'Paramètres';
    }

    return '';
  }

  getTitle(page: PageType): string {
    switch (page) {
      case 'accounting':
        return "Rendez-vous sur l'app&nbsp;!";
      case 'performance':
        return "Page réservée à l'appli&nbsp;!";
      default:
        return 'Ce module n’est pas accessible sur un écran de cette taille';
    }
  }

  getImageFromPage(page: PageType, screenSize?: 's' | 'm'): string {
    switch (page) {
      case 'vendor-invoice':
        return 'images/mobile-blank-slate/vendor-invoice.svg';
      case 'investment':
        return 'images/mobile-blank-slate/investment.svg';
      case 'declaration':
        return 'images/mobile-blank-slate/declaration.svg';
      case 'accounting-survey':
        return 'images/mobile-blank-slate/accounting-survey.svg';
      case 'invoicing':
        return 'images/mobile-blank-slate/invoicing.svg';
      case 'settings':
        return 'images/mobile-blank-slate/settings.svg';
      case 'performance':
        return `images/mobile-blank-slate/performance-${screenSize}.svg`;
      case 'accounting':
        return `images/mobile-blank-slate/accounting-${screenSize}.svg`;
    }

    return '';
  }

  getUrlStore(): string {
    if (this.platform.IOS) {
      return APPLE_STORE_URL;
    }

    if (this.platform.ANDROID) {
      return GOOGLE_STORE_URL;
    }

    return '';
  }
}
