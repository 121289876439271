import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { AvatarComponent } from '../avatar';
import { PanelInfoComponent } from '../panel-info';
import { TooltipDirective } from '../tooltip';

type AvatarMessageSize = 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';
type AvatarMessageColor = 'color-primary-700';

@Component({
  selector: 'dougs-avatar-message',
  templateUrl: './avatar-message.component.html',
  styleUrls: ['./avatar-message.component.scss'],
  standalone: true,
  imports: [CommonModule, AvatarComponent, PanelInfoComponent, TooltipDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarMessageComponent {
  private _fullName!: string;
  private _avatarUrl!: string;
  private _size: AvatarMessageSize = 'small';
  private _fullNameTextSize?: AvatarMessageSize;
  private _fullNameTextColor?: AvatarMessageColor;

  public fullNameTextClass = '';

  @Input() @HostBinding('class') align: 'top' | 'bottom' = 'bottom';
  @Input() disableFullName = false;
  @Input() fullWidth = false;

  @Input()
  set fullName(fullName: string | undefined) {
    this._fullName = fullName || 'Romain Heinrich';
  }

  get fullName() {
    return this._fullName;
  }

  @Input()
  set avatarUrl(avatarUrl: string | undefined) {
    this._avatarUrl =
      avatarUrl || 'https://images.prismic.io/test-dougs/58a190f4-a09e-494d-96a1-ea4c885bd1ef_romain5.jpeg';
  }

  get avatarUrl() {
    return this._avatarUrl;
  }

  @Input()
  set size(size: AvatarMessageSize) {
    this._size = size || 'small';

    this.refreshFullNameTextClass();
  }

  get size(): AvatarMessageSize {
    return this._size;
  }

  @Input()
  set fullNameTextSize(fullNameTextSize: AvatarMessageSize | undefined) {
    this._fullNameTextSize = fullNameTextSize;

    this.refreshFullNameTextClass();
  }

  get fullNameTextSize(): AvatarMessageSize | undefined {
    return this._fullNameTextSize;
  }

  @Input()
  set fullNameTextColor(fullNameTextColor: AvatarMessageColor | undefined) {
    this._fullNameTextColor = fullNameTextColor;

    this.refreshFullNameTextClass();
  }

  get fullNameTextColor(): AvatarMessageColor | undefined {
    return this._fullNameTextColor;
  }

  refreshFullNameTextClass(): void {
    this.fullNameTextClass = [this.fullNameTextSize || this.size, this.fullNameTextColor || ''].join(' ');
  }
}
