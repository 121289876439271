import { TransactionProviderDigest, TransactionProviderDigestStatusCode } from '../dto';

const getDefaults = (): TransactionProviderDigest => ({
  result: {
    createdCount: 0,
    errorCount: 0,
    updatedCount: 0,
  },
  status: {
    code: TransactionProviderDigestStatusCode.DONE,
    transactionCount: 0,
  },
});

export const getTransactionProviderDigestDigestMock = (
  p?: Partial<TransactionProviderDigest>,
): TransactionProviderDigest => ({
  ...getDefaults(),
  ...p,
});
