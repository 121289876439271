import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getDatePillMaxWidth',
  standalone: true,
})
export class GetDatePillMaxWidthPipe implements PipeTransform {
  transform(listLength: number): string {
    if (!listLength || listLength === 1) {
      return 'fit-content';
    } else if (listLength === 2) {
      return '50%';
    }
    return '30%';
  }
}
