import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FlashMessagesService } from './flash-messages.service';

@Component({
  selector: 'dougs-flash-messages',
  templateUrl: './flash-messages.component.html',
  styleUrls: ['./flash-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  animations: [
    trigger('animFlash', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-16px)' }),
        animate('300ms ease-in-out', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [animate('300ms ease-in-out', style({ opacity: 0, transform: 'translateY(-16px)' }))]),
    ]),
  ],
  imports: [CommonModule],
})
export class FlashMessagesComponent {
  constructor(public _flashMessagesService: FlashMessagesService) {}

  trackByMessage(index: number) {
    return index;
  }
}
