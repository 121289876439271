import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  FormFieldComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '../../components';

@Component({
  selector: 'dougs-select-modal',
  templateUrl: './select-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalContentDirective,
    ModalCloseDirective,
    ModalFooterDirective,
    ButtonComponent,
    ReactiveFormsModule,
    FormFieldComponent,
    ControlFormFieldDirective,
  ],
})
export class SelectModalComponent implements OnInit, OnDestroy {
  itemSearch: Record<string, string>[] = [];
  searchControl = new UntypedFormControl('');

  private searchSubscription!: Subscription;

  constructor(
    @Inject(MODAL_DATA)
    public data: { title: string; items: Record<string, string>[]; hideSearch: boolean },
    private readonly modalRef: ModalRef<unknown, { title: string; items: Record<string, string>[] }>,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.itemSearch = this.data.items;

    this.searchSubscription = this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((search: string) => {
        this.itemSearch = this.data.items.filter(
          (item: Record<string, string>) =>
            item.label?.toLowerCase().includes(search.trim().toLowerCase()) ||
            item.description?.toLowerCase().includes(search.trim().toLowerCase()),
        );
        this.cdr.markForCheck();
      });
  }

  selectItem(item: Record<string, string>): void {
    this.modalRef.close(item.value);
  }

  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }
}
