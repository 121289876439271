import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wrapUrlsInHtml',
  standalone: true,
})
export class WrapUrlsInHtmlPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    const httpRegExp =
      /(href=["'])?(?:https?:\/\/)?([:\w.+&\-_~[\]{}]*?\.(com|fr|net|org|edu|gov|mil|io|co|uk|so)(?=\/|\?)?[%:/\w.#?=+&\-_!"'~[\]{}]+)/gim;
    return value.replace(httpRegExp, (wholeMatch, groupHref, groupLink) => {
      if (groupHref && groupHref.length > 0) {
        return wholeMatch; // Do not replace if the link is already wrapped in an html tag
      }
      return `<a rel="noopener" target="_blank" href="http://${groupLink}">${groupLink}</a>`;
    });
  }
}
