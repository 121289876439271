import { Pipe, PipeTransform } from '@angular/core';
import { DougsDatePipe } from './date.pipe';

@Pipe({
  name: 'monthPrefix',
  standalone: true,
})
export class MonthPrefixPipe implements PipeTransform {
  constructor(private readonly dougsDatePipe: DougsDatePipe) {}

  transform(value: string | undefined): string {
    if (value) {
      const month = this.dougsDatePipe.transform(value, 'MMMM');
      return `${['avril', 'août', 'octobre'].includes(month || '') ? "d'" : 'de '}${month}`;
    }

    return '';
  }
}
