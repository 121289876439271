import { computed, EventEmitter, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SourceDocument, SourceDocumentAttachment, SourceDocumentType } from '@dougs/core/files';
import { ModalService } from '../../modal/modal.service';

@Injectable()
export class SourceDocumentPillComponentService {
  private readonly sourceDocumentAttachment: WritableSignal<SourceDocumentAttachment | null> = signal(null);
  sourceDocumentAttachment$: Signal<SourceDocumentAttachment | null> = this.sourceDocumentAttachment.asReadonly();

  private readonly canDelete: WritableSignal<boolean> = signal(true);
  canDelete$: Signal<boolean> = this.canDelete.asReadonly();

  private readonly disableRouting: WritableSignal<boolean> = signal(false);
  disableRouting$: Signal<boolean> = this.disableRouting.asReadonly();

  private readonly enableAnimation: WritableSignal<boolean> = signal(false);
  enableAnimation$: Signal<boolean> = this.enableAnimation.asReadonly();

  public deleteSourceDocumentAttachmentEmitter: EventEmitter<SourceDocumentAttachment> =
    new EventEmitter<SourceDocumentAttachment>();

  sourceDocument$: Signal<SourceDocument | null> = computed(
    () => this.sourceDocumentAttachment$()?.sourceDocument ?? null,
  );

  useRouterLink$: Signal<boolean> = computed(() => !this.disableRouting$() && this.isInvoice$());

  animationDisabled$: Signal<boolean> = computed(
    () => !this.sourceDocument$()?.hasBeenUploadedNow || !this.enableAnimation$(),
  );

  isLoading$: Signal<boolean> = computed(() => this.sourceDocument$()?.type === SourceDocumentType.UNKNOWN);

  showDeleteIcon$: Signal<boolean> = computed(() => !this.sourceDocument$()?.progress && this.canDelete$());

  isVendorInvoice$: Signal<boolean> = computed(() => this.isVendorInvoice(this.sourceDocumentAttachment$()));
  isSalesInvoice$: Signal<boolean> = computed(() => this.isSalesInvoice(this.sourceDocumentAttachment$()));
  isInvoice$: Signal<boolean> = computed(() => this.isSalesInvoice$() || this.isVendorInvoice$());

  queryParamsKey$: Signal<string> = computed(() =>
    this.isVendorInvoice$() ? 'vendorInvoiceId' : this.isSalesInvoice$() ? 'salesInvoiceId' : '',
  );
  externalId$: Signal<string> = computed(() => this.sourceDocumentAttachment$()?.sourceDocument?.externalId ?? '');
  queryParams$: Signal<{ [key: string]: string }> = computed(() => ({ [this.queryParamsKey$()]: this.externalId$() }));

  pillIcon$: Signal<string> = computed(() =>
    this.isInvoice$() ? (this.isVendorInvoice$() ? 'fa-receipt' : 'fa-file-invoice') : 'fa-paperclip',
  );

  url$: Signal<string | null> = computed(() => this.sourceDocumentAttachment$()?.sourceDocument?.file?.url ?? null);

  constructor(private readonly modalService: ModalService) {}

  setSourceDocumentAttachment(sourceDocumentAttachment: SourceDocumentAttachment): void {
    this.sourceDocumentAttachment.set(sourceDocumentAttachment);
  }

  setCanDelete(canDelete: boolean): void {
    this.canDelete.set(canDelete);
  }

  setDisableRouting(disableRouting: boolean): void {
    this.disableRouting.set(disableRouting);
  }

  setEnableAnimation(enableAnimation: boolean): void {
    this.enableAnimation.set(enableAnimation);
  }

  private isVendorInvoice(sourceDocumentAttachment: SourceDocumentAttachment | null): boolean {
    if (!sourceDocumentAttachment) {
      return false;
    }

    return (
      sourceDocumentAttachment?.sourceDocument?.type === SourceDocumentType.VENDOR_INVOICE &&
      !!sourceDocumentAttachment?.sourceDocument?.externalId
    );
  }

  private isSalesInvoice(sourceDocumentAttachment: SourceDocumentAttachment | null): boolean {
    if (!sourceDocumentAttachment) {
      return false;
    }

    return (
      sourceDocumentAttachment?.sourceDocument?.type === SourceDocumentType.SALES_INVOICE &&
      !!sourceDocumentAttachment?.sourceDocument?.externalId
    );
  }

  async confirmDelete(e: Event): Promise<void> {
    e.preventDefault();
    e.stopPropagation();
    const sourceDocumentAttachment: SourceDocumentAttachment | null = this.sourceDocumentAttachment$();

    if (sourceDocumentAttachment) {
      const isConfirmed: boolean = await this.openConfirmationModal();
      if (isConfirmed) {
        this.deleteSourceDocumentAttachmentEmitter.emit(sourceDocumentAttachment);
      }
    }
  }

  private async openConfirmationModal(): Promise<boolean> {
    const { ConfirmationModalComponent } = await import('../../../modals');

    const { data } = await lastValueFrom(
      this.modalService.open(ConfirmationModalComponent, {
        data: {
          title: this.getConfirmationModalTitle(),
          body: this.getConfirmationModalBody(),
          yesText: this.getConfirmationModalYesText(),
          noText: 'Annuler',
        },
      }).afterClosed$,
    );
    return !!data;
  }

  private getConfirmationModalTitle(): string {
    return this.isInvoice$() ? 'Détacher la facture' : 'Supprimer le fichier';
  }

  private getConfirmationModalBody(): string {
    return this.isInvoice$()
      ? `Êtes-vous sûr de vouloir détacher la facture <b>&laquo;&nbsp;${this.sourceDocument$()?.file?.name}&nbsp;&raquo;</b> &nbsp; de cette opération ? Votre facture sera toujours accessible depuis votre module Factures ${this.isSalesInvoice$() ? 'de vente' : "d'achat"}.`
      : `Êtes-vous sûr de vouloir supprimer le fichier <b>&laquo;&nbsp;${this.sourceDocument$()?.file?.name}&nbsp;&raquo;</b> &nbsp;? Cette action est définitive.`;
  }

  private getConfirmationModalYesText(): string {
    return this.isInvoice$() ? 'Oui, détacher la facture' : 'Oui, supprimer le fichier';
  }
}
