import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit, Type } from '@angular/core';
import { ModalRef } from './modal-ref';
import { MODAL_STYLE } from './modal.service';

@Component({
  selector: 'dougs-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnInit {
  component!: Type<unknown>;

  @HostBinding('style') get styles(): Partial<CSSStyleDeclaration> {
    if (this.modalStyle?.fullScreen) {
      return {
        maxWidth: 'inherit',
        borderRadius: '0',
      };
    }
    return {};
  }

  constructor(
    private readonly ref: ModalRef,
    @Inject(MODAL_STYLE) public readonly modalStyle: { fullScreen?: boolean },
  ) {}

  close() {
    this.ref.close();
  }

  ngOnInit() {
    this.component = this.ref.content as Type<unknown>;
  }
}
