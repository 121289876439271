import { AbstractControl, ValidatorFn } from '@angular/forms';
import parsePhoneNumber from 'libphonenumber-js';

export const isPhone = (checkIfEmpty = true): ValidatorFn => {
  return (control: AbstractControl) => {
    if (!checkIfEmpty && !control.value) {
      return null;
    }
    const isValid =
      control.value && ['0', '+'].includes(control.value[0]) && parsePhoneNumber(control.value, 'FR')?.isValid();

    if (isValid) {
      return null;
    }
    return { isPhone: true };
  };
};
