import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DrawerAnimationService {
  closeDrawer = new Subject<void>();
  closeDrawer$ = this.closeDrawer.asObservable();

  animationEnd = new Subject<void>();
  animationEnd$ = this.animationEnd.asObservable();
}
