import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'dougs-blank-state',
  templateUrl: './blank-state.component.html',
  styleUrls: ['./blank-state.component.scss'],
  imports: [CommonModule, LottieComponent],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlankStateComponent {
  image = 'images/emoji/shrug.png';
  animation?: AnimationOptions;

  @Input()
  set illustration(
    illustration:
      | 'empty-search'
      | 'empty-list'
      | 'loan-blank-slate'
      | 'vip-blank-slate'
      | 'vehicle-blank-slate'
      | 'synchronized-account-blank-slate'
      | 'invoice-blank-slate'
      | 'sales-channel-blank-slate'
      | 'etablissement-secondaire-blank-slate'
      | 'palmtree'
      | 'loader-douggie-running'
      | 'party-popper'
      | 'people-blank-slate'
      | 'empty-search-blank-slate',
  ) {
    switch (illustration) {
      case 'loan-blank-slate':
      case 'vip-blank-slate':
      case 'invoice-blank-slate':
      case 'vehicle-blank-slate':
      case 'synchronized-account-blank-slate':
      case 'sales-channel-blank-slate':
      case 'etablissement-secondaire-blank-slate':
      case 'people-blank-slate':
      case 'empty-search-blank-slate':
        this.image = `images/illustrations/${illustration}.png`;
        break;
      case 'empty-list':
      case 'empty-search':
        this.image = 'images/emoji/shrug.png';
        break;
      case 'party-popper':
        this.image = 'images/emoji/party-popper.png';
        break;
      case 'loader-douggie-running':
        this.animation = { path: `animations/${illustration}.json` };
        break;
      default:
        this.image = `images/emoji/${illustration}.png`;
    }
  }

  @Input() autoWidth = false;
  @Input() maxWidth!: number;
}
