import { HttpClient, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '../../dto';

export abstract class AbstractFileHttpService {
  protected readonly http: HttpClient = inject(HttpClient);

  protected sortedFileModelNameArray: string[] = [];

  uploadFile(file: File, options?: { type?: string }, ...modelIds: (string | number)[]): Observable<Attachment> {
    if (
      !this.sortedFileModelNameArray?.length ||
      !modelIds?.length ||
      modelIds?.length !== this.sortedFileModelNameArray.length
    ) {
      throw new Error('Model name array & model ids array not compatible');
    }
    const url: string = this.buildFileUrl(modelIds, file);
    const formData: FormData = new FormData();
    let params: HttpParams = new HttpParams();
    if (options) {
      params = this.buildHttpParams(params, options);
    }
    formData.append('file', file);

    return this.http.post<Attachment>(url, formData, { params });
  }

  private buildFileUrl(modelIds: (string | number)[], file: File): string {
    return `${AppConfig.settings.legacyApiServerUrl}/${this.sortedFileModelNameArray
      .map((modelName: string, i: number) => `${modelName}/${modelIds[i]}`)
      .join('/')}/attachments?filename=${file.name}`;
  }

  private buildHttpParams(params: HttpParams, options: { type?: string }): HttpParams {
    if (options.type) {
      params = params.append('type', options.type);
    }
    return params;
  }
}
