<nav class="menu">
  <ul>
    <ng-container *ngFor="let menuItem of menuItems; trackBy: 'id' | trackBy">
      <li
        *ngIf="menuItem.show$ | async"
        (mouseenter)="activateMenuHover(menuItemLink)"
        (mouseleave)="desactivateMenuHover()"
      >
        <a
          [id]="menuItem.id"
          #menuItemLink
          (click)="activateMenu($event, menuItem)"
          [ngClass]="{ active: activeItemId === menuItem.id, 'is-admin': menuItem?.isAdmin }"
          class="pt-24 pb-8"
          href="#"
        >
          {{ menuItem.label }}
        </a>
      </li>
    </ng-container>
    <div [ngStyle]="{ 'width.px': floatingBorderWidth, 'left.px': floatingBorderLeft }" class="floating-border"></div>
    <div
      [ngStyle]="{ 'width.px': floatingBorderHoverWidth, 'left.px': floatingBorderHoverLeft }"
      class="floating-border-hover"
    ></div>
  </ul>
</nav>
