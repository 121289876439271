<ng-template cdk-portal>
  <div
    [style.max-height]="maxHeight ? maxHeight + 'px' : 'none'"
    [style.overflow]="maxHeight ? 'auto' : 'visible'"
    class="dropdown-options-container"
    [ngClass]="{
      'has-arrow': displayArrow,
      'on-top': onTop,
      'is-select': isSelect,
      'width-100': widthToRefElement,
      'is-datepicker': isDatepicker
    }"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
