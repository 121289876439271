<div *ngIf="!isMemoOpened">
  <button type="button" [ngClass]="{ light: light }" class="memo-button" (click)="openMemo($event)">
    + Ajouter un mémo
  </button>
</div>
<div *ngIf="isMemoOpened" class="memo-input">
  <span
    #contentEditable
    (keydown.enter)="saveMemo($event)"
    class="memo-input__content-editable"
    contenteditable="true"
    ngDefaultControl
    (paste)="onPaste($event)"
    (blur)="closeMemoIfEmpty()"
  ></span>
</div>
