import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StepperIconComponent } from './stepper-icon/stepper-icon.component';
import { StepperStatusClassPipe } from './stepper-status-class.pipe';

@Component({
  selector: 'dougs-stepper',
  standalone: true,
  imports: [CommonModule, StepperIconComponent, StepperStatusClassPipe],
  templateUrl: './stepper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent {
  @Input() direction: 'vertical' | 'horizontal' = 'vertical';
  @Input() color: 'primary' | 'green' = 'primary';
  @Input() isTiny = false;
}
