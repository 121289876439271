import { Attachment } from '@dougs/core/files';
import { JobDto } from '@dougs/core/job-dto';

export interface TransactionProvider {
  id: number;
  accountId: number;
  attachments: Attachment[];
  companyId: number;
  connectionId: number;
  title: string;
  subType?: string;
  connectorId: string;
  connector: {
    id: string;
    type: string;
  };
  enabled: boolean;
  transactionsDigestedAt?: Date;
  transactionsDigest?: TransactionProviderDigest;
  minDate: Date | string;
  maxDate: Date | string;
  data?: TransactionProviderData;
  externalId: number;
  remoteSynchronizedAt?: Date;
  job?: JobDto;
  updatedAt?: Date;
}

export interface TransactionProviderDigest {
  result: TransactionProviderDigestResult;
  status: TransactionProviderDigestStatus;
}

export interface TransactionProviderDigestResult {
  createdCount: number;
  errorCount: number;
  updatedCount: number;
}

export interface TransactionProviderDigestStatus {
  code?: TransactionProviderDigestStatusCode;
  transactionCount?: number;
}

export enum TransactionProviderDigestStatusCode {
  CREATING_OPERATIONS = 'creatingOperations',
  FETCHING_TRANSACTIONS = 'fetchingTransactions',
  DONE = 'done',
  ERROR = 'error',
}

export interface TransactionProviderData {
  importerName: string;
  iban: string;
  providerToMergeId?: number;
}

export interface TransactionProviderPost {
  attachments: Attachment[];
  connectionId: number;
  externalId: string;
  minDate: string | null;
  maxDate: string | null;
}

export interface TransactionProviderMergePost {
  connectionId: number;
  externalId: string;
}
