<ng-container *ngIf="!disableRouting; else openFile">
  <a
    [ngClass]="{ 'disable-link': !vendorInvoice?.id }"
    [routerLink]="[]"
    [queryParams]="{ vendorInvoiceId: vendorInvoice.id }"
  >
    <ng-container [ngTemplateOutlet]="filePillContent"></ng-container>
  </a>
</ng-container>
<ng-template #openFile>
  <a
    [ngClass]="{ 'disable-link': !vendorInvoiceAttachment?.url }"
    [href]="vendorInvoiceAttachment?.url"
    target="_blank"
  >
    <ng-container [ngTemplateOutlet]="filePillContent"></ng-container>
  </a>
</ng-template>

<ng-template #filePillContent>
  <div class="file-pill">
    <div
      class="file-pill__content"
      [ngClass]="{
        'file-pill__content--no-delete': !canDelete
      }"
    >
      <i
        @scaleIn
        [@.disabled]="!vendorInvoiceAttachment.hasBeenUploadedNow || !enableAnimation"
        *ngIf="!vendorInvoiceAttachment.progress"
        class="self-center fal fa-receipt"
      ></i>
      <dougs-loader
        *ngIf="vendorInvoiceAttachment.progress"
        @slideOut
        class="self-center"
        appearance="spin"
      ></dougs-loader>
      <span class="filename">{{ vendorInvoiceAttachment.name }}</span>
      <i
        @slideInFromSide
        [@.disabled]="!vendorInvoiceAttachment.hasBeenUploadedNow || !enableAnimation"
        *ngIf="!vendorInvoiceAttachment?.progress && canDelete"
        class="fal fa-times delete-file"
        (click)="confirmDeleteFile($event)"
      ></i>
    </div>
  </div>
</ng-template>
