import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dougs-memo',
  templateUrl: './memo.component.html',
  styleUrls: ['./memo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MemoComponent),
    },
  ],
})
export class MemoComponent implements ControlValueAccessor {
  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly window: Window,
  ) {}

  private _contentEditable?: ElementRef;

  private _value!: string;

  @Input() light = false;

  @ViewChild('contentEditable', { static: false })
  set contentEditableChild(content: ElementRef) {
    if (content) {
      this._contentEditable = content;
      this.setInnerTextValue();
    }
  }

  get contentEditable(): ElementRef | undefined {
    return this._contentEditable;
  }

  get value(): string {
    return this._value;
  }

  isMemoOpened = false;

  onChange!: (value: string) => void;
  onTouched!: (value: string) => void;

  openMemo(e: Event): void {
    e.stopPropagation();
    this.isMemoOpened = true;
    setTimeout(() => {
      this.contentEditable?.nativeElement.focus();
    });

    this.cdr.markForCheck();
  }

  saveMemo(e: KeyboardEvent): void {
    e.preventDefault();
    this._contentEditable?.nativeElement.blur();
  }

  closeMemoIfEmpty(): void {
    if (!this._contentEditable?.nativeElement.innerText || this._contentEditable?.nativeElement.innerText === '') {
      this.isMemoOpened = false;
    }

    this._value = this._contentEditable?.nativeElement.innerText;

    this.onChange(this._value.trim());

    this.cdr.markForCheck();
  }

  writeValue(value: string): void {
    this._value = value;

    this.setInnerTextValue();

    if (value) {
      this.isMemoOpened = true;
    }

    this.cdr.markForCheck();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (value: string) => void): void {
    this.onTouched = fn;
  }

  onPaste(clipboardEvent: ClipboardEvent): void {
    clipboardEvent.preventDefault();
    const clipboardData: DataTransfer | null = clipboardEvent?.clipboardData;
    const pastedText = clipboardData?.getData('text');
    if (pastedText) {
      this.window.document.execCommand('insertText', false, pastedText);
    }
  }

  setInnerTextValue(): void {
    if (this._contentEditable?.nativeElement) {
      this._contentEditable.nativeElement.innerText = this.value;
    }
  }
}
