import { Injectable } from '@angular/core';
import {
  eachDayOfInterval,
  endOfMonth,
  getDate,
  getDay,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isSameMonth,
  isSameYear,
  isToday,
  startOfDay,
  startOfMonth,
  subDays,
} from 'date-fns';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatepickerCommonService } from '../datepicker-common.service';

export interface Day {
  date: Date;
  day: number;
  month: number;
  year: number;
  inThisMonth: boolean;
  isToday: boolean;
  isSelected: boolean;
  isSelectable: boolean;
}

@Injectable()
export class DatepickerComponentService extends DatepickerCommonService {
  private readonly _selectedDateSubject: BehaviorSubject<Date | null> = new BehaviorSubject<Date | null>(null);

  days$: Observable<Day[]> = combineLatest([
    this._currentDateSubject,
    this._viewSubject,
    this._selectedDateSubject,
  ]).pipe(
    map(([currentDate, view, selectedDate]) => {
      return view === 'days' ? this.initDays(currentDate, selectedDate) : [];
    }),
  );
  selectedDate$: Observable<Date | null> = this._selectedDateSubject.asObservable();

  onDateChange: Subject<Date> = new Subject<Date>();

  get selectedDate(): Date | null {
    return this._selectedDateSubject.value;
  }

  set selectedDate(selectedDate: Date) {
    this._selectedDateSubject.next(selectedDate);
  }

  setDate(date: Date): void {
    this._currentDateSubject.next(date);
    this._selectedDateSubject.next(date);
    this.onDateChange.next(date);
  }

  isMonthSelected(monthIndex: number): boolean {
    return this.selectedDate ? monthIndex === getMonth(this.selectedDate) : false;
  }

  isYearSelected(year: number): boolean {
    return this.selectedDate ? year === getYear(this.selectedDate) : false;
  }

  private initDays(currentDate: Date, selectedDate: Date | null): Day[] {
    const [start, end] = [startOfMonth(currentDate), endOfMonth(currentDate)];

    const days: Day[] = eachDayOfInterval({ start, end }).map((d: Date) => this.generateDay(d, selectedDate));

    const tmp: number = getDay(start) - 1;
    const prevDays: number = tmp < 0 ? 7 - 1 : tmp;
    for (let i = 1; i <= prevDays; i++) {
      days.unshift(this.generateDay(subDays(start, i), selectedDate, false));
    }

    return days;
  }

  private generateDay(date: Date, selectedDate: Date | null, inThisMonth = true): Day {
    return {
      date,
      day: getDate(date),
      month: getMonth(date),
      year: getYear(date),
      inThisMonth,
      isToday: isToday(date),
      isSelected:
        !!selectedDate &&
        isSameDay(date, selectedDate) &&
        isSameMonth(date, selectedDate) &&
        isSameYear(date, selectedDate),
      isSelectable: this.isDateSelectable(date),
    };
  }

  private isDateSelectable(date: Date): boolean {
    if (
      this.options.minDate &&
      !isEqual(startOfDay(date), startOfDay(this.options.minDate)) &&
      isBefore(date, this.options.minDate)
    ) {
      return false;
    }

    return !(this.options.maxDate && isAfter(date, this.options.maxDate));
  }
}
