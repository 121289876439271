<div class="loader" *ngIf="appearance === 'dots'" [ngClass]="{ 'loader--left': align === 'left' }">
  <div class="loader__ball">
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="loader__text" *ngIf="loadingTexts">{{ activeLoadingText }}</div>
</div>

<div *ngIf="appearance === 'spin'" class="loader-spin">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
