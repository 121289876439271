import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { addDays, addMonths, format, isValid, parse } from 'date-fns';
import { Subscription } from 'rxjs';
import { DatePill } from '../../pill';
import { DatepickerComponent } from './datepicker.component';

@Directive({
  selector: '[dougsDatepicker]',
  standalone: true,
})
export class DatepickerDirective implements OnInit, OnDestroy {
  control!: NgControl;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly injector: Injector,
  ) {}

  private onDateChangeSubscription!: Subscription;
  private onValueChangeSubscription!: Subscription;

  @Input() dougsDatepicker!: DatepickerComponent;
  @Input() dougsDatepickerDisabled = false;
  @Input() dateFormat = 'dd/MM/yyyy';
  @Input() useDefaultTaskPill = false;
  @Input() customDatePills?: DatePill[];
  @Output() chooseValue: EventEmitter<void> = new EventEmitter<void>();

  taskDatePills: DatePill[] = [
    { label: 'Dans 3 jours', date: addDays(new Date(), 3) },
    { label: 'Dans 7 jours', date: addDays(new Date(), 7) },
    { label: 'Dans 1 mois', date: addMonths(new Date(), 1) },
  ];

  @HostListener('click')
  onClick(): void {
    if (!this.dougsDatepickerDisabled) {
      this.dougsDatepicker.reference = this.elementRef;
      if (this.elementRef.nativeElement instanceof HTMLInputElement) {
        (this.elementRef.nativeElement as HTMLInputElement).select();
      }

      setTimeout(() => {
        this.putDateInDatepickerComponent(this.control.value);
        this.dougsDatepicker.dropdown.show();
      });
    }
  }

  ngOnInit(): void {
    this.control = this.injector.get(NgControl);

    this.onDateChangeSubscription = this.dougsDatepicker.onDateChange.subscribe((date: Date) => {
      this.control.control?.setValue(format(date, this.dateFormat));
      this.chooseValue.next();
    });

    setTimeout(() => {
      if (this.control.control) {
        this.putDateInDatepickerComponent(this.control.control.value);
        this.setDatePills();
        this.onValueChangeSubscription = this.control.control.valueChanges.subscribe((value: string) => {
          this.putDateInDatepickerComponent(value);
          this.dougsDatepicker.dropdown?.hide();
        });
      }
    });
  }

  putDateInDatepickerComponent(value: string): void {
    const formats: string[] = ['dd/MM/yy', 'dd/MM/yyyy', 'yyyy-MM-dd'];

    if (!value) {
      return;
    }

    for (const format of formats.values()) {
      const parseDate: Date = parse(value, format, new Date());
      if (isValid(parseDate)) {
        this.dougsDatepicker.date = parseDate;
        break;
      }
    }
  }

  private setDatePills(): void {
    if (this.useDefaultTaskPill) {
      this.dougsDatepicker.datePills = this.taskDatePills;
    } else if (this.customDatePills?.length && this.customDatePills.length <= 3) {
      this.dougsDatepicker.datePills = this.customDatePills;
    }
  }

  ngOnDestroy(): void {
    this.onDateChangeSubscription?.unsubscribe();
    this.onValueChangeSubscription?.unsubscribe();
  }
}
