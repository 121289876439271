import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Attachment } from '@dougs/core/files';
import { ModalService } from '../../modal/modal.service';

@Injectable()
export class FilePillComponentService {
  constructor(private readonly modalService: ModalService) {}

  async confirmDeleteFile(attachment: Attachment): Promise<boolean> {
    const { ConfirmationModalComponent } = await import('../../../modals');

    const { data } = await lastValueFrom(
      this.modalService.open(ConfirmationModalComponent, {
        data: {
          title: 'Supprimer le fichier',
          body: `Êtes-vous sûr de vouloir supprimer le fichier <b>&laquo;&nbsp;${attachment.name}&nbsp;&raquo;</b> &nbsp;? Cette action est définitive.`,
          yesText: 'Oui, supprimer le fichier',
          noText: 'Annuler',
        },
      }).afterClosed$,
    );
    return !!data;
  }
}
