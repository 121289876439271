import { RadioComponent } from './radio.component';

export class RadioChange {
  source: RadioComponent | null;
  value: unknown;

  constructor(source: RadioComponent, value: unknown) {
    this.source = source;
    this.value = value;
  }
}
