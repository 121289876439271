import { PostIt } from '../dto';

const getDefaults = (): PostIt => ({
  id: 1,
  title: 'title',
  content: 'content',
  lastUpdater: {
    lastName: 'lastName',
    firstName: 'firstName',
    avatarUrl: 'avatarUrl',
  },
  department: 'accounting',
  isArchived: false,
  createdAt: new Date('2023-01-01'),
  attachments: [],
  isGlobal: false,
});

export const getPostItMock = (p?: Partial<PostIt>): PostIt => ({
  ...getDefaults(),
  ...p,
});
