import { Injectable } from '@angular/core';
import { generateUuidV4 } from '@dougs/core/utils';
import { Attachment } from '../dto';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  removeFromFileList(files: File[], tempAttachments: Attachment[], attachment: Attachment): File[] {
    files.splice(
      tempAttachments.findIndex((tempAttachment: Attachment) => tempAttachment.id === attachment.id),
      1,
    );

    return files;
  }

  removeFromTempAttachment(tempAttachments: Attachment[], attachment: Attachment): Attachment[] {
    return tempAttachments.filter((tempAttachment: Attachment) => tempAttachment.id !== attachment.id);
  }

  getTempAttachmentsFromFiles(files: File[]): Attachment[] {
    return files.map((file: File) => ({
      id: generateUuidV4(),
      file: {
        url: URL.createObjectURL(file),
      },
      modelId: 0,
      companyId: 0,
      name: file.name,
    }));
  }
}
