<div
  class="radio-button-group"
  [ngClass]="{
    'is-admin': isAdmin,
    'is-vertical': vertical,
    'yes-no': appearance === 'yesNo',
    'yes-no-outline': appearance === 'yesNoOutline',
    'no-vertical-row-gap': appearance === 'no-vertical-row-gap',
    'no-wrap': noWrap
  }"
>
  <ng-content></ng-content>
</div>
