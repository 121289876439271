export interface Currency {
  flag: string;
  isoCode: CurrencyISOCode;
  label: string;
}

export enum CurrencyISOCode {
  EUR = 'EUR',
  USD = 'USD',
  CAD = 'CAD',
  GBP = 'GBP',
  CHF = 'CHF',
  CNY = 'CNY',
  JPY = 'JPY',
  HKD = 'HKD',
  AUD = 'AUD',
  MGA = 'MGA',
}
