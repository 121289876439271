import { Profile } from '../dto';

const getDefaults = (): Profile => ({
  id: 1,
  initials: '',
  firstName: '',
  lastName: '',
  alternateAvatarUrl: '',
  avatarUrl: '',
  fullName: '',
  email: '',
  identityProof: [],
  normalizedPhones: [],
  phone: '',
  workPhone: '',
});

export const getProfileMock = (p?: Partial<Profile>): Profile => ({
  ...getDefaults(),
  ...p,
});
