import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minInHour',
  standalone: true,
})
export class MinInHourPipe implements PipeTransform {
  transform(minutes: number): string {
    const hours: number = Math.floor(minutes / 60);
    const remainingMinutes = Math.floor(minutes % 60);

    return `${hours}h${remainingMinutes > 0 ? String(remainingMinutes).padStart(2, '0') : ''}`;
  }
}
