import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '@dougs/synchronized-accounts/dto';

@Pipe({
  name: 'currencyCodeToSymbol',
  standalone: true,
})
export class CurrencyCodeToSymbolPipe implements PipeTransform {
  transform(value: Currency): string {
    switch (value) {
      case Currency.EUR:
        return '€';
      case Currency.USD:
        return '$';
      case Currency.GBP:
        return '£';
      case Currency.CNY:
      case Currency.JPY:
        return '¥';
      case Currency.AUD:
      case Currency.CAD:
        return value + '$';
      case Currency.HKD:
        return 'HK$';
      case Currency.ILS:
        return '₪';
      default:
        return value;
    }
  }
}
