import { User } from '../dto';

const getDefaults = (): User => ({
  uuid: '1',
  id: 1,
  createdAt: new Date('2021-11-10'),
  email: 'test@test.fr',
  isSignupCompleted: false,
  company: {
    id: 1,
    legalName: '',
  },
  companyIds: [],
  flags: [],
  role: 'customer',
  teamId: undefined,
  department: 'accounting',
  isAccountantOrAdmin: true,
  isAdmin: true,
  isAccountant: false,
  name: 'name',
  username: 'username',
  isEligibleToComptastart: false,
  metadata: {
    compactAppAside: false,
    darkMode: 'light',
    hasSeenProductTour: true,
    hasSeenAccountingOnboardingMovie: true,
    hasSeenNewServiceCatalog: false,
    subscribedToOperationNotifications: false,
    intercomEmail: '',
    askForRanking: false,
  },
  profile: undefined,
  isEmailValidated: false,
  profileId: 1,
  accountingFirmId: 1,
  avatarUrl: '',
  companySummary: {
    pills: [],
    intercom: null,
    postIts: [],
  },
  referralHash: '',
  referrer: undefined,
  referrerHash: undefined,
  startPayingOnPartnerSide: '',
  deletedAt: new Date('2022-04-25'),
  suspendedAt: new Date('2022-04-25'),
  suspended: false,
  preferredCompanyId: null,
  companies: [
    {
      id: 1,
      legalName: '',
    },
  ],
});

export const getUserMock = (p?: Partial<User>): User => ({
  ...getDefaults(),
  ...p,
});
