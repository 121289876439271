import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, TemplateRef } from '@angular/core';
import { TooltipDirective } from './tooltip.directive';

@Component({
  selector: 'dougs-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  standalone: true,
  imports: [CommonModule, TooltipDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  rawMessage!: string;
  templateMessage!: TemplateRef<Element>;
  classes = '';

  constructor(private readonly cdr: ChangeDetectorRef) {}

  detectChanges(): void {
    this.cdr.detectChanges();
  }
}
