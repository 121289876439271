import {
  ConnectedOverlayPositionChange,
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'dougs-dropdown',
  templateUrl: './dropdown.component.html',
  standalone: true,
  imports: [CommonModule, PortalModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnDestroy {
  @Input()
  public reference!: HTMLElement;

  @Input() displayArrow = true;
  @Input() isSelect = false;
  @Input() widthToRefElement = true;
  @Input() isDatepicker = false;
  @Input() hasBackdrop = true;
  @Input() classes: string | string[] = '';
  @Input() maxHeight = 0;

  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() opened: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(CdkPortal)
  public contentTemplate!: CdkPortal;

  protected overlayRef!: OverlayRef;

  public showing = false;
  public onTop = false;

  afterClosed$ = new Subject<void>();
  backdropSubscription?: Subscription;
  escapeSubscription?: Subscription;
  positionStrategySubscription?: Subscription;

  constructor(
    protected overlay: Overlay,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  public show(): void {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.attach(this.contentTemplate);
    this.backdropSubscription = this.overlayRef.backdropClick().subscribe(() => this.hide());
    this.escapeSubscription = this.overlayRef
      .keydownEvents()
      .pipe(filter((event: KeyboardEvent) => event.key === 'Escape'))
      .subscribe((event: KeyboardEvent) => {
        event.preventDefault();
        this.hide();
      });
    this.showing = true;
    this.syncWidth();
    this.opened.emit();
  }

  public hide(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.showing = false;
      this.afterClosed$.next();
      this.closed.emit();
    }
  }

  @HostListener('window:resize')
  public onWinResize() {
    this.syncWidth();
  }

  protected getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.reference)
      .withPush(true)
      .withGrowAfterOpen(true)
      .withViewportMargin(8)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        },
      ]);

    this.positionStrategySubscription = positionStrategy.positionChanges.subscribe(
      (change: ConnectedOverlayPositionChange) => {
        this.updateCurrentPositionClass(change.connectionPair);
      },
    );

    const scrollStrategy = this.overlay.scrollStrategies.block();

    return new OverlayConfig({
      positionStrategy: positionStrategy,
      scrollStrategy: scrollStrategy,
      hasBackdrop: this.hasBackdrop,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: this.classes,
    });
  }

  private updateCurrentPositionClass(connectionPair: ConnectionPositionPair): void {
    this.onTop = connectionPair.originY === 'top';

    this.cdr.detectChanges();
  }

  public updateOverlayPosition(): void {
    this.overlayRef?.updatePosition();
  }

  private syncWidth() {
    if (!this.overlayRef || !this.widthToRefElement) {
      return;
    }

    this.overlayRef.updateSize({
      width: this.reference.getBoundingClientRect().width,
    });
  }

  ngOnDestroy() {
    this.backdropSubscription?.unsubscribe();
    this.positionStrategySubscription?.unsubscribe();
    this.escapeSubscription?.unsubscribe();
  }
}
