import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DragNDropDirective } from '../../../directives';
import { DropdownComponent, DropdownTriggerDirective } from '../../dropdown';

@Component({
  selector: 'dougs-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  standalone: true,
  imports: [CommonModule, DropdownTriggerDirective, DragNDropDirective, DropdownComponent, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInputComponent {
  @Input() fileInputText = 'Attacher un fichier';
  @Input() noMargin = false;
  @Input() acceptFileType = '*';
  @Input() square = false;

  @Input() multiple = true;
  @Input() shouldAddVendorInvoice = false;
  @Input() shouldAddSalesInvoice = false;

  @ViewChild('fileInput')
  dropdownFileInput!: DropdownComponent;

  @Output() uploadFiles: EventEmitter<FileList | File> = new EventEmitter<FileList | File>();
  @Output() attachFromVendorInvoice: EventEmitter<void> = new EventEmitter<void>();
  @Output() attachFromSalesInvoice: EventEmitter<void> = new EventEmitter<void>();

  constructor(public element: ElementRef) {}

  onUploadFiles(e: FileList, event?: Event): void {
    const files: FileList = {
      ...e,
      length: e?.length || 0,
    } as FileList;
    const file: File | null = (files?.[0] as File) || null;
    if (!this.multiple && file) {
      this.uploadFiles.emit(file);
    } else if (files?.length > 0) {
      this.uploadFiles.emit(files);
    }
    this.dropdownFileInput.hide();
    if (event?.target) {
      (event?.target as any).value = null;
    }
  }
}
