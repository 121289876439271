export enum Currency {
  'EUR' = 'EUR',
  'USD' = 'USD',
  'GBP' = 'GBP',
  'CNY' = 'CNY',
  'JPY' = 'JPY',
  'AUD' = 'AUD',
  'CAD' = 'CAD',
  'HKD' = 'HKD',
  'ILS' = 'ILS',
  'MGA' = 'MGA',
}
