export function groupBy<T, U>(
  iteratee: (t: T) => { key: string; groupInfo: U },
  collection: T[],
): { [key: string]: [U, T[]] } {
  return collection.reduce(
    (acc, cv) => {
      const { key, groupInfo } = iteratee(cv);
      const group = acc[key];
      if (!group) {
        acc[key] = [groupInfo, [cv]];
      } else {
        const [, members] = group;
        members.push(cv);
      }
      return acc;
    },
    {} as { [key: string]: [U, T[]] },
  );
}

export function groupByKey<T>(getKey: (item: T) => string, collection: T[]): { [x: string]: T[] } {
  return collection.reduce((result: { [x: string]: T[] }, item: T) => {
    const itemKey: string = getKey(item);
    return {
      ...result,
      [itemKey]: [...(result[itemKey] || []), item],
    };
  }, {});
}
