import { ConfigBack, CurrencyISOCode } from '../dto';

const getDefaults = (): ConfigBack => ({
  missionAcceptationSurveyQuestions: [],
  certifiedOrganism: {
    organisms: [],
  },
  categoryGroups: {
    1: {
      name: '',
      color: '',
      id: 1,
    },
    2: {
      name: '',
      color: '',
      id: 2,
    },
    3: {
      name: '',
      color: '',
      id: 3,
    },
    4: {
      name: '',
      color: '',
      id: 4,
    },
    5: {
      name: '',
      color: '',
      id: 5,
    },
    6: {
      name: '',
      color: '',
      id: 6,
    },
    7: {
      name: '',
      color: '',
      id: 7,
    },
    8: {
      name: '',
      color: '',
      id: 8,
    },
    9: {
      name: '',
      color: '',
      id: 9,
    },
    10: {
      name: '',
      color: '',
      id: 10,
    },
    11: {
      name: '',
      color: '',
      id: 11,
    },
    12: {
      name: '',
      color: '',
      id: 12,
    },
    13: {
      name: '',
      color: '',
      id: 13,
    },
  },
  corporatePurposes: {
    corporatePurpose: [],
  },
  countries: {
    countries: [],
  },
  courtsAdministrationServices: [],
  companyTaxesServices: [],
  entrySurvey: {
    questions: [],
  },
  labftSurveyQuestions: {
    canSignFlags: [],
    sections: {},
  },
  taxRegimes: {
    ir_2031: {
      isFirst: false,
      startDate: '2022-09-22',
      value: 'ir_2031',
    },
    ir_2035: {
      isFirst: false,
      startDate: '2022-09-22',
      value: 'ir_2035',
    },
    ir_2072: {
      isFirst: false,
      startDate: '2022-09-22',
      value: 'ir_2072',
    },
    ir_lmnp: {
      isFirst: false,
      startDate: '2022-09-22',
      value: 'ir_lmnp',
    },
    ir_lmp: {
      isFirst: false,
      startDate: '2022-09-22',
      value: 'ir_lmp',
    },
    ir_micro_bic: {
      isFirst: false,
      startDate: '2022-09-22',
      value: 'ir_micro_bic',
    },
    na: {
      isFirst: false,
      startDate: '2022-09-22',
      value: 'na',
    },
    ir_micro_bnc: {
      isFirst: false,
      startDate: '2022-09-22',
      value: 'ir_micro_bnc',
    },
    is: {
      isFirst: false,
      startDate: '2022-09-22',
      value: 'is',
    },
  },
  team: {
    accountingTeams: [],
    careTeams: [],
    fiscalTeams: [],
    internalFinanceTeams: [],
    legalTeams: [],
    socialTeams: [],
    techTeams: [],
    douggieId: 1,
  },
  vatSettings: {
    labels: {
      vatActivities: {
        exemption: [],
        none: [],
        simplified: [],
        standardMonthly: [],
        standardQuaterly: [],
      },
      vatTypes: [],
    },
    thresholds: {
      simplified: {},
      none: {},
      exemption: {},
      standardMonthly: {},
      standardQuaterly: {},
    },
  },
  legalForms: {
    ass: {
      value: 'ass',
      name: 'Association',
      label: 'ASS',
      definiteNamePrefix: "l'",
      hasLegalName: true,
      hasInseePublication: false,
      hasKbis: false,
      hasBylaws: true,
      hasGeneralAssembly: true,
      isCompany: false,
      shareType: null,
      allowMultipleAssociates: true,
      allowMultipleCeos: false,
      allowCompanyAsAssociate: false,
      allowCompanyAsCeo: false,
      allowAssociateAccount: false,
      tns: 'never',
      allowKilometricIndemnityForTaxRegimes: [],
      allowKilometricIndemnity: [],
      allowedTaxRegimes: [],
      unAvailableTaxRegimes: ['is', 'ir_2031'],
      preferredTaxRegime: 'na',
      normalTaxRegime: 'na',
      taxRegimeOptionDuration: null,
    },
    ei: {
      value: 'ei',
      name: 'Entreprise individuelle',
      label: 'EI (professions libérales, etc.)',
      definiteNamePrefix: "l'",
      hasLegalName: false,
      hasInseePublication: true,
      hasKbis: false,
      hasBylaws: false,
      hasGeneralAssembly: false,
      isCompany: false,
      shareType: null,
      allowMultipleAssociates: false,
      allowMultipleCeos: false,
      allowCompanyAsAssociate: false,
      allowCompanyAsCeo: false,
      allowAssociateAccount: true,
      tns: 'always',
      allowKilometricIndemnityForTaxRegimes: [],
      allowKilometricIndemnity: ['ir_2035'],
      allowedTaxRegimes: ['ir_2031', 'ir_2035', 'ir_micro_bic', 'ir_micro_bnc', 'ir_lmnp', 'ir_lmp'],
      unAvailableTaxRegimes: ['is'],
      preferredTaxRegime: 'na',
      normalTaxRegime: 'na',
      taxRegimeOptionDuration: null,
    },
    eirl: {
      value: 'eirl',
      name: 'Entreprise individuelle à Responsabilité Limitée',
      label: 'EIRL',
      definiteNamePrefix: "l'",
      hasLegalName: false,
      hasInseePublication: true,
      hasKbis: false,
      hasBylaws: false,
      hasGeneralAssembly: false,
      isCompany: true,
      shareType: null,
      allowMultipleAssociates: false,
      allowMultipleCeos: false,
      allowCompanyAsAssociate: false,
      allowCompanyAsCeo: false,
      allowAssociateAccount: true,
      tns: 'always',
      allowKilometricIndemnityForTaxRegimes: [],
      allowKilometricIndemnity: ['ir_2035', 'is'],
      allowedTaxRegimes: ['is', 'ir_2031', 'ir_2035', 'ir_micro_bic', 'ir_micro_bnc'],
      unAvailableTaxRegimes: [],
      preferredTaxRegime: 'na',
      normalTaxRegime: 'ir',
      taxRegimeOptionDuration: null,
    },
    eurl: {
      value: 'eurl',
      name: 'Entreprise Unipersonnelle à Responsabilité Limitée',
      label: 'EURL',
      definiteNamePrefix: "l'",
      hasLegalName: true,
      hasInseePublication: false,
      hasKbis: true,
      hasBylaws: true,
      hasGeneralAssembly: true,
      isCompany: true,
      shareType: 'part',
      allowMultipleAssociates: false,
      allowMultipleCeos: false,
      allowCompanyAsAssociate: true,
      allowCompanyAsCeo: false,
      allowAssociateAccount: true,
      tns: 'majority',
      allowKilometricIndemnityForTaxRegimes: [],
      allowKilometricIndemnity: ['*'],
      allowedTaxRegimes: ['is', 'ir_2031', 'ir_2035', 'ir_micro_bic', 'ir_micro_bnc'],
      unAvailableTaxRegimes: [],
      preferredTaxRegime: 'is',
      normalTaxRegime: 'ir',
      taxRegimeOptionDuration: null,
    },
    gie: {
      value: 'gie',
      name: "Groupement d'intérêt économique",
      label: 'GIE',
      definiteNamePrefix: 'le',
      hasLegalName: true,
      hasInseePublication: false,
      hasKbis: true,
      hasBylaws: true,
      hasGeneralAssembly: true,
      isCompany: true,
      shareType: 'part',
      allowMultipleAssociates: true,
      allowMultipleCeos: true,
      allowCompanyAsAssociate: true,
      allowCompanyAsCeo: true,
      allowAssociateAccount: true,
      tns: 'always',
      allowKilometricIndemnityForTaxRegimes: [],
      allowKilometricIndemnity: ['*'],
      allowedTaxRegimes: ['ir_2031'],
      unAvailableTaxRegimes: [],
      preferredTaxRegime: 'ir_2031',
      normalTaxRegime: 'ir',
      taxRegimeOptionDuration: null,
    },
    sarl: {
      value: 'sarl',
      name: 'Société à Responsabilité Limitée',
      label: 'SARL',
      definiteNamePrefix: 'la',
      hasLegalName: true,
      hasInseePublication: false,
      hasKbis: true,
      hasBylaws: true,
      hasGeneralAssembly: true,
      isCompany: true,
      shareType: 'part',
      allowMultipleAssociates: true,
      allowMultipleCeos: true,
      allowCompanyAsAssociate: true,
      allowCompanyAsCeo: false,
      allowAssociateAccount: true,
      allowKilometricIndemnityForTaxRegimes: [],

      tns: 'majority',
      allowKilometricIndemnity: ['*'],
      allowedTaxRegimes: ['is', 'ir_2031', 'ir_2035'],
      unAvailableTaxRegimes: [],
      preferredTaxRegime: 'is',
      normalTaxRegime: 'is',
      taxRegimeOptionDuration: null,
    },
    'sarl famille': {
      value: 'sarl famille',
      name: 'Société à Responsabilité Limitée',
      label: 'SARL famille',
      definiteNamePrefix: 'la',
      hasLegalName: true,
      hasInseePublication: false,
      hasKbis: true,
      hasBylaws: true,
      hasGeneralAssembly: true,
      isCompany: true,
      shareType: 'part',
      allowMultipleAssociates: true,
      allowMultipleCeos: true,
      allowCompanyAsAssociate: true,
      allowCompanyAsCeo: false,
      allowAssociateAccount: true,
      tns: 'majority',
      allowKilometricIndemnityForTaxRegimes: [],
      allowKilometricIndemnity: ['*'],
      allowedTaxRegimes: ['is', 'ir_2031', 'ir_lmnp', 'ir_lmp'],
      unAvailableTaxRegimes: [],
      preferredTaxRegime: 'is',
      normalTaxRegime: 'is',
      taxRegimeOptionDuration: null,
    },
    sas: {
      value: 'sas',
      name: 'Société par Actions Simplifiée',
      label: 'SAS',
      definiteNamePrefix: 'la',
      hasLegalName: true,
      hasInseePublication: false,
      hasKbis: true,
      hasBylaws: true,
      hasGeneralAssembly: true,
      isCompany: true,
      shareType: 'action',
      allowMultipleAssociates: true,
      allowMultipleCeos: false,
      allowCompanyAsAssociate: true,
      allowCompanyAsCeo: true,
      allowAssociateAccount: true,
      tns: 'never',
      allowKilometricIndemnityForTaxRegimes: [],
      allowKilometricIndemnity: ['*'],
      allowedTaxRegimes: ['is', 'ir_2031', 'ir_2035'],
      unAvailableTaxRegimes: [],
      preferredTaxRegime: 'is',
      normalTaxRegime: 'is',
      taxRegimeOptionDuration: null,
    },
    sasu: {
      value: 'sasu',
      name: 'Société par Actions Simplifiée Unipersonnelle',
      label: 'SASU',
      definiteNamePrefix: 'la',
      hasLegalName: true,
      hasInseePublication: false,
      hasKbis: true,
      hasBylaws: true,
      hasGeneralAssembly: true,
      isCompany: true,
      shareType: 'action',
      allowMultipleAssociates: false,
      allowMultipleCeos: false,
      allowCompanyAsAssociate: true,
      allowCompanyAsCeo: true,
      allowAssociateAccount: true,
      tns: 'never',
      allowKilometricIndemnityForTaxRegimes: [],
      allowKilometricIndemnity: ['*'],
      allowedTaxRegimes: ['is', 'ir_2031', 'ir_2035'],
      unAvailableTaxRegimes: [],
      preferredTaxRegime: 'is',
      normalTaxRegime: 'is',
      taxRegimeOptionDuration: null,
    },
    sc: {
      value: 'sc',
      name: 'Société Civile',
      label: 'SC',
      definiteNamePrefix: 'la',
      hasLegalName: true,
      hasInseePublication: false,
      hasKbis: true,
      hasBylaws: true,
      hasGeneralAssembly: true,
      isCompany: true,
      shareType: 'part',
      allowMultipleAssociates: true,
      allowMultipleCeos: true,
      allowCompanyAsAssociate: true,
      allowCompanyAsCeo: true,
      allowAssociateAccount: true,
      tns: 'majority',
      allowKilometricIndemnityForTaxRegimes: [],
      allowKilometricIndemnity: ['*'],
      allowedTaxRegimes: ['is'],
      unAvailableTaxRegimes: [],
      preferredTaxRegime: 'is',
      normalTaxRegime: 'is',
      taxRegimeOptionDuration: null,
    },
    sci: {
      value: 'sci',
      name: 'Société Civile Immobilière',
      label: 'SCI',
      definiteNamePrefix: 'la',
      hasLegalName: true,
      hasInseePublication: false,
      hasKbis: true,
      hasBylaws: true,
      hasGeneralAssembly: true,
      isCompany: true,
      shareType: 'part',
      allowMultipleAssociates: true,
      allowMultipleCeos: true,
      allowCompanyAsAssociate: true,
      allowCompanyAsCeo: true,
      allowAssociateAccount: true,
      tns: 'never',
      allowKilometricIndemnityForTaxRegimes: [],
      allowKilometricIndemnity: ['*'],
      allowedTaxRegimes: ['is'],
      unAvailableTaxRegimes: ['ir_2072'],
      preferredTaxRegime: 'is',
      normalTaxRegime: 'ir',
      taxRegimeOptionDuration: null,
    },
    selarl: {
      value: 'selarl',
      name: 'Société d’Exercice Libérale à Responsabilité Limitée',
      label: 'SELARL',
      definiteNamePrefix: 'la',
      hasLegalName: true,
      hasInseePublication: false,
      hasKbis: true,
      hasBylaws: true,
      hasGeneralAssembly: true,
      isCompany: true,
      shareType: 'part',
      allowMultipleAssociates: true,
      allowMultipleCeos: true,
      allowCompanyAsAssociate: true,
      allowCompanyAsCeo: false,
      allowAssociateAccount: true,
      tns: 'always',
      allowKilometricIndemnityForTaxRegimes: [],
      allowKilometricIndemnity: ['*'],
      allowedTaxRegimes: ['is', 'ir_2031', 'ir_2035'],
      unAvailableTaxRegimes: [],
      preferredTaxRegime: 'is',
      normalTaxRegime: 'is',
      taxRegimeOptionDuration: null,
    },
    selarlu: {
      value: 'selarlu',
      name: "Société d'Exercice Libérale à Responsabilité Limitée Unipersonnelle",
      label: 'SELARLU',
      definiteNamePrefix: 'la',
      hasLegalName: true,
      hasInseePublication: false,
      hasKbis: true,
      hasBylaws: true,
      hasGeneralAssembly: true,
      isCompany: true,
      shareType: 'part',
      allowMultipleAssociates: false,
      allowMultipleCeos: false,
      allowCompanyAsAssociate: true,
      allowCompanyAsCeo: false,
      allowAssociateAccount: true,
      tns: 'majority',
      allowKilometricIndemnityForTaxRegimes: [],
      allowKilometricIndemnity: ['*'],
      allowedTaxRegimes: ['is', 'ir_2031', 'ir_2035'],
      unAvailableTaxRegimes: [],
      preferredTaxRegime: 'is',
      normalTaxRegime: 'ir',
      taxRegimeOptionDuration: null,
    },
    selas: {
      value: 'selas',
      name: 'Société d’Exercice Libérale par Actions Simplifiée',
      label: 'SELAS',
      definiteNamePrefix: 'la',
      hasLegalName: true,
      hasInseePublication: false,
      hasKbis: true,
      hasBylaws: true,
      hasGeneralAssembly: true,
      isCompany: true,
      shareType: 'action',
      allowMultipleAssociates: true,
      allowMultipleCeos: false,
      allowCompanyAsAssociate: true,
      allowCompanyAsCeo: true,
      allowAssociateAccount: true,
      tns: 'always',
      allowKilometricIndemnityForTaxRegimes: [],
      allowKilometricIndemnity: ['*'],
      allowedTaxRegimes: ['is', 'ir_2031', 'ir_2035'],
      unAvailableTaxRegimes: [],
      preferredTaxRegime: 'is',
      normalTaxRegime: 'is',
      taxRegimeOptionDuration: null,
    },
    selasu: {
      value: 'selasu',
      name: 'Société d’Exercice Libérale par Actions Simplifiée Unipersonnelle',
      label: 'SELASU',
      definiteNamePrefix: 'la',
      hasLegalName: true,
      hasInseePublication: false,
      hasKbis: true,
      hasBylaws: true,
      hasGeneralAssembly: true,
      isCompany: true,
      shareType: 'action',
      allowMultipleAssociates: false,
      allowMultipleCeos: false,
      allowCompanyAsAssociate: true,
      allowCompanyAsCeo: true,
      allowAssociateAccount: true,
      tns: 'always',
      allowKilometricIndemnityForTaxRegimes: [],
      allowKilometricIndemnity: ['*'],
      allowedTaxRegimes: ['is', 'ir_2031', 'ir_2035'],
      unAvailableTaxRegimes: [],
      preferredTaxRegime: 'is',
      normalTaxRegime: 'is',
      taxRegimeOptionDuration: null,
    },
    snc: {
      value: 'snc',
      name: 'Société en Nom Collectif',
      label: 'SNC',
      definiteNamePrefix: 'la',
      hasLegalName: true,
      hasInseePublication: false,
      hasKbis: true,
      hasBylaws: true,
      hasGeneralAssembly: true,
      isCompany: true,
      shareType: 'part',
      allowMultipleAssociates: true,
      allowMultipleCeos: true,
      allowCompanyAsAssociate: true,
      allowCompanyAsCeo: true,
      allowAssociateAccount: true,
      tns: 'always',
      allowKilometricIndemnityForTaxRegimes: [],
      allowKilometricIndemnity: ['*'],
      allowedTaxRegimes: ['is', 'ir_2031'],
      unAvailableTaxRegimes: [],
      preferredTaxRegime: 'ir_2031',
      normalTaxRegime: 'ir',
      taxRegimeOptionDuration: null,
    },
  },
  resiliationReasons: [],
  legalCreationTasks: {
    creationTasks: {
      codes: [
        'legal:standardCreation',
        'legal:48hCreation',
        'legal:creation',
        'legal:newStandardCreation',
        'legal:new48hCreation',
        'legal:creationSciAutomatic',
        'legal:creationSASAutomatic',
        'legal:creationEURLAutomatic',
        'legal:creationEiMicroLmnp',
      ],
    },
    initialAppointmentTasks: {
      codes: [
        'legal:initialCreationAppointment',
        'legal:newInitialCreationAppointment',
        'legal:configInitialCreationAppointment',
      ],
    },
  },
  currencies: [
    { flag: 'eu', isoCode: CurrencyISOCode.EUR, label: 'Euro' },
    {
      flag: 'us',
      isoCode: CurrencyISOCode.USD,
      label: 'Dollar américain',
    },
    {
      flag: 'uk',
      isoCode: CurrencyISOCode.GBP,
      label: 'Livre britannique',
    },
    {
      flag: 'au',
      isoCode: CurrencyISOCode.AUD,
      label: 'Dollar autralien',
    },
    {
      flag: 'ca',
      isoCode: CurrencyISOCode.CAD,
      label: 'Dollar canadien',
    },
    {
      flag: 'ch',
      isoCode: CurrencyISOCode.CHF,
      label: 'Franc suisse',
    },
    {
      flag: 'cn',
      isoCode: CurrencyISOCode.CNY,
      label: 'Renminbi chinois',
    },
    {
      flag: 'hk',
      isoCode: CurrencyISOCode.HKD,
      label: 'Dollar de Hong-Kong',
    },
    {
      flag: 'jp',
      isoCode: CurrencyISOCode.JPY,
      label: 'Yen japonais',
    },
    {
      flag: 'mg',
      isoCode: CurrencyISOCode.MGA,
      label: 'Ariary malgache',
    },
  ],
});

export const getConfigBackMock = (p?: Partial<ConfigBack>): ConfigBack => ({
  ...getDefaults(),
  ...p,
});
