import {
  ConnectedOverlayPositionChange,
  ConnectionPositionPair,
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  OriginConnectionPosition,
  Overlay,
  OverlayConnectionPosition,
  OverlayPositionBuilder,
  OverlayRef,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, TemplateRef } from '@angular/core';
import { TooltipPosition } from './tooltip-position.type';
import { TooltipComponent } from './tooltip.component';

@Directive({ selector: '[dougsTooltip]', standalone: true })
export class TooltipDirective implements OnDestroy {
  private overlayRef!: OverlayRef;
  private tooltipRef: ComponentRef<TooltipComponent> | null = null;
  private animationTimeoutId: number | null = null;
  private rawMessage = '';
  private templateMessage: TemplateRef<Element> | null = null;

  @Input('dougsTooltip')
  set message(value: string | TemplateRef<Element>) {
    if (value instanceof TemplateRef) {
      this.templateMessage = value;
    } else {
      this.rawMessage = value;
    }

    if (this.tooltipRef) {
      this.hide();
      this.show();
    }
  }

  @Input('dougsTooltipDisable') disable = false;
  @Input('dougsTooltipCloseOnClick') closeOnClick = false;
  @Input('dougsTooltipSize') size: 'small' | 'medium' | 'big' = 'medium';
  @Input('dougsTooltipDelay') delay = 0;

  constructor(
    protected readonly overlay: Overlay,
    protected readonly overlayPositionBuilder: OverlayPositionBuilder,
    protected readonly elementRef: ElementRef,
    protected readonly window: Window,
  ) {}

  private _position: TooltipPosition = 'above';

  get position(): TooltipPosition {
    return this._position;
  }

  @Input('dougsTooltipPosition')
  set position(position: TooltipPosition) {
    if (this._position !== position) {
      this._position = position;

      if (this.overlayRef) {
        this.updatePosition(this.overlayRef);
        this.overlayRef.updatePosition();
      }
    }
  }

  get originPosition(): {
    main: OriginConnectionPosition;
    fallback: OriginConnectionPosition;
  } {
    const originPosition: OriginConnectionPosition = {
      originX: this.originX,
      originY: this.originY,
    };

    const { x, y } = this.invertPosition(originPosition.originX, originPosition.originY);

    return {
      main: originPosition,
      fallback: { originX: x, originY: y },
    };
  }

  get overlayPosition(): {
    main: OverlayConnectionPosition;
    fallback: OverlayConnectionPosition;
  } {
    const overlayPosition: OverlayConnectionPosition = {
      overlayX: this.overlayX,
      overlayY: this.overlayY,
    };

    const { x, y } = this.invertPosition(overlayPosition.overlayX, overlayPosition.overlayY);

    return {
      main: overlayPosition,
      fallback: { overlayX: x, overlayY: y },
    };
  }

  get originX(): 'end' | 'start' | 'center' {
    return this.position === 'right' ? 'end' : this.position === 'left' ? 'start' : 'center';
  }

  get originY(): 'top' | 'bottom' | 'center' {
    return this.position === 'above' ? 'top' : this.position === 'below' ? 'bottom' : 'center';
  }

  get overlayX(): 'end' | 'start' | 'center' {
    return this.position === 'right' ? 'start' : this.position === 'left' ? 'end' : 'center';
  }

  get overlayY(): 'top' | 'bottom' | 'center' {
    return this.position === 'above' ? 'bottom' : this.position === 'below' ? 'top' : 'center';
  }

  @HostListener('mouseenter')
  show() {
    if ((this.rawMessage || this.templateMessage) && !this.disable) {
      if (this.delay) {
        this.animationTimeoutId = this.window.setTimeout(() => {
          this.showTooltip();
          this.animationTimeoutId = null;
        }, this.delay);
      } else {
        this.showTooltip();
      }
    }
  }

  @HostListener('mouseleave')
  hide() {
    if (this.animationTimeoutId) {
      this.window.clearTimeout(this.animationTimeoutId);
    }
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.tooltipRef = null;
    }
  }

  @HostListener('click')
  hideIfNecessary(): void {
    if (this.closeOnClick) {
      this.hide();
    }
  }

  private showTooltip(): void {
    if (!this.overlayRef) {
      this.createOverlay();
    }

    const tooltipPortal: ComponentPortal<TooltipComponent> = new ComponentPortal(TooltipComponent);

    this.tooltipRef = this.overlayRef.attach(tooltipPortal);

    if (this.templateMessage) {
      this.tooltipRef.instance.templateMessage = this.templateMessage;
    } else {
      this.tooltipRef.instance.rawMessage = this.rawMessage;
    }

    this.tooltipRef.instance.classes = this.tooltipRef.instance.classes
      ? this.tooltipRef.instance.classes + ' ' + this.size
      : this.size;
  }

  private createOverlay(): void {
    const positionStrategy: FlexibleConnectedPositionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withFlexibleDimensions(false);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    this.updatePosition(this.overlayRef);

    positionStrategy.positionChanges.subscribe((change: ConnectedOverlayPositionChange) => {
      this.updateCurrentPositionClass(change.connectionPair);
    });
  }

  private updatePosition(overlayRef: OverlayRef): void {
    const origin = this.originPosition;
    const overlay = this.overlayPosition;

    (overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy).withPositions([
      { ...origin.main, ...overlay.main },
      { ...origin.fallback, ...overlay.fallback },
    ]);
  }

  private updateCurrentPositionClass(connectionPair: ConnectionPositionPair): void {
    const { overlayY, originX, originY } = connectionPair;
    let newPosition: TooltipPosition;

    if (overlayY === 'center') {
      newPosition = originX === 'start' ? 'left' : 'right';
    } else {
      newPosition = overlayY === 'bottom' && originY === 'top' ? 'above' : 'below';
    }

    if (this.tooltipRef) {
      if (newPosition !== this.position) {
        this.tooltipRef.instance.classes = newPosition;
        this.position = newPosition;
      } else {
        this.tooltipRef.instance.classes = this.tooltipRef.instance.classes
          ? this.tooltipRef.instance.classes + ' ' + this.position
          : this.position;
      }

      this.tooltipRef.instance.detectChanges();
    }
  }

  private invertPosition(x: HorizontalConnectionPos, y: VerticalConnectionPos) {
    if (this.position === 'above' || this.position === 'below') {
      if (y === 'top') {
        y = 'bottom';
      } else if (y === 'bottom') {
        y = 'top';
      }
    } else if (x === 'end') {
      x = 'start';
    } else if (x === 'start') {
      x = 'end';
    }

    return { x, y };
  }

  ngOnDestroy(): void {
    this.hide();
  }
}
