import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[dougs100vhMobile]',
  standalone: true,
})
export class VhMobile implements AfterViewInit {
  constructor(
    private readonly host: ElementRef,
    private readonly window: Window,
  ) {}

  ngAfterViewInit(): void {
    if (!CSS.supports('height: 100svh') && this.window.visualViewport) {
      this.host.nativeElement.style.height = this.window.visualViewport.height + 'px';
    }
  }
}
