export interface Attachment {
  id: number | string;
  name: string;
  file: FileAttachment;
  description?: string;
  creator?: {
    profile: {
      fullName: string;
      avatarUrl: string;
      alternateAvatarUrl: string;
    };
  };
  fileId?: number;
  modelId: number;
  modelName?: string;
  uuid?: string;
  companyId: number;
  progress?: number;
  type?: string;
  createdAt?: Date;
  metadata?: AttachmentMetadata;
  url?: string;
  hasBeenUploadedNow?: boolean;
  refusedAt?: Date | null;
  validatedAt?: Date | null;
}

export interface AttachmentMetadata {
  accountingNumberMap?: {
    [key: string]: {
      accountingNumber: string | null;
      label: string | null;
      fileLabel: string | null;
    };
  };
  bankStatementId?: string;
  bankStatementData?: {
    [key: string]: any;
  };
  accountingResult?: number;
  balanceSheetTotal?: number;
  externalId?: string;
  openingDate?: Date | string;
  requiredAccountingNumbers?: string[];
  isTaxReportProcessed?: boolean;
  userErrorMessage?: string;
  validationRules?: ValidationRule[];
  payslipDate?: Date;
  balanceSheetByDougs?: any;
  isSigned?: boolean;
  isProjectStatusDocument?: boolean;
  partnerId?: number;
  variableCreatedAt?: string;
  loanId?: number;
  salesChannelId?: number;
  type?: string;
}

export interface ValidationRule {
  label: string;
  result: 'success' | 'error' | 'warning';
  failingLineNumbers: number[];
  successRequiredForAccountingReportGeneration: boolean;
  successRequiredForReversalGeneration: boolean;
}

export interface FileAttachment {
  url: string;
  name?: string;
  updatedAt?: Date | string;
  uuid?: string;
  extension?: string;
  metadata?: {
    terms?: {
      acceptedAt: Date | string;
      id: string;
      version: number;
    };
  };
}

export interface AttachmentPost {
  type: string;
  fileId: number;
  fileUuid: string;
}

export enum MimeTypesEnum {
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PDF = 'application/pdf',
  PNG = 'image/png',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  HEIC = 'image/heic',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ZIP = 'application/zip',
}
