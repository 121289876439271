<a
  [ngClass]="{ 'disable-link': !attachment.file?.url && !attachment?.url }"
  [href]="legacyApiServerUrl + (attachment.file?.url ?? attachment.url)"
  target="_blank"
  (click)="openFile()"
  (dragstart)="onDragStart($event)"
>
  <div class="file-pill" *ngIf="!square">
    <div
      class="file-pill__content"
      [ngClass]="{
        'file-pill__content--no-delete': !canDelete,
        'file-pill__content--in-progress': canDelete && attachment.progress
      }"
    >
      <ng-container [ngTemplateOutlet]="filePillContent"></ng-container>
    </div>
  </div>

  <div *ngIf="square" class="square-pill px-8" (click)="openFile()">
    <ng-container [ngTemplateOutlet]="filePillContent"></ng-container>
  </div>
</a>
<ng-template #filePillContent>
  <i
    @scaleIn
    [@.disabled]="!attachment.hasBeenUploadedNow || !enableAnimation"
    *ngIf="!attachment.progress"
    class="self-center fal fa-paperclip"
  ></i>
  <dougs-loader *ngIf="attachment.progress" @slideOut class="self-center" appearance="spin"></dougs-loader>
  <span class="filename">{{ attachment.name }}</span>
  <i
    @slideInFromSide
    [@.disabled]="!attachment.hasBeenUploadedNow || !enableAnimation"
    *ngIf="!attachment.progress && canDelete"
    class="fal fa-times delete-file"
    (click)="confirmDeleteFile($event)"
  ></i>
</ng-template>
