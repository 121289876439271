<div
  @animFlash
  *ngFor="let message of _flashMessagesService.messages$ | async; trackBy: trackByMessage"
  [ngClass]="[
    'flash-message',
    message.options.type,
    'py-16',
    'px-24',
    'mt-8',
    message.options.onClick ? 'pointer' : ''
  ]"
  (mouseenter)="_flashMessagesService.stopTimer(message)"
  (mouseleave)="_flashMessagesService.startTimer(message)"
>
  <div class="alert-content-wrapper">
    <i
      class="alert-icon fal"
      [ngClass]="{
        'fa-info-circle': message.options.type === 'info',
        'fa-check-circle': message.options.type === 'success',
        'fa-times-circle': message.options.type === 'error',
        'fa-exclamation-circle': message.options.type === 'warning',
        'fa-sync fa-spin': message.options.type === 'pending'
      }"
    ></i>
    <div class="alert-content pl-24" (click)="message.options?.onClick && message.options.onClick()">
      <div [innerHTML]="message.content"></div>
      <a *ngIf="message.options.reloadLink" href="javascript:window.location.reload()">Actualiser la page</a>
    </div>
  </div>
  <i
    *ngIf="message.options.canClose"
    class="alert-close fal fa-times"
    (click)="_flashMessagesService.close(message)"
  ></i>
</div>
