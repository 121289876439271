import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { CountryCode, getCountries, isValidPhoneNumber, parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigBackService, Country } from '@dougs/core/config-back';
import { FormService } from '@dougs/core/form';
import { TrackByPipe } from '../../pipes';
import { DropdownComponent, DropdownOptionComponent, DropdownTriggerDirective } from '../dropdown';
import {
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  PrefixFormFieldDirective,
} from '../form-field';
import { CountryDialCodePipe } from './country-dial-code.pipe';

@Component({
  selector: 'dougs-phone-number',
  standalone: true,
  imports: [
    CommonModule,
    ControlFormFieldDirective,
    FormsModule,
    FormFieldComponent,
    PrefixFormFieldDirective,
    DropdownComponent,
    DropdownOptionComponent,
    TrackByPipe,
    DropdownTriggerDirective,
    CountryDialCodePipe,
    ErrorFormFieldDirective,
  ],
  templateUrl: './phone-number.component.html',
  styleUrl: './phone-number.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PhoneNumberComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PhoneNumberComponent,
      multi: true,
    },
  ],
})
export class PhoneNumberComponent implements ControlValueAccessor, AfterViewInit {
  private _value = '';

  phoneCode = 'FR';

  @Input() size?: 'small' | 'medium' | 'large'; // FormFieldComponent['size']

  countries$: Observable<Country[] | undefined> = this.configBackService.countries$.pipe(
    map((countries) => {
      const countriesSupported: CountryCode[] = getCountries();
      return countries?.filter((country) => countriesSupported.includes(country.value.toUpperCase() as CountryCode));
    }),
  );

  control!: NgControl;

  onChange!: (value: string) => void;
  onTouch!: () => void;

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    if (this._value !== value) {
      this._value = value;
      try {
        const phoneNumber: PhoneNumber = parsePhoneNumber(this._value, this.phoneCode as CountryCode);
        this.onChange(phoneNumber.number);
      } catch (e) {
        this.onChange(value);
      }
    }
  }

  selectDialCode(value: string): void {
    this.phoneCode = value.toUpperCase();
    try {
      const phoneNumber: PhoneNumber = parsePhoneNumber(this._value, this.phoneCode as CountryCode);
      this.onChange(phoneNumber.number);
    } catch (e) {
      this.onChange(value);
    }
  }

  constructor(
    public readonly configBackService: ConfigBackService,
    public readonly injector: Injector,
    public readonly formService: FormService,
  ) {}

  ngAfterViewInit(): void {
    this.control = this.injector.get(NgControl);
  }

  validate({ value }: FormControl): boolean | { invalidPhoneNumber: boolean } {
    return !isValidPhoneNumber(value, this.phoneCode as CountryCode) && { invalidPhoneNumber: true };
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(value: string): void {
    try {
      const phoneNumber: PhoneNumber = parsePhoneNumber(value);
      this._value = phoneNumber.formatNational();
      this.phoneCode = phoneNumber?.country as string;
    } catch (e) {
      this._value = value;
    }
  }

  onBlur(): void {
    this.onTouch();
  }
}
