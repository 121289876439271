import { AccountBalance } from '../dto';
import { Currency } from '../enums';

const getDefaults = (): AccountBalance => ({
  accountId: 1,
  balance: 100,
  changeRate: 1,
  expenses: 100,
  incomes: 100,
  originalCurrency: Currency.EUR,
  originalCurrencyBalance: 1,
});

export const getAccountBalanceMock = (p?: Partial<AccountBalance>): AccountBalance => ({
  ...getDefaults(),
  ...p,
});
