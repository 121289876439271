import { Injectable } from '@angular/core';
import * as log from 'loglevel';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  // Reuse loglevel functions
  setLevel: (level: log.LogLevelDesc, persist?: boolean) => void = log.setLevel;
  trace: (...msg: unknown[]) => void = log.trace;
  debug: (...msg: unknown[]) => void = log.debug;
  info: (...msg: unknown[]) => void = log.info;
  warn: (...msg: unknown[]) => void = log.warn;
  error: (...msg: unknown[]) => void = log.error;
}
