import { Pipe, PipeTransform } from '@angular/core';
import { Partner } from '@dougs/partners/dto';

@Pipe({
  name: 'partnerImageUrl',
  standalone: true,
})
export class PartnerImageUrlPipe implements PipeTransform {
  transform(partner: Partner): string {
    if (partner.naturalPerson) {
      return (
        partner.naturalPerson.avatarUrl ||
        partner.naturalPerson.alternateAvatarUrl ||
        'https://www.gravatar.com/avatar/null?s=256&d=mm'
      );
    }
    return partner.legalPerson?.id && partner.legalPerson?.avatar?.name
      ? `/companies/${partner.legalPerson.id}/avatar?filename=${partner.legalPerson?.avatar?.name}`
      : 'https://www.gravatar.com/avatar/null?s=256&d=mm';
  }
}
