import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'targetBlankInHref',
  standalone: true,
})
export class TargetBlankInHrefPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    const hrefRegExp = new RegExp(/(<a)( )([^>]*>.*?<\/a>)/gm);

    if (value.match(hrefRegExp)) {
      return value.replace(hrefRegExp, '$1' + ' target="_blank" ' + '$3');
    }
    return value;
  }
}
