export function stringToNumber(stringNumber: string): number {
  const cleanedStringNumber: string = stringNumber
    .trim()
    .replace(/[^0-9.,-]/g, '')
    .replace(/,/g, '.');
  return parseFloat(cleanedStringNumber);
}

export function evalString(str: string): string {
  const sanitizeEvalRegExp = /^[()\s\d.,+-/*]*$/;

  if (!sanitizeEvalRegExp.test(str)) {
    return str;
  }
  try {
    return (0, eval)(str.replace(new RegExp(',', 'g'), '.')) + '';
  } catch (e) {
    return str;
  }
}
