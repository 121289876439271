<input
  class="radio-button"
  type="radio"
  [checked]="checked"
  [disabled]="disabled || disabledFromGroup"
  [name]="name"
  [id]="id"
  [required]="required"
  [value]="value"
  (change)="onChange($event)"
  (click)="onClick($event)"
/>
<label
  class="radio-button__label"
  [ngClass]="{ 'radio-button__label--no': noDesign, 'radio-button__label--light': light }"
  [for]="id"
  id="label-{{ id }}"
>
  <span class="radio-button__appearance mr-8"></span>
  <ng-content></ng-content>
</label>
