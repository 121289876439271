import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector, Type } from '@angular/core';
import { ModalConfig } from './modal-config.model';
import { ModalRef } from './modal-ref';
import { ModalComponent } from './modal.component';

export const MODAL_DATA = new InjectionToken<unknown>('ModalData');
export const MODAL_STYLE = new InjectionToken<unknown>('ModalStyle');

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    private readonly overlay: Overlay,
    private readonly injector: Injector,
  ) {}

  private _hasModalOpened = false;

  public get hasModalOpened(): boolean {
    return this._hasModalOpened;
  }

  public onModalClose(): void {
    this._hasModalOpened = false;
  }

  open<Output = unknown, Input = unknown>(content: Type<unknown>, config?: ModalConfig<Input>): ModalRef<Output> {
    const fullScreenStyle = config?.style?.fullScreen
      ? {
          width: '100%',
          height: '100%',
        }
      : {};

    const scrollStrategy = this.overlay.scrollStrategies.block();

    const configs = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'modal-background',
      panelClass: 'modal',
      scrollStrategy,
      ...fullScreenStyle,
    });

    const overlayRef: OverlayRef = this.overlay.create(configs);

    overlayRef.hostElement.classList.add('global-overlay-wrapper');

    const modalRef: ModalRef<Output, Input> = new ModalRef<Output, Input>(overlayRef, content, this, config);

    const injector = Injector.create({
      providers: [
        { provide: ModalRef, useValue: modalRef },
        { provide: MODAL_DATA, useValue: config?.data },
        { provide: MODAL_STYLE, useValue: config?.style },
      ],
      parent: this.injector,
    });

    overlayRef.attach(new ComponentPortal(ModalComponent, null, injector));

    document.body.classList.add('modal-opened');
    this._hasModalOpened = true;

    return modalRef;
  }
}
