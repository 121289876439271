import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'dougs-loader-fullpage',
  templateUrl: './loader-fullpage.component.html',
  styleUrls: ['./loader-fullpage.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderFullpageComponent {}
