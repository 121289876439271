import { SynchronizedAccount } from '../dto';
import { getAccountBalanceMock } from './account-balance.mock';

const getDefaults = (): SynchronizedAccount => ({
  id: 1,
  providers: [],
  bankStatements: [],
  accountName: 'accountName',
  accountingNumber: 512001,
  bankName: 'bankName',
  closed: false,
  closedAt: new Date('2022-12-12'),
  color: 'color',
  companyId: 1,
  createdAt: '',
  creatorId: 1,
  currency: '',
  memo: '',
  hidden: false,
  index: 1,
  name: '',
  number: '',
  type: 'bank',
  subType: 'subType',
  metadata: {
    balance: getAccountBalanceMock(),
  },
});

export const getSynchronizedAccountMock = (p?: Partial<SynchronizedAccount>): SynchronizedAccount => ({
  ...getDefaults(),
  ...p,
});
