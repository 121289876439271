<div dougsModalTitle>
  <h6>{{ data.title }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent [innerHTML]="data.body"></div>
<div dougsModalFooter>
  <dougs-button [dougsModalClose]="false" color="secondary">
    {{ data.noText || 'Annuler' }}
  </dougs-button>
  <dougs-button
    [dougsModalClose]="true"
    [color]="data.secondaryColor ? 'primary' : 'delete'"
    data-cy="confirmation-modal-accept-button"
  >
    {{ data.yesText || 'Oui' }}
  </dougs-button>
</div>
