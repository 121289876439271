import { TransactionProvider } from '../dto';

const getDefaults = (): TransactionProvider => ({
  id: 1,
  accountId: 1,
  attachments: [],
  companyId: 1,
  connectionId: 1,
  title: 'Provider',
  connectorId: 'abc',
  connector: {
    id: 'abc',
    type: 'test',
  },
  enabled: true,
  minDate: new Date(),
  maxDate: new Date(),
  externalId: 1,
});

export const getTransactionProviderMock = (p?: Partial<TransactionProvider>): TransactionProvider => ({
  ...getDefaults(),
  ...p,
});
