<div [ngClass]="size" class="form-field__wrapper">
  <ng-content select="[dougsFormFieldLabel]"></ng-content>
  <div
    [ngClass]="{
      'has-error': hasError,
      'has-suffix': _suffixChildren?.length,
      'has-prefix': _prefixChildren?.length
    }"
    class="form-field__content"
  >
    <div *ngIf="_prefixChildren?.length" class="form-field__prefix">
      <ng-content select="[dougsFormFieldPrefix]"></ng-content>
    </div>
    <ng-content></ng-content>
    <div *ngIf="_suffixChildren?.length" [ngClass]="{ 'no-border': noBorder }" class="form-field__suffix">
      <ng-content select="[dougsFormFieldSuffix]"></ng-content>
    </div>
  </div>
</div>
<ng-content select="[dougsFormFieldCharacterCount]"></ng-content>
<div *ngIf="hasError" class="form-field__errors">
  <ng-content select="[dougsFormFieldError]"></ng-content>
</div>
