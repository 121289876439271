import { Pipe } from '@angular/core';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';

@Pipe({
  name: 'countryDialCode',
  standalone: true,
})
export class CountryDialCodePipe {
  transform(countryCode: string): string {
    return getCountryCallingCode(countryCode.toUpperCase() as CountryCode);
  }
}
