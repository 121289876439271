import { AfterViewInit, Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[dougsModalContent]',
  standalone: true,
})
export class ModalContentDirective implements AfterViewInit, OnDestroy {
  private observer!: ResizeObserver;
  private modalContent: HTMLElement | null = null;
  private modalFooter: HTMLElement | null = null;

  @Input() dougsModalContent?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';

  constructor(private readonly host: ElementRef) {}

  @HostBinding('class')
  get elementClasses() {
    return `modal-content modal-section ${this.dougsModalContent ? this.dougsModalContent : 'medium'}`;
  }

  @HostListener('scroll', ['$event.target'])
  onContentScroll(container: HTMLElement) {
    // Disable shadow at the bottom of the container
    if (container.offsetHeight + container.scrollTop >= container.scrollHeight - 1) {
      this.modalFooter?.classList.remove('modal-footer-shadow');
    } else if (!this.modalFooter?.classList.contains('modal-footer-shadow')) {
      this.modalFooter?.classList.add('modal-footer-shadow');
    }
  }

  ngAfterViewInit() {
    this.modalContent = this.host.nativeElement;
    this.modalFooter = this.host.nativeElement?.parentNode?.querySelector('[dougsModalFooter]');

    this.observer = new ResizeObserver((entries) => {
      if (entries[0]?.target.scrollHeight > entries[0]?.target.clientHeight) {
        this.modalFooter?.classList.add('modal-footer-shadow');
      } else {
        this.modalFooter?.classList.remove('modal-footer-shadow');
      }
    });

    if (this.modalContent && this.modalFooter) {
      this.observer.observe(this.modalContent);
    }
  }

  ngOnDestroy() {
    if (this.modalContent) {
      this.observer.unobserve(this.modalContent);
    }
  }
}
