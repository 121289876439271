<ng-container *ngIf="setValidatorsOnCountryChanged$ | async" />
<form [formGroup]="addressFormGroup" (ngSubmit)="onSubmit()">
  <div dougsModalTitle>
    <h6 class="mr-16">Mon adresse n'est pas listée</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    Pour l'enregistrement de votre société sur le guichet unique de l'INPI, nous avons besoin d'une adresse reconnue. Si
    votre adresse n'est pas dans la liste, veuillez la compléter. Nous alerterons le service juridique.
  </div>
  <div dougsModalContent>
    @if (this.addressFieldsToDisplay.includes(AddressFieldsEnum.STREET)) {
      <div class="mb-16">
        <dougs-form-field [noMargin]="true">
          <label dougsFormFieldLabel>Numéro et rue</label>
          <input formControlName="street" placeholder="Numéro et rue" dougsFormFieldControl type="text" />
          @if (addressFormGroup.get('street')?.touched && addressFormGroup.get('street')?.invalid) {
            <span dougsFormFieldError>Veuillez renseigner le numéro et la rue.</span>
          }
        </dougs-form-field>
      </div>
    }
    <div class="form-layout__two-columns-small-first mb-16">
      @if (this.addressFieldsToDisplay.includes(AddressFieldsEnum.ZIPCODE)) {
        <dougs-form-field [noMargin]="true">
          <label dougsFormFieldLabel>Code postal</label>
          <input formControlName="zipcode" [placeholder]="'Ex. : 69005'" dougsFormFieldControl type="text" />
          @if (addressFormGroup.get('zipcode')?.touched && addressFormGroup.get('zipcode')?.invalid) {
            <span dougsFormFieldError>Veuillez renseigner le code postal.</span>
          }
        </dougs-form-field>
      }
      @if (this.addressFieldsToDisplay.includes(AddressFieldsEnum.CITY)) {
        <dougs-form-field [noMargin]="true">
          <label dougsFormFieldLabel>Ville</label>
          <input formControlName="city" placeholder="Ville" dougsFormFieldControl type="text" />
          @if (addressFormGroup.get('city')?.touched && addressFormGroup.get('city')?.invalid) {
            <span dougsFormFieldError>Veuillez renseigner la ville.</span>
          }
        </dougs-form-field>
      }
    </div>
    @if (this.addressFieldsToDisplay.includes(AddressFieldsEnum.ADDITIONAL_ADDRESS)) {
      <div class="mb-16">
        <dougs-form-field [noMargin]="true">
          <label dougsFormFieldLabel>Complément d'adresse</label>
          <input
            formControlName="additionalAddress"
            placeholder="Numéro de bâtiment, étage, service, etc. (facultatif)"
            dougsFormFieldControl
            type="text"
          />
        </dougs-form-field>
      </div>
    }
    @if (this.addressFieldsToDisplay.includes(AddressFieldsEnum.COUNTRY)) {
      <div class="mb-16">
        <div>
          <label dougsFormFieldLabel>Pays</label>
          <dougs-select
            maxHeight="250"
            dougsFormFieldControl
            formControlName="country"
            [searchable]="true"
            placeholder="-- Choisir un pays --"
          >
            <dougs-select-option
              *ngFor="let country of configBackService.countries$ | async; trackBy: trackByValue"
              [value]="country"
            >
              {{ country.label }}
            </dougs-select-option>
          </dougs-select>
        </div>
      </div>
    }
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    <dougs-button type="submit">Valider</dougs-button>
  </div>
</form>
