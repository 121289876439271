import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dougsMenuAnchor]',
  standalone: true,
})
export class MenuAnchorDirective implements OnInit {
  constructor(
    private readonly host: ElementRef,
    private readonly renderer2: Renderer2,
  ) {}

  private _headerSize = 70;
  private _anchorId!: string;

  get anchorId(): string {
    return this._anchorId;
  }

  @Input('dougsMenuAnchor') set anchorId(id: string) {
    this._anchorId = id;
    this.host.nativeElement.setAttribute('id', this.anchorId);
  }

  get headerSize(): number {
    return this._headerSize;
  }

  @Input('dougsMenuAnchorHeaderSize') set headerSize(size: number) {
    this._headerSize = size;
    this.renderer2.setStyle(this.host.nativeElement, 'scroll-margin-top', `${this.headerSize}px`);
  }

  ngOnInit(): void {
    this.renderer2.setStyle(this.host.nativeElement, 'scroll-margin-top', `${this.headerSize}px`);
  }
}
