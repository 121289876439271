<dougs-avatar
  class="mr-8"
  [dougsTooltipDisable]="disableFullName"
  [dougsTooltip]="fullName"
  [image]="avatarUrl"
  [size]="size"
></dougs-avatar>
<div [ngClass]="[align, fullWidth ? 'full-width' : '']">
  <dougs-panel-info [ngClass]="{ 'mb-8': !disableFullName }">
    <ng-content></ng-content>
  </dougs-panel-info>
  <p [class]="fullNameTextClass" class="small" *ngIf="!disableFullName">{{ fullName }}</p>
</div>
