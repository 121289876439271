import { Directive, HostBinding, InjectionToken } from '@angular/core';

export const CUSTOM_OPTION_FOOTER = new InjectionToken<CustomSelectOptionFooterDirective>('ErrorFormFieldDirective');

@Directive({
  selector: '[dougsCustomSelectOptionFooter]',
  standalone: true,
  providers: [{ provide: CUSTOM_OPTION_FOOTER, useExisting: CustomSelectOptionFooterDirective }],
})
export class CustomSelectOptionFooterDirective {
  @HostBinding('class') classes = 'custom-option-footer';
}
