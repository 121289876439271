import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Intercom } from '@supy-io/ngx-intercom';
import {
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '../../components';

@Component({
  selector: 'dougs-help-modal',
  templateUrl: './help-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalContentDirective,
    ModalCloseDirective,
    ModalFooterDirective,
    ButtonComponent,
  ],
})
export class HelpModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      title: string;
      body: string;
      contactUsMessage?: string;
    },
    private readonly intercom: Intercom,
  ) {}

  openIntercom(e: Event): void {
    e.preventDefault();
    this.intercom.show(this.data.contactUsMessage);
  }
}
