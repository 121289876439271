import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { GetDatePillMaxWidthPipe, IsSameDayPipe, TrackByPipe } from '../../../pipes';
import { ButtonComponent } from '../../button';
import { DividerComponent } from '../../divider';
import { DropdownComponent } from '../../dropdown';
import { DatepickerOptions } from '../datepicker-common.service';
import {
  DateRangePickerComponentService,
  DateRangePickerOutput,
  RangeMode,
} from './date-range-picker.component.service';

@Component({
  selector: 'dougs-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DropdownComponent,
    TrackByPipe,
    IsSameDayPipe,
    GetDatePillMaxWidthPipe,
    ButtonComponent,
    DividerComponent,
    TrackByPipe,
  ],
  providers: [DateRangePickerComponentService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerComponent {
  constructor(
    private readonly cdr: ChangeDetectorRef,
    public readonly dateRangePickerComponentService: DateRangePickerComponentService,
  ) {}

  RangeMode = RangeMode;
  onRangeChange: Subject<DateRangePickerOutput> = this.dateRangePickerComponentService.onRangeChange;

  @Input()
  set options(options: DatepickerOptions) {
    this.dateRangePickerComponentService.options = options;
  }

  @ViewChild('dropdownDatepicker') dropdown!: DropdownComponent;
  private _reference!: ElementRef;

  set reference(reference: ElementRef) {
    this._reference = reference;
    this.cdr.markForCheck();
  }

  get reference(): ElementRef {
    return this._reference;
  }

  setRange(startDate: Date | null, endDate: Date | null): void {
    this.dateRangePickerComponentService.setRange(startDate, endDate);
  }
}
