import { getWeekYear } from "../../../getWeekYear.mjs";
import { startOfWeek } from "../../../startOfWeek.mjs";
import { Parser } from "../Parser.mjs";
import { mapValue, normalizeTwoDigitYear, parseNDigits } from "../utils.mjs";

// Local week-numbering year
export class LocalWeekYearParser extends Parser {
  priority = 130;
  parse(dateString, token, match) {
    const valueCallback = year => ({
      year,
      isTwoDigitYear: token === "YY"
    });
    switch (token) {
      case "Y":
        return mapValue(parseNDigits(4, dateString), valueCallback);
      case "Yo":
        return mapValue(match.ordinalNumber(dateString, {
          unit: "year"
        }), valueCallback);
      default:
        return mapValue(parseNDigits(token.length, dateString), valueCallback);
    }
  }
  validate(_date, value) {
    return value.isTwoDigitYear || value.year > 0;
  }
  set(date, flags, value, options) {
    const currentYear = getWeekYear(date, options);
    if (value.isTwoDigitYear) {
      const normalizedTwoDigitYear = normalizeTwoDigitYear(value.year, currentYear);
      date.setFullYear(normalizedTwoDigitYear, 0, options.firstWeekContainsDate);
      date.setHours(0, 0, 0, 0);
      return startOfWeek(date, options);
    }
    const year = !("era" in flags) || flags.era === 1 ? value.year : 1 - value.year;
    date.setFullYear(year, 0, options.firstWeekContainsDate);
    date.setHours(0, 0, 0, 0);
    return startOfWeek(date, options);
  }
  incompatibleTokens = ["y", "R", "u", "Q", "q", "M", "L", "I", "d", "D", "i", "t", "T"];
}