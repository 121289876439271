import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Fields } from '@dougs/fields/dto';
import { CountUpDirective } from '../../directives';
import { ButtonComponent } from '../button';
import { LoaderComponent } from '../loader';
import { RingProgressComponent } from '../ring-progress';
import { CompletionService } from './completion.service';

@Component({
  selector: 'dougs-completion',
  templateUrl: './completion.component.html',
  styleUrls: ['./completion.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, LoaderComponent, RingProgressComponent, ButtonComponent, CountUpDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CompletionService],
})
export class CompletionComponent {
  private _completionPercent = 0;
  private _missingFields?: Fields;

  @Input() bottomMargin = true;
  @Input() bottomBorder = true;
  @Input() smallSize = false;

  @Input() completionOnSettings = false;
  @Input() completionOnAccountingSurvey = false;
  @Input() isWaitingForCompletionPercentageUpdate = false;
  @Input() settingsLink!: string;
  @Input() container?: HTMLElement;
  @Input() showHeader = true;
  @Input() missingFieldsLink = true;
  @Input() showBorder = true;
  @Input() baseline = 'Complétez tous les champs pour garantir des déclarations fiscales conformes et dans les temps !';

  oneItem = false;
  hasMissingFields = true;
  missingFieldsLength = 0;

  get missingFields(): Fields | undefined {
    return this._missingFields;
  }

  @Input()
  set missingFields(value: Fields) {
    this._missingFields = value;

    if (value) {
      this.missingFieldsLength = Object.keys(value).length;
      this.oneItem = this.missingFieldsLength === 1;
      this.hasMissingFields = this.missingFieldsLength > 0;
    }
  }

  @Input()
  set completionPercent(completionPercent: number) {
    this._completionPercent = completionPercent;
  }

  get completionPercent(): number {
    return this._completionPercent;
  }

  constructor(public readonly completionService: CompletionService) {}
}
