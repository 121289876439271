import { Pipe, PipeTransform } from '@angular/core';

type TrackByFunction<T extends { [k: string]: unknown } = { [k: string]: unknown }> = (
  index: number,
  item: T,
) => unknown;
type TrackByFunctionCache = { [k: string]: TrackByFunction };

const cache: TrackByFunctionCache = Object.create(null);

@Pipe({
  name: 'trackBy',
  standalone: true,
})
export class TrackByPipe implements PipeTransform {
  transform(propertyName: string): TrackByFunctionCache[string] {
    if (!cache[propertyName]) {
      cache[propertyName] = this.buildFunctionForProperty(propertyName);
    }
    return cache[propertyName];
  }

  private buildFunctionForProperty(propertyName: string): TrackByFunction {
    return function trackByProperty(
      _index: Parameters<TrackByFunction>[0],
      item: Parameters<TrackByFunction>[1],
    ): ReturnType<TrackByFunction> {
      return item?.[propertyName];
    };
  }
}
