import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[dougsStepStatus]',
  standalone: true,
})
export class StepStatusDirective {
  @HostBinding('class')
  get elementClasses(): string {
    return `step-status`;
  }
}
