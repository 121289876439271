import { Injectable } from '@angular/core';
import { indexOf } from 'lodash';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { Attachment, SourceDocumentAttachment } from '../dto';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService<
  T extends {
    id: number;
    attachments?: Attachment[];
    vendorInvoices?: VendorInvoice[];
    sourceDocumentAttachments?: SourceDocumentAttachment[];
  },
> {
  removeAttachment(object: T, attachmentId: string | number): T {
    return {
      ...object,
      attachments: (object?.attachments ?? []).filter((attachmentFiltered) => attachmentFiltered.id !== attachmentId),
    };
  }

  addAttachments(object: T, attachments: Attachment[]): T {
    return {
      ...object,
      attachments: attachments.every((attachment) => object.id === attachment.modelId)
        ? [...(object?.attachments || []), ...attachments]
        : object.attachments,
    };
  }

  addSourceDocumentAttachment(object: T, sourceDocumentAttachments: SourceDocumentAttachment[]): T {
    return {
      ...object,
      sourceDocumentAttachments: [...(object?.sourceDocumentAttachments ?? []), ...sourceDocumentAttachments],
    };
  }

  addVendorInvoices(object: T, attachments: Attachment[]): T {
    return {
      ...object,
      vendorInvoices: [...(object?.vendorInvoices || []), ...attachments],
    };
  }

  addAttachmentAfterPreviousIds(object: T, attachment: Attachment, previousIds: (number | string)[]): T {
    let index = 0;
    const attachments: Attachment[] = object?.attachments ?? [];
    for (const previousId of previousIds) {
      const previousAttachment: Attachment | undefined = attachments.find(
        (attachmentIterated) => attachmentIterated.id === previousId,
      );
      if (previousAttachment) {
        index = indexOf(attachments, previousAttachment) + 1;
        break;
      }
    }
    attachments.splice(index, 0, attachment);
    return {
      ...object,
      attachments,
    };
  }

  replaceAttachment(object: T, attachment: Attachment, uuid: string): T {
    return {
      ...object,
      attachments: (object?.attachments ?? []).map((attachmentIterate) =>
        attachmentIterate.id === uuid
          ? {
              ...attachmentIterate,
              ...attachment,
              progress: undefined,
            }
          : attachmentIterate,
      ),
    };
  }

  replaceVendorInvoice(object: T, attachment: Attachment, uuid: string): T {
    return {
      ...object,
      vendorInvoices: (object?.vendorInvoices ?? []).map((attachmentIterate) =>
        attachmentIterate.id === uuid
          ? {
              ...attachment,
              id: attachment?.metadata?.externalId,
              progress: undefined,
              hasBeenUploadedNow: attachmentIterate?.hasBeenUploadedNow,
            }
          : attachmentIterate,
      ),
    };
  }

  getPreviousAttachmentsIds(attachments: Attachment[], attachment: Attachment) {
    const index: number = indexOf(attachments, attachment);
    const previousAttachmentsIds: (number | string)[] = [];
    for (let i = 0; i < index; i++) {
      const id: number | string = attachments[i].id;
      previousAttachmentsIds.push(id);
    }
    return previousAttachmentsIds.reverse();
  }

  tagHasBeenUploadedNowToSourceDocumentAttachments(
    object: T,
    temporarySourceDocumentAttachments: SourceDocumentAttachment[],
  ): T {
    return {
      ...object,
      sourceDocumentAttachments: [
        ...(object?.sourceDocumentAttachments ?? []).map((sda) =>
          temporarySourceDocumentAttachments.some(
            (tmpSda) => tmpSda.sourceDocument?.tempUuid === sda.sourceDocument?.tempUuid,
          )
            ? {
                ...sda,
                sourceDocument: { ...sda.sourceDocument, hasBeenUploadedNow: true },
              }
            : sda,
        ),
      ],
    };
  }
}
