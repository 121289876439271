<dougs-dropdown
  [isDatepicker]="true"
  [widthToRefElement]="false"
  [displayArrow]="false"
  *ngIf="reference"
  #dropdownDatepicker
  [reference]="reference?.nativeElement"
>
  <div
    *ngIf="{
      view: datepickerComponentService.view$ | async,
      days: datepickerComponentService.days$ | async,
      months: datepickerComponentService.months$ | async,
      years: datepickerComponentService.years$ | async,
      selectedDate: datepickerComponentService.selectedDate$ | async
    } as pickerData"
    class="datepicker"
  >
    <div class="px-24 pb-16">
      <div
        class="datepicker__navigation mt-16 mb-16"
        [ngClass]="{ 'datepicker__navigation--centered': pickerData.view === 'months' }"
      >
        <i
          *ngIf="pickerData.view !== 'months'"
          (click)="datepickerComponentService.previous(pickerData.view)"
          class="datepicker__navigation__arrow fal fa-chevron-left"
        ></i>
        <h5 class="datepicker__navigation__title" (click)="datepickerComponentService.toggleView('years')">
          {{ datepickerComponentService.title$ | async | titlecase }}
          <i class="fal fa-chevron-down"></i>
        </h5>
        <i
          *ngIf="pickerData.view !== 'months'"
          (click)="datepickerComponentService.next(pickerData.view)"
          class="datepicker__navigation__arrow fal fa-chevron-right"
        ></i>
      </div>
      <div class="datepicker__day-names" *ngIf="pickerData.view === 'days'">
        <span class="datepicker__day-names__label" *ngFor="let day of datepickerComponentService.dayNames">{{
          day | titlecase
        }}</span>
      </div>
      <div class="datepicker__days" *ngIf="pickerData.view === 'days'">
        <span
          (click)="day.isSelectable && datepickerComponentService.setDate(day.date)"
          class="datepicker__days__label"
          *ngFor="let day of pickerData.days; let i = index"
          [ngClass]="{
            'datepicker__days__label--is-not-in-month': !day.inThisMonth,
            'datepicker__days__label--is-today': day.isToday,
            'datepicker__days__label--is-selected': day.isSelected,
            'is-disabled': !day.isSelectable
          }"
        >
          {{ day.day }}
        </span>
      </div>
      <div class="datepicker__years" *ngIf="pickerData.view === 'years'">
        <span
          (click)="datepickerComponentService.setYear(year.year)"
          class="datepicker__years__label"
          *ngFor="let year of pickerData.years; let i = index"
          [ngClass]="{
            'datepicker__years__label--is-selected': year.isSelected,
            'datepicker__years__label--is-this-year': year.isThisYear
          }"
        >
          {{ year.year }}
        </span>
      </div>
      <div class="datepicker__months" *ngIf="pickerData.view === 'months'">
        <span
          (click)="datepickerComponentService.setMonth(month.month)"
          class="datepicker__months__label"
          *ngFor="let month of pickerData.months; let i = index; trackBy: 'month' | trackBy"
          [ngClass]="{
            'datepicker__months__label--is-selected': month.isSelected,
            'datepicker__months__label--is-this-year': month.isThisMonth
          }"
        >
          {{ month.label | titlecase }}
        </span>
      </div>
      <div class="datepicker__pills mt-8" *ngIf="datePills?.length">
        <dougs-pill
          [ngStyle]="{ 'max-width': datePills.length | getDatePillMaxWidth }"
          *ngFor="let pill of datePills; trackBy: 'date' | trackBy"
          [isSelected]="pill.date | isSameDay: pickerData.selectedDate"
          type="primary"
          class="pointer"
          (click)="datepickerComponentService.setDate(pill.date)"
        >
          <span class="datepicker__pills__label">
            {{ pill.label }}
          </span>
        </dougs-pill>
      </div>
    </div>
  </div>
</dougs-dropdown>
