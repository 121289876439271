import { millisecondsInSecond } from "./constants.mjs";

/**
 * @name secondsToMilliseconds
 * @category Conversion Helpers
 * @summary Convert seconds to milliseconds.
 *
 * @description
 * Convert a number of seconds to a full number of milliseconds.
 *
 * @param seconds - The number of seconds to be converted
 *
 * @returns The number of seconds converted in milliseconds
 *
 * @example
 * // Convert 2 seconds into milliseconds
 * const result = secondsToMilliseconds(2)
 * //=> 2000
 */
export function secondsToMilliseconds(seconds) {
  return seconds * millisecondsInSecond;
}

// Fallback for modularized imports:
export default secondsToMilliseconds;