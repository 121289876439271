import { Pipe } from '@angular/core';
import { MimeTypesEnum } from '../dto/file-preview.dto';

@Pipe({
  name: 'classIcon',
  standalone: true,
})
export class ClassIconPipe {
  transform(mimeType: string | null): string {
    switch (mimeType) {
      case MimeTypesEnum.DOC:
      case MimeTypesEnum.DOCX:
        return 'fal fa-file-word';
      case MimeTypesEnum.PDF:
        return 'fal fa-file-pdf';
      case MimeTypesEnum.PNG:
      case MimeTypesEnum.JPG:
      case MimeTypesEnum.JPEG:
        return 'fal fa-file-image';
      case MimeTypesEnum.XLS:
      case MimeTypesEnum.XLSX:
        return 'fal fa-file-excel';
      case MimeTypesEnum.ZIP:
        return 'fal fa-file-archive';
      default:
        return 'fal fa-file';
    }
  }
}
