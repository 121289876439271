import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StepStatus } from '../step-status.enum';
import { StepperStatusClassPipe } from '../stepper-status-class.pipe';

@Component({
  selector: 'dougs-stepper-icon',
  standalone: true,
  imports: [CommonModule, StepperStatusClassPipe],
  templateUrl: './stepper-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('dashoffset', [
      transition(':enter', [
        style({ strokeDashoffset: '16px' }),
        animate('200ms 100ms ease-in-out', style({ strokeDashoffset: 0 })),
      ]),
      transition(':leave', [
        style({ strokeDashoffset: 0 }),
        animate('200ms ease-in-out', style({ strokeDashoffset: '16px' })),
      ]),
    ]),
  ],
})
export class StepperIconComponent {
  @Input() stepStatus!: StepStatus;
  protected readonly StepStatus = StepStatus;
}
