<dougs-form-field [noMargin]="noMargin" [autoWidth]="autoWidth" [size]="size">
  <label *ngIf="label" [id]="labelId" [isAdmin]="isAdmin" dougsFormFieldLabel [for]="id" [innerHTML]="label"></label>
  <input
    #inputElement
    [dougsDatepicker]="dateDatepicker"
    [useDefaultTaskPill]="useDefaultTaskPill"
    [customDatePills]="customDatePills"
    mask="00/00/0000"
    [placeholder]="placeholder || 'jj/mm/aaaa'"
    [(ngModel)]="value"
    [id]="id"
    type="text"
    [disabled]="isDisabled"
    dougsFormFieldControl
    (blur)="onBlur()"
    (chooseValue)="onBlur()"
  />
  <i (click)="openDatepicker()" dougsFormFieldSuffix class="fal fa-calendar"></i>
  <span *ngIf="formService.isControlInvalid(control.control)" dougsFormFieldError>
    <span *ngIf="control.control.hasError('required')">Ce champ est requis</span>
    <span *ngIf="control.control.hasError('invalidDate')">La date doit être au format jj/mm/aaaa</span>
  </span>
  <dougs-datepicker [options]="options" #dateDatepicker></dougs-datepicker>
</dougs-form-field>
