import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ConfigBackService, CurrencyISOCode } from '@dougs/core/config-back';
import { CurrencyClassPipe, TrackByPipe } from '../../../pipes';
import { DropdownComponent, DropdownOptionComponent, DropdownTriggerDirective } from '../../dropdown';
import { ControlFormFieldDirective } from '../control.directive';
import { SuffixFormFieldDirective } from '../suffix.directive';

@Component({
  selector: 'dougs-currency-suffix',
  standalone: true,
  imports: [
    CommonModule,
    SuffixFormFieldDirective,
    CurrencyClassPipe,
    DropdownComponent,
    ControlFormFieldDirective,
    FormsModule,
    ReactiveFormsModule,
    TrackByPipe,
    DropdownOptionComponent,
    DropdownTriggerDirective,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CurrencySuffixComponent,
      multi: true,
    },
  ],
  templateUrl: './currency-suffix.component.html',
  styleUrls: ['./currency-suffix.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencySuffixComponent implements ControlValueAccessor {
  @Input() disabledDropdown = false;

  private disabled = false;
  protected readonly CurrencyISOCode = CurrencyISOCode;
  protected isDropdownOpened = false;
  private _value?: CurrencyISOCode;

  set value(currency: CurrencyISOCode) {
    this._value = currency;
    if (this.propagateChange) {
      this.propagateChange(currency);
      this.onTouched();
    }
    this.cdr.markForCheck();
  }

  get value(): CurrencyISOCode | undefined {
    return this._value;
  }

  constructor(
    public configBackService: ConfigBackService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  propagateChange: (value: CurrencyISOCode) => void = () => void 0;

  onTouched: () => void = () => true;

  registerOnChange(fn: (value: CurrencyISOCode) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(currency: CurrencyISOCode): void {
    this._value = currency;
    this.cdr.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}
