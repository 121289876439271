import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';

@Pipe({
  name: 'formatDistanceToNow',
  standalone: true,
})
export class FormatDistanceToNowPipe implements PipeTransform {
  transform(date: Date | string, addSuffix = false): string {
    const dateToTransform = typeof date === 'string' ? new Date(date) : date;

    return formatDistanceToNow(dateToTransform, {
      locale: fr,
      addSuffix,
    });
  }
}
