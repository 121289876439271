import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[dougsCardHeader]',
  standalone: true,
})
export class CardHeaderDirective {
  @HostBinding('class.card__header')
  cardHeader = true;
}
