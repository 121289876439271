import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { DropdownComponent } from './dropdown.component';

@Directive({
  selector: '[dougsDropdownTrigger]',
  standalone: true,
})
export class DropdownTriggerDirective {
  constructor(private readonly elementRef: ElementRef) {}

  @Input('dougsDropdownTrigger') dropdown!: DropdownComponent;
  @Input('dougsDropdownDisabled') disabled = false;

  @HostBinding('style.cursor') cursor = 'pointer';

  @HostListener('click', ['$event'])
  onClick(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    if (!this.disabled) {
      if (this.dropdown) {
        this.dropdown.reference = this.elementRef.nativeElement;
        this.dropdown.show();
      } else {
        throw Error('Dropdown need to be initialized');
      }
    }
  }
}
