import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'avatarName',
  standalone: true,
})
export class AvatarNamePipe implements PipeTransform {
  transform(
    value: { name?: string; fullName?: string; avatarUrl?: string },
    defaultName = 'Sarah Jaouani, Responsable Juridique',
  ): string {
    const name = value?.name || value?.fullName;
    return name && value?.avatarUrl ? name : defaultName;
  }
}
