<div dougsModalTitle>
  <h6>Envoyer un SMS</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <dougs-form-field [noMargin]="true">
    <textarea placeholder="Votre message" maxlength="160" dougsFormFieldControl [formControl]="message"></textarea>
    <span dougsFormFieldError *ngIf="formService.isControlInvalid(message)"></span>
  </dougs-form-field>
  <p class="tiny">Caractères restants {{ message.value.length }} / 160</p>
</div>
<div dougsModalFooter>
  <dougs-button dougsModalClose color="secondary">Annuler</dougs-button>
  <dougs-button (click)="submit()">Envoyer</dougs-button>
</div>
