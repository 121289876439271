<div class="dougs-stepper-icon-container" [ngClass]="{ 'no-border': stepStatus === StepStatus.PENDING }">
  <svg *ngIf="stepStatus === StepStatus.SUCCESS" width="12px" height="9px" viewbox="0 0 12 9" [@dashoffset]>
    <polyline points="1 5 4 8 11 1"></polyline>
  </svg>
  <ng-container *ngIf="stepStatus === StepStatus.ERROR || stepStatus === StepStatus.INELIGIBLE">
    <svg width="10" height="10" viewBox="0 0 10 10" [@dashoffset]>
      <polyline points="9 1 1 9"></polyline>
    </svg>
    <svg width="10" height="10" viewBox="0 0 10 10" [@dashoffset]>
      <polyline points="1 1 9 9"></polyline>
    </svg>
  </ng-container>
  <ng-container *ngIf="stepStatus === StepStatus.TOOEARLY">
    <i class="far fa-clock too-early"></i>
  </ng-container>
  <div class="stepper-loader" *ngIf="stepStatus === StepStatus.PENDING">
    <span class="stepper-loader__loader"></span>
  </div>
</div>
