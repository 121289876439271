import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { PanelInfoType } from './panel-info.type';

@Component({
  selector: 'dougs-panel-info',
  templateUrl: './panel-info.component.html',
  styleUrls: ['./panel-info.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelInfoComponent {
  @HostBinding('class')
  @Input()
  type: PanelInfoType = 'info';

  @HostBinding('class.small')
  @Input()
  small = false;

  @HostBinding('class.center')
  @Input()
  center = false;
}
