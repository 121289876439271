import { setISOWeek } from "../../../setISOWeek.mjs";
import { startOfISOWeek } from "../../../startOfISOWeek.mjs";
import { numericPatterns } from "../constants.mjs";
import { Parser } from "../Parser.mjs";
import { parseNDigits, parseNumericPattern } from "../utils.mjs";

// ISO week of year
export class ISOWeekParser extends Parser {
  priority = 100;
  parse(dateString, token, match) {
    switch (token) {
      case "I":
        return parseNumericPattern(numericPatterns.week, dateString);
      case "Io":
        return match.ordinalNumber(dateString, {
          unit: "week"
        });
      default:
        return parseNDigits(token.length, dateString);
    }
  }
  validate(_date, value) {
    return value >= 1 && value <= 53;
  }
  set(date, _flags, value) {
    return startOfISOWeek(setISOWeek(date, value));
  }
  incompatibleTokens = ["y", "Y", "u", "q", "Q", "M", "L", "w", "d", "D", "e", "c", "t", "T"];
}