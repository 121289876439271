import { Injectable } from '@angular/core';
import {
  addDays,
  differenceInDays,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDate,
  getDay,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  isSameYear,
  isToday,
  isWithinInterval,
  setMonth,
  startOfDay,
  startOfMonth,
  subDays,
} from 'date-fns';
import { fr } from 'date-fns/locale/fr';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatepickerCommonService, Month } from '../datepicker-common.service';

export interface Day {
  date: Date;
  day: number;
  month: number;
  year: number;
  inThisMonth: boolean;
  isToday: boolean;
  isStartDate: boolean;
  isEndDate: boolean;
  isSelectable: boolean;
  isWithinRange: boolean;
  isPreselected: boolean;
}

export enum RangeMode {
  RANGE,
  FROM,
  UNTIL,
}

export interface DateRangePickerOutput {
  from: Date | null;
  to: Date | null;
}

@Injectable()
export class DateRangePickerComponentService extends DatepickerCommonService {
  private readonly _startDateSubject: BehaviorSubject<Date | null> = new BehaviorSubject<Date | null>(null);
  private readonly _endDateSubject: BehaviorSubject<Date | null> = new BehaviorSubject<Date | null>(null);
  private readonly _modeSubject: BehaviorSubject<RangeMode> = new BehaviorSubject<RangeMode>(RangeMode.RANGE);
  private readonly _hoveredDateSubject: BehaviorSubject<Date | null> = new BehaviorSubject<Date | null>(null);

  days$: Observable<Day[]> = combineLatest([
    this._currentDateSubject,
    this._modeSubject,
    this._hoveredDateSubject,
    this._viewSubject,
  ]).pipe(
    map(([currentDate, , , view]) => {
      return view === 'days' ? this.initDays(currentDate) : [];
    }),
  );

  mode$: Observable<RangeMode> = this._modeSubject.asObservable();

  isValid$: Observable<boolean> = combineLatest([this._startDateSubject, this._endDateSubject, this._modeSubject]).pipe(
    map(([startDate, endDate, mode]) => {
      return (
        (mode === RangeMode.RANGE && !!startDate && !!endDate) ||
        (mode === RangeMode.FROM && !!startDate) ||
        (mode === RangeMode.UNTIL && !!endDate)
      );
    }),
  );

  onRangeChange: Subject<DateRangePickerOutput> = new Subject<DateRangePickerOutput>();

  get startDate(): Date | null {
    return this._startDateSubject.value;
  }

  get endDate(): Date | null {
    return this._endDateSubject.value;
  }

  setRange(startDate: Date | null, endDate: Date | null): void {
    this._currentDateSubject.next(startDate || endDate || new Date());
    this._startDateSubject.next(startDate);
    this._endDateSubject.next(endDate);
    this._modeSubject.next(startDate && endDate ? RangeMode.RANGE : !endDate ? RangeMode.FROM : RangeMode.UNTIL);
  }

  choseDate(date: Date): void {
    switch (this._modeSubject.value) {
      case RangeMode.RANGE:
        if (this.startDate) {
          if (isBefore(date, this.startDate)) {
            this._startDateSubject.next(date);
            this._endDateSubject.next(null);
          } else {
            this._endDateSubject.next(date);
          }
        } else {
          this._startDateSubject.next(date);
        }
        this._hoveredDateSubject.next(null);
        break;
      case RangeMode.FROM:
        this._startDateSubject.next(date);
        break;
      case RangeMode.UNTIL:
        this._endDateSubject.next(date);
        break;
    }

    this._currentDateSubject.next(date);
  }

  submit(): void {
    this.onRangeChange.next({
      from: this.startDate,
      to: this.endDate,
    });
  }

  setMode(newMode: RangeMode): void {
    if (newMode !== this._modeSubject.value) {
      if (this._modeSubject.value === RangeMode.RANGE || newMode === RangeMode.RANGE) {
        this._startDateSubject.next(null);
        this._endDateSubject.next(null);
      }

      if (this._modeSubject.value === RangeMode.FROM && newMode === RangeMode.UNTIL) {
        this._endDateSubject.next(this.startDate);
        this._startDateSubject.next(null);
      }

      if (this._modeSubject.value === RangeMode.UNTIL && newMode === RangeMode.FROM) {
        this._startDateSubject.next(this.endDate);
        this._endDateSubject.next(null);
      }

      this._modeSubject.next(newMode);
    }
  }

  reset(): void {
    this._startDateSubject.next(null);
    this._endDateSubject.next(null);
    this.onRangeChange.next({
      from: null,
      to: null,
    });
    this._modeSubject.next(RangeMode.RANGE);
    this._currentDateSubject.next(new Date());
    this._yearRangeSubject.next(0);
    this._viewSubject.next('days');
  }

  startHoveringDay(day: Day): void {
    if (
      this._modeSubject.value === RangeMode.RANGE &&
      this.startDate &&
      !this.endDate &&
      isAfter(day.date, this.startDate)
    ) {
      this._hoveredDateSubject.next(day.date);
    }
  }

  stopHoveringDay(): void {
    if (this._hoveredDateSubject.value) {
      this._hoveredDateSubject.next(null);
    }
  }

  trackById(id: number): number {
    return id;
  }

  private initDays(currentDate: Date): Day[] {
    const [start, end] = [startOfMonth(currentDate), endOfMonth(currentDate)];

    const days: Day[] = eachDayOfInterval({ start, end }).map((d: Date) =>
      this.generateDay(d, this.startDate, this.endDate),
    );

    const tmp: number = getDay(start) - 1;
    const prevDays: number = tmp < 0 ? 7 - 1 : tmp;
    for (let i = 1; i <= prevDays; i++) {
      days.unshift(this.generateDay(subDays(start, i), this.startDate, this.endDate, false));
    }

    return days;
  }

  initMonths(): Month[] {
    const months: Month[] = [];
    for (let i = 0; i <= 11; i++) {
      months.push({
        month: i,
        isSelected: this.isMonthSelected(i),
        isThisMonth: i === getMonth(new Date()),
        label: format(setMonth(new Date(), i), 'MMM', {
          locale: fr,
        }).slice(0, -1),
      });
    }
    return months;
  }

  isMonthSelected(monthIndex: number): boolean {
    const isStartDateMonthSelected: boolean =
      !!this.startDate &&
      monthIndex === getMonth(this.startDate) &&
      isSameYear(this.startDate, this._currentDateSubject.value);
    const isEndDateMonthSelected: boolean =
      !!this.endDate &&
      monthIndex === getMonth(this.endDate) &&
      isSameYear(this.endDate, this._currentDateSubject.value);
    return isStartDateMonthSelected || isEndDateMonthSelected;
  }

  isYearSelected(year: number): boolean {
    return year === (this.startDate && getYear(this.startDate)) || year === (this.endDate && getYear(this.endDate));
  }

  private generateDay(date: Date, startDate: Date | null, endDate: Date | null, inThisMonth = true): Day {
    return {
      date,
      day: getDate(date),
      month: getMonth(date),
      year: getYear(date),
      inThisMonth,
      isToday: isToday(date),
      isStartDate: !!startDate && isEqual(date, startDate),
      isEndDate: !!endDate && isEqual(date, endDate),
      isSelectable: this.isDateSelectable(date),
      isWithinRange: this.isDateInInterval(date),
      isPreselected: this.isDatePreselected(date),
    };
  }

  private isDateInInterval(date: Date): boolean {
    if (this.startDate && this.endDate) {
      return differenceInDays(this.endDate, this.startDate) > 1
        ? isWithinInterval(date, {
            start: addDays(this.startDate, 1),
            end: subDays(this.endDate, 1),
          })
        : false;
    } else if (this.startDate && this._modeSubject.value !== RangeMode.RANGE) {
      return isAfter(date, this.startDate);
    } else if (this.endDate && this._modeSubject.value !== RangeMode.RANGE) {
      return isBefore(date, this.endDate);
    }
    return false;
  }

  private isDateSelectable(date: Date): boolean {
    if (
      this.options.minDate &&
      !isEqual(startOfDay(date), startOfDay(this.options.minDate)) &&
      isBefore(date, this.options.minDate)
    ) {
      return false;
    }

    return !(this.options.maxDate && isAfter(date, this.options.maxDate));
  }

  private isDatePreselected(date: Date): boolean {
    return (
      !!this.startDate &&
      !!this._hoveredDateSubject.value &&
      isAfter(date, this.startDate) &&
      !isAfter(date, this._hoveredDateSubject.value)
    );
  }
}
