import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import { ButtonColor } from '../../dto';

@Component({
  selector: 'dougs-button, [dougsButton]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  imports: [CommonModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() type: 'button' | 'submit' = 'button';
  @Input() color: ButtonColor = 'primary';
  @Input() disabled = false;
  @Input() round = false;
  @Input() size: 'small' | 'medium' | 'large' | 'large-narrow' = 'medium';
  @Input() noWrap = false;

  @HostBinding('class.full-width')
  @Input()
  fullWidth = false;

  @ViewChild('buttonElement')
  buttonElement!: ElementRef;

  constructor(private readonly elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.buttonElement && !!this.elementRef.nativeElement.querySelectorAll('a').length) {
      (this.buttonElement.nativeElement as HTMLButtonElement).classList.add('no-padding');
    }
  }
}
