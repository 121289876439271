<div *ngIf="textLeft">
  <ng-container *ngTemplateOutlet="textTemplate"></ng-container>
</div>
<div class="ring-progress" [ngStyle]="{ width: size + 'px', height: size + 'px' }">
  <svg class="ring-progress__svg">
    <circle
      [@ringProgress]="{ value: undefined, params: { circumferenceProgress } }"
      [ngStyle]="{
        'stroke-dasharray': circumferenceProgress,
        'stroke-dashoffset': strokeProgress,
        'stroke-width': strokeWidth
      }"
      [ngClass]="['ring-progress__svg__circle__' + strokeColor, isAnimated ? 'is-animated' : '']"
      class="ring-progress__svg__circle"
      [attr.r]="size / 2 - strokeWidth"
      [attr.cx]="size / 2"
      [attr.cy]="size / 2"
    />
  </svg>
  <svg class="ring-progress__svg">
    <circle
      [ngStyle]="{
        'stroke-dasharray': circumferenceProgress,
        'stroke-width': strokeWidth
      }"
      class="ring-progress__svg__circle ring-progress__svg__circle__background"
      [attr.r]="size / 2 - strokeWidth"
      [attr.cx]="size / 2"
      [attr.cy]="size / 2"
    />
  </svg>
</div>
<div *ngIf="!textLeft">
  <ng-container *ngTemplateOutlet="textTemplate"></ng-container>
</div>
<ng-template #textTemplate>
  <div class="ring-progress__text" [ngClass]="'color-' + strokeColor">
    <ng-content></ng-content>
  </div>
</ng-template>
