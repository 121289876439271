import { Pipe, PipeTransform } from '@angular/core';
import { CompanyPill, LabelsLevel } from '@dougs/user/dto';
import { Pill } from '../components';

@Pipe({
  name: 'userLabelPill',
  standalone: true,
})
export class UserLabelPillPipe implements PipeTransform {
  transform(label: CompanyPill): Pill {
    switch (label.level) {
      case LabelsLevel.ALERT:
        return 'error';
      case LabelsLevel.SUCCESS:
        return 'success';
      case LabelsLevel.INFO:
        return 'primary';
      case LabelsLevel.WARN:
        return 'warning';
      default:
        return 'secondary';
    }
  }
}
