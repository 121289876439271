<div class="accordion">
  <div
    [id]="accordionId"
    class="accordion__header px-24 py-16"
    (mouseenter)="headerHovered = true"
    (mouseleave)="headerHovered = false"
    [ngClass]="{ 'accordion__header--opened': !animationLeaveDone, pointer: !isLocked, 'is-admin': isAdmin }"
    (click)="handleAccordionClick()"
  >
    <div class="accordion__header__left">
      <div *ngIf="image || icon" class="accordion__header__left__icon-container" [ngClass]="[iconBackgroundColor]">
        <i
          *ngIf="icon && !image"
          class="fal"
          [ngClass]="icon"
          [class.color-primary]="!disableActiveIcon && (isOpen || headerHovered) && !isAdmin"
          [class.color-admin]="!disableActiveIcon && (isOpen || headerHovered) && isAdmin"
        ></i>
        <img *ngIf="image" [src]="image" alt="Image" />
      </div>
      <div class="min-width-0">
        <ng-content select="dougs-accordion-title"></ng-content>
        <ng-content select="dougs-accordion-description"></ng-content>
      </div>
    </div>
    <i *ngIf="!isLocked" class="fal fa-chevron-down" [class.rotate]="isOpen"></i>
  </div>
  <div
    class="accordion__body px-24"
    @slideInOut
    (@slideInOut.start)="animationStarted($event)"
    (@slideInOut.done)="animationDone($event)"
    *ngIf="isOpen"
  >
    <div class="py-16">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="accordion__footer px-24 py-16" *ngIf="isOpen && showFooter" @slideInOut>
    <ng-content select="dougs-accordion-footer"></ng-content>
  </div>
</div>
