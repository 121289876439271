import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core';
import { StepStatusDirective } from '../step-status.directive';
import { StepStatus } from '../step-status.enum';
import { StepperIconComponent } from '../stepper-icon/stepper-icon.component';
import { StepperStatusClassPipe } from '../stepper-status-class.pipe';
import { stepperAnimations } from '../stepper.animation';

@Component({
  selector: 'dougs-step',
  standalone: true,
  imports: [CommonModule, StepperStatusClassPipe, StepperIconComponent],
  templateUrl: './step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [stepperAnimations],
})
export class StepComponent {
  @Input() status!: StepStatus;
  @Input() isLast!: boolean;

  showStepStatus = true;

  @ContentChild(StepStatusDirective, { static: false })
  set contentChild(stepStatusDirective: StepStatusDirective) {
    this.showStepStatus = !!stepStatusDirective;
  }
}
