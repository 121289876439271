export class FlashMessage {
  content: string | HTMLElement = '';
  options: FlashMessageOptions = {
    type: 'info',
    timeout: 1500,
    canClose: true,
    reloadLink: false,
  };
  timeoutId?: number;

  constructor(content: string | HTMLElement, options?: FlashMessageOptions) {
    this.content = content;
    this.options = { ...this.options, ...options };
  }
}

export type FlashMessageOptions = {
  type?: FlashType;
  timeout?: number;
  canClose?: boolean;
  reloadLink?: boolean;
  onClick?: () => void;
};

export type FlashType = 'info' | 'pending' | 'error' | 'warning' | 'success';

export type LegacyPushMessage = {
  type: FlashType;
  content: string;
  options?: {
    timeout?: number;
    canClose?: boolean;
    reloadLink?: boolean;
  };
};
