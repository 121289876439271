<ng-container *ngIf="!disableRouting; else openFile">
  <a
    [ngClass]="{ 'disable-link': !salesInvoiceSourceDocumentAttachment?.sourceDocument?.externalId }"
    [routerLink]="[]"
    [queryParams]="{ salesInvoiceId: salesInvoiceSourceDocumentAttachment.sourceDocument.externalId }"
  >
    <ng-container [ngTemplateOutlet]="filePillContent"></ng-container>
  </a>
</ng-container>
<ng-template #openFile>
  <a [ngClass]="{ 'disable-link': !salesInvoiceAttachment?.url }" [href]="salesInvoiceAttachment?.url" target="_blank">
    <ng-container [ngTemplateOutlet]="filePillContent"></ng-container>
  </a>
</ng-template>

<ng-template #filePillContent>
  <div class="file-pill">
    <div
      class="file-pill__content"
      [ngClass]="{
        'file-pill__content--no-delete': !canDelete
      }"
    >
      <i
        @scaleIn
        [@.disabled]="!salesInvoiceAttachment.hasBeenUploadedNow || !enableAnimation"
        *ngIf="!salesInvoiceAttachment.progress"
        class="self-center fal fa-file-invoice"
      ></i>
      <dougs-loader
        *ngIf="salesInvoiceAttachment.progress"
        @slideOut
        class="self-center"
        appearance="spin"
      ></dougs-loader>
      <span class="filename">{{ salesInvoiceAttachment.name }}</span>
      <i
        @slideInFromSide
        [@.disabled]="!salesInvoiceAttachment.hasBeenUploadedNow || !enableAnimation"
        *ngIf="!salesInvoiceAttachment?.progress && canDelete"
        class="fal fa-times delete-file"
        (click)="confirmDeleteFile($event)"
      ></i>
    </div>
  </div>
</ng-template>
