import { FocusableOption } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { DropdownComponent } from './dropdown.component';

@Component({
  selector: 'dougs-dropdown-option',
  template: '<ng-content></ng-content>',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownOptionComponent implements FocusableOption {
  constructor(
    private readonly parent: DropdownComponent,
    private readonly element: ElementRef,
  ) {}

  @Input() closeOnClick = false;
  @Input()
  @HostBinding('class.disabled')
  disabled = false;
  @Input() value?: any;

  @HostBinding('class.dropdown-option')
  private readonly classDropdown = true;

  @HostListener('click')
  onClick(): void {
    if (this.closeOnClick && !this.disabled) {
      this.parent.hide();
    }
  }

  focus(): void {
    this.element.nativeElement.scrollIntoView();
  }
}
