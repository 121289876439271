import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Injector,
  INJECTOR,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { format, parse } from 'date-fns';
import { NgxMaskDirective } from 'ngx-mask';
import { FormService } from '@dougs/core/form';
import { generateUuidV4, isValidBackDate, isValidFrDate } from '@dougs/core/utils';
import {
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  PrefixFormFieldDirective,
  SuffixFormFieldDirective,
} from '../form-field';
import { DatePill } from '../pill';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DatepickerDirective } from './datepicker/datepicker.directive';

@Component({
  selector: 'dougs-input-datepicker',
  templateUrl: './input-datepicker.component.html',
  styleUrls: ['./input-datepicker.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DatepickerComponent,
    DatepickerDirective,
    FormFieldComponent,
    ControlFormFieldDirective,
    ErrorFormFieldDirective,
    LabelFormFieldDirective,
    PrefixFormFieldDirective,
    SuffixFormFieldDirective,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskDirective,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputDatepickerComponent,
      multi: true,
    },
    { provide: NG_VALIDATORS, useExisting: InputDatepickerComponent, multi: true },
  ],
})
export class InputDatepickerComponent implements ControlValueAccessor, OnInit, Validator {
  @Input() label = 'Date';
  @Input() placeholder?: string;
  @Input() noMargin = false;

  @HostBinding('class.auto-width')
  @Input()
  autoWidth = false;
  @Input() noBorder = false;
  @Input() isAdmin = false;
  @Input() options: { minDate?: Date | null; maxDate?: Date | null; formatTitle?: string } = {
    minDate: null,
    maxDate: null,
    formatTitle: 'LLLL yyyy',
  };
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() useDefaultTaskPill = false;
  @Input() customDatePills?: DatePill[];
  @Input() labelId = generateUuidV4();

  @ViewChild('inputElement')
  inputElement!: ElementRef;

  isDisabled = false;
  control!: NgControl;
  id = generateUuidV4();
  onTouched!: () => void;
  propagateChange!: (value: string | null) => void;

  constructor(
    public formService: FormService,
    @Inject(INJECTOR) private readonly injector: Injector,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  private _value = '';

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    if (value !== this.value) {
      this._value = value;

      if (value) {
        if (isValidFrDate(value)) {
          const formatDate = format(parse(value, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
          this.propagateChange(formatDate);
        } else {
          this.propagateChange(value);
        }
      } else {
        this.propagateChange(null);
      }
    }
  }

  ngOnInit(): void {
    this.control = this.injector.get(NgControl);
  }

  validate(control: AbstractControl | null): ValidationErrors | null {
    return (control?.dirty || control?.touched) && this._value && !isValidFrDate(this._value)
      ? {
          invalidDate: { value: this._value },
        }
      : null;
  }

  openDatepicker(): void {
    if (this.inputElement) {
      (this.inputElement.nativeElement as HTMLInputElement).click();
    }
  }

  onBlur(): void {
    this.onTouched();
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(date: Date | string): void {
    const dateTZ: Date = new Date(date);
    const dateNoTZ: Date = new Date(dateTZ.valueOf() + dateTZ.getTimezoneOffset() * 60 * 1000);
    this._value = date && isValidBackDate(date) ? format(dateNoTZ, 'dd/MM/yyyy') : '';
    this.cdr.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cdr.markForCheck();
  }
}
