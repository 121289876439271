@if (displayManualEntryInfo) {
  <dougs-panel-info class="mb-16"
    >L'absence de votre adresse a été notifiée. Un juriste prendra en compte votre demande pour la création de votre
    société.
  </dougs-panel-info>
}
<dougs-form-field [noMargin]="true">
  <label dougsFormFieldLabel>Adresse</label>
  @if (isAccountantOrAdmin) {
    <i dougsFormFieldPrefix class="fal fa-copy color-admin" [dougsCopyToClipboard]="fullAddress"></i>
  }
  <input
    [(ngModel)]="fullAddress"
    (ngModelChange)="invalidateAddress()"
    placeholder="Adresse"
    [disabled]="isDisabled"
    dougsAddressAutocomplete
    [showAddressAutocomplete]="true"
    [useAddressContext]="true"
    [showAddressNotListedOption]="true"
    [addressFieldsToDisplay]="addressFieldsToDisplay"
    (selectAddress)="onSelectAddress($event)"
    (manualAddressEntry)="onManualAddressEntry($event)"
    dougsFormFieldControl
    type="text"
    (blur)="onTouched()"
  />
  <span *ngIf="control?.touched && !hasValidAddress" dougsFormFieldError>
    <span>Impossible de vérifier votre adresse. Sélectionnez une adresse suggérée avant de l'enregistrer.</span>
  </span>
</dougs-form-field>
