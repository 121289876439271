import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  FormFieldComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '../../components';

@Component({
  selector: 'dougs-sms-modal',
  templateUrl: './sms-modal.component.html',
  styleUrls: ['./sms-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalContentDirective,
    ModalCloseDirective,
    ModalFooterDirective,
    ButtonComponent,
    ReactiveFormsModule,
    FormFieldComponent,
    FormsModule,
    ControlFormFieldDirective,
  ],
})
export class SmsModalComponent {
  message: FormControl = new FormControl('', [Validators.maxLength(160), Validators.required]);

  constructor(
    private readonly modalRef: ModalRef,
    public readonly formService: FormService,
  ) {}

  submit(): void {
    this.message.markAllAsTouched();
    if (this.message.valid) {
      this.modalRef.close(this.message.value);
    }
  }
}
