import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { format, isValid, parse } from 'date-fns';
import { Subscription } from 'rxjs';
import { DateRangePickerComponent } from './date-range-picker.component';
import { DateRangePickerOutput } from './date-range-picker.component.service';

@Directive({
  selector: '[dougsDateRangePicker]',
  standalone: true,
})
export class DateRangePickerDirective implements OnInit, OnDestroy {
  control!: NgControl;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly injector: Injector,
  ) {}

  private onRangeChangeSubscription!: Subscription;
  private onValueChangeSubscription!: Subscription;

  @Input() dougsDateRangePicker!: DateRangePickerComponent;
  @Input() dateFormat = 'dd/MM/yyyy';
  @Output() chooseValue: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('click')
  onClick(): void {
    this.dougsDateRangePicker.reference = this.elementRef;
    if (this.elementRef.nativeElement instanceof HTMLInputElement) {
      (this.elementRef.nativeElement as HTMLInputElement).select();
    }

    setTimeout(() => {
      this.putRangeInDateRangePickerComponent(this.control.value);
      this.dougsDateRangePicker.dropdown.show();
    });
  }

  ngOnInit(): void {
    this.control = this.injector.get(NgControl);

    this.onRangeChangeSubscription = this.dougsDateRangePicker.onRangeChange.subscribe(
      (dateRangePickerOutput: DateRangePickerOutput) => {
        this.control.control?.setValue(this.mapDateRangePickerOutputToString(dateRangePickerOutput));
        this.chooseValue.next();

        if (!!dateRangePickerOutput.from || !!dateRangePickerOutput.to) {
          if (this.dougsDateRangePicker.dropdown) {
            this.dougsDateRangePicker.dropdown.hide();
          }
        }
      },
    );

    setTimeout(() => {
      if (this.control.control) {
        this.putRangeInDateRangePickerComponent(this.control.control.value);
        this.onValueChangeSubscription = this.control.control.valueChanges.subscribe((value: string) => {
          this.putRangeInDateRangePickerComponent(value);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.onRangeChangeSubscription?.unsubscribe();
    this.onValueChangeSubscription?.unsubscribe();
  }

  putRangeInDateRangePickerComponent(value: string): void {
    let startDate: Date | null = null;
    let endDate: Date | null = null;

    if (value.startsWith('>')) {
      startDate = this.parseDate(value.substring(1));
    } else if (value.startsWith('<')) {
      endDate = this.parseDate(value.substring(1));
    } else if (value.includes('-')) {
      const dates: string[] = value.split('-');
      startDate = this.parseDate(dates[0]);
      endDate = this.parseDate(dates[1]);
    }

    if (startDate !== null || endDate !== null) {
      this.dougsDateRangePicker.setRange(startDate, endDate);
    }
  }

  private parseDate(date: string): Date | null {
    const formats: string[] = ['dd/MM/yy', 'dd/MM/yyyy', 'yyyy-MM-dd'];

    for (const format of formats.values()) {
      const parseDate: Date = parse(date, format, new Date());
      if (isValid(parseDate)) {
        return parseDate;
      }
    }

    return null;
  }

  private mapDateRangePickerOutputToString(dateRangePickerOutput: DateRangePickerOutput): string {
    if (dateRangePickerOutput.from && dateRangePickerOutput.to) {
      return (
        format(dateRangePickerOutput.from, this.dateFormat) + '-' + format(dateRangePickerOutput.to, this.dateFormat)
      );
    } else if (dateRangePickerOutput.from) {
      return '>' + format(dateRangePickerOutput.from, this.dateFormat);
    } else if (dateRangePickerOutput.to) {
      return '<' + format(dateRangePickerOutput.to as Date, this.dateFormat);
    }
    return '';
  }
}
