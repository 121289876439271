import { Directive, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[dougsAnchor]',
  standalone: true,
})
export class AnchorDirective {
  private _fragment!: string;
  private _routeSubscription!: Subscription;

  constructor(
    private readonly host: ElementRef,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this._routeSubscription = this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        this._fragment = fragment;
      }
    });
  }

  ngAfterViewInit(): void {
    if (!this._fragment) {
      return;
    }
    setTimeout(() => this.host.nativeElement.scrollIntoView({ behavior: 'smooth' }));
  }

  ngOnDestroy(): void {
    this._routeSubscription.unsubscribe();
  }
}
