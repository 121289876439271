import { startOfISOWeekYear } from "./startOfISOWeekYear.mjs";

/**
 * @name isSameISOWeekYear
 * @category ISO Week-Numbering Year Helpers
 * @summary Are the given dates in the same ISO week-numbering year?
 *
 * @description
 * Are the given dates in the same ISO week-numbering year?
 *
 * ISO week-numbering year: http://en.wikipedia.org/wiki/ISO_week_date
 *
 * @typeParam DateType - The `Date` type, the function operates on. Gets inferred from passed arguments. Allows to use extensions like [`UTCDate`](https://github.com/date-fns/utc).
 *
 * @param dateLeft - The first date to check
 * @param dateRight - The second date to check
 *
 * @returns The dates are in the same ISO week-numbering year
 *
 * @example
 * // Are 29 December 2003 and 2 January 2005 in the same ISO week-numbering year?
 * const result = isSameISOWeekYear(new Date(2003, 11, 29), new Date(2005, 0, 2))
 * //=> true
 */
export function isSameISOWeekYear(dateLeft, dateRight) {
  const dateLeftStartOfYear = startOfISOWeekYear(dateLeft);
  const dateRightStartOfYear = startOfISOWeekYear(dateRight);
  return +dateLeftStartOfYear === +dateRightStartOfYear;
}

// Fallback for modularized imports:
export default isSameISOWeekYear;