import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { ConfigBack, Department } from '../dto';

@Injectable({
  providedIn: 'root',
})
export class ConfigBackHttpService {
  constructor(private readonly http: HttpClient) {}

  getConfig(): Observable<ConfigBack> {
    return this.http.get<ConfigBack>(`${AppConfig.settings.legacyApiServerUrl}/config`);
  }

  getCollaboratorDepartments(): Observable<Department[]> {
    return this.http.get<Department[]>(`${AppConfig.settings.legacyApiServerUrl}/collaborator/departments`);
  }
}
