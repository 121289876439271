import { SurveyQuestion } from '../dto';

const getDefaults = (): SurveyQuestion => ({
  type: 'input',
});

export const getSurveyQuestionMock = (p?: Partial<SurveyQuestion>): SurveyQuestion => ({
  ...getDefaults(),
  ...p,
});
