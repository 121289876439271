import { Directive, EventEmitter, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[dougsNgInit]',
  standalone: true,
})
export class NgInitDirective implements OnInit {
  @Output('ngInit') initEvent: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {
    setTimeout(() => this.initEvent.emit(), 10);
  }
}
