// `parsePhoneNumber()` named export has been renamed to `parsePhoneNumberWithError()`.
export { parsePhoneNumberWithError, parsePhoneNumberWithError as parsePhoneNumber } from './min/exports/parsePhoneNumberWithError.js';
// `parsePhoneNumberFromString()` named export is now considered legacy:
// it has been promoted to a default export due to being too verbose.
export { parsePhoneNumber as parsePhoneNumberFromString, parsePhoneNumber as default } from './min/exports/parsePhoneNumber.js';
export { isValidPhoneNumber } from './min/exports/isValidPhoneNumber.js';
export { isPossiblePhoneNumber } from './min/exports/isPossiblePhoneNumber.js';
export { validatePhoneNumberLength } from './min/exports/validatePhoneNumberLength.js';

// Deprecated.
export { findNumbers } from './min/exports/findNumbers.js';
export { searchNumbers } from './min/exports/searchNumbers.js';
export { findPhoneNumbersInText } from './min/exports/findPhoneNumbersInText.js';
export { searchPhoneNumbersInText } from './min/exports/searchPhoneNumbersInText.js';
export { PhoneNumberMatcher } from './min/exports/PhoneNumberMatcher.js';
export { AsYouType } from './min/exports/AsYouType.js';
export { DIGIT_PLACEHOLDER } from './es6/AsYouTypeFormatter.js';
export { isSupportedCountry } from './min/exports/isSupportedCountry.js';
export { getCountries } from './min/exports/getCountries.js';
// `getPhoneCode` name is deprecated, use `getCountryCallingCode` instead.
export { getCountryCallingCode, getCountryCallingCode as getPhoneCode } from './min/exports/getCountryCallingCode.js';
export { getExtPrefix } from './min/exports/getExtPrefix.js';
export { Metadata } from './min/exports/Metadata.js';
export { getExampleNumber } from './min/exports/getExampleNumber.js';
export { formatIncompletePhoneNumber } from './min/exports/formatIncompletePhoneNumber.js';
export { ParseError, parseIncompletePhoneNumber, parsePhoneNumberCharacter, parseDigits, parseRFC3966, formatRFC3966 } from './core/index.js';

// Deprecated (old) exports.
export { parse as parseNumber, parse } from './index.es6.exports/parse.js';
export { format as formatNumber, format } from './index.es6.exports/format.js';
export { getNumberType } from './index.es6.exports/getNumberType.js';
export { isPossibleNumber } from './index.es6.exports/isPossibleNumber.js';
export { isValidNumber } from './index.es6.exports/isValidNumber.js';
export { isValidNumberForRegion } from './index.es6.exports/isValidNumberForRegion.js';
export { findPhoneNumbers } from './index.es6.exports/findPhoneNumbers.js';
export { searchPhoneNumbers } from './index.es6.exports/searchPhoneNumbers.js';
export { PhoneNumberSearch } from './index.es6.exports/PhoneNumberSearch.js';

// Deprecated DIGITS export.
// (it was used in `react-phone-number-input`)
export { DIGITS } from './es6/helpers/parseDigits.js';

// Deprecated "custom" exports.
export { default as parseCustom } from './es6/legacy/parse.js';
export { default as formatCustom } from './es6/legacy/format.js';
export { default as isValidNumberCustom } from './es6/legacy/isValidNumber.js';
export { default as findPhoneNumbersCustom } from './es6/legacy/findPhoneNumbers.js';
export { searchPhoneNumbers as searchPhoneNumbersCustom } from './es6/legacy/findPhoneNumbers.js';
export { PhoneNumberSearch as PhoneNumberSearchCustom } from './es6/legacy/findPhoneNumbersInitialImplementation.js';
export { default as getNumberTypeCustom } from './es6/legacy/getNumberType.js';
export { default as getCountryCallingCodeCustom, default as getPhoneCodeCustom } from './es6/getCountryCallingCode.js';
export { default as AsYouTypeCustom } from './es6/AsYouType.js';